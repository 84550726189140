import React, { useState } from 'react';
import { Button, Upload, Icon, message } from 'antd';
import CONFIG from '../../../config';

export default function fileUploadTable() {
  const [fileList, setFileList] = useState([]);

  function copyToClipboard(text) {
    if (navigator.clipboard) {
      message.success('Copied');
      return navigator.clipboard.writeText(text);
    }

    const element = document.createElement('span');
    element.textContent = text;
    element.style.whiteSpace = 'pre';
    document.body.appendChild(element);
    const selection = window.getSelection();
    const range = document.createRange();
    selection.removeAllRanges();
    range.selectNode(element);
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
    document.body.removeChild(element);
    message.success('Copied');

    return null;
  }

  return (
    <div>
      <Upload
        action={`${process.env.REACT_APP_API_DOMAIN}/upload`}
        onChange={(e) => {
          const uploadedList = e.fileList.map((file) => {
            if (file.response) {
              return {
                ...file,
                name: file.name,
                thumbUrl: `${CONFIG.FILE_DOMAIN}${file.response[0]}`,
                url: `${CONFIG.FILE_DOMAIN}${file.response[0]}`,
              };
            }

            return file;
          });

          setFileList(uploadedList);
        }}
      >
        <Button icon="upload">Click to Upload</Button>
      </Upload>

      <div
        style={{ gap: 10, width: '100%', display: 'flex', flexDirection: 'column', marginTop: 20 }}
      >
        {fileList.map((e) => (
          <div key={e.uid} style={{ border: '1px solid #dedede', display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '20%',
              }}
            >
              {e.type.includes('image') ? (
                <img
                  src={e.url}
                  alt=""
                  style={{ width: 100, height: 'auto', objectFit: 'contain' }}
                />
              ) : (
                <Icon type="file" style={{ fontSize: 50 }} />
              )}
            </div>
            <div style={{ width: '80%', padding: '5px 0px' }}>
              <p>{`File Name : ${e.name}`}</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ marginBottom: 0 }}>File Url : </p>
                <Button icon="copy" onClick={() => copyToClipboard(e.url)} />
              </div>

              <span>{e.url}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
