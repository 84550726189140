import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie/cjs';
import { useHistory } from 'react-router-dom';

const cookies = new Cookies();

export default function useImportFromSkiData({
  CarParkId,
  SerialNo,
  UserEmail,
  UserSurname,
  UserTitle,
  setIsLoading,
}) {
  const history = useHistory();

  async function handelImportFromSkiData() {
    setIsLoading(true);
    return axios
      .post(`${process.env.REACT_APP_API_DOMAIN}/SkiDataMonthlyParker/import`, {
        CarParkId,
        SerialNo,
      }, {
        headers: { token: cookies.get('token') },
      })
      .then(({ data }) => {
        const { successRecords = [], failedRecords = [] } = data || {};

        if (successRecords.length > 0 && failedRecords.length === 0) {
          if (!alert('Record successfully updated')) {
            history.replace({
              pathname: 'lastbooking',
              search: `?page=1&phone=${UserEmail}&registrationMark=${UserSurname}&carParkCode=${UserTitle}`,
            });
          }
          return;
        }

        if (failedRecords.length > 0) {
          const errorMsg = failedRecords[0].error || 'unknow error!';

          if (!alert(errorMsg)) {
            window.location.reload();
          }
        }
      })
      .catch((err) => {
        if (!alert(err || err.error)) {
          window.location.reload();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return {
    handelImportFromSkiData,
  };
}
