import React, { Component } from "react";
import { Form } from "reactstrap";
import authAPIService from "../../apiService/authAPIService";
import axios from "axios";

import Cookies from "universal-cookie/cjs";
const cookies = new Cookies();

class LoginPage extends Component {
  constructor(props) {
    super(props);
    console.log(process.env);
    this.state = {
      render: "login",

      username: "",
      email: "",
      password: "",

      message: ""
    };
  }

  componentDidMount() {
    // console.log(this.state);
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      message: ""
    });
    // console.log(this.state);
  };

  handleLogin = e => {
    e.preventDefault();
    authAPIService.login(
      this.state.username,
      this.state.password,
      response => {
        // console.log(response);
        if (!response.type || response.type.toLowerCase() !== 'admin') {
          this.setState({ message: "Incorrect username or password" });
          return;
        }
        this.setState({ message: "" });
        cookies.set("token", response.token);
        cookies.set("username", this.state.username);
        setTimeout(() => {
          window.location = "/home";
        }, 500);
      },
      error => {
        this.setState({ message: "Incorrect username or password" });
        console.log(error);
      }
    );
  };

  handleForgotPassword = e => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_API_DOMAIN}/password/forget`, {
        email: this.state.email
      })
      .then(response => {
        this.setState({
          message: "An email has been sent to recover your password."
        });
      })
      .catch(error => {
        this.setState({
          message: "Something went wrong."
        });
        console.log(error.response);
      });
  };

  _renderForgotPassword() {
    return (
      <div className="col-md-8 p-0">
        <div className="auth-innerright">
          <div className="authentication-box">
            <h4>Recover Password</h4>
            <div className="card mt-4 p-4 mb-0">
              <Form className="theme-form" onSubmit={this.handleForgotPassword}>
                <div className="form-group">
                  <label className="col-form-label pt-0">Email</label>
                  <input
                    required
                    type="email"
                    className="form-control form-control-lg"
                    name="email"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group form-row mt-3">
                  <div className="col-md-3">
                    <button type="submit" className="btn btn-secondary">
                      Submit
                    </button>
                  </div>
                </div>
                {this.state.message !== "" && <span>{this.state.message}</span>}
              </Form>
              <button
                style={{
                  background: "none",
                  border: "none",
                  marginTop: 15,
                  width: "max-content",
                  outline: "none"
                }}
                onClick={() => this.setState({ render: "login", message: "" })}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderLogin() {
    return (
      <div className="col-md-8 p-0">
        <div className="auth-innerright">
          <div className="authentication-box">
            <h4>Login</h4>
            <div className="card mt-4 p-4 mb-0">
              <Form className="theme-form" onSubmit={this.handleLogin}>
                <div className="form-group">
                  <label className="col-form-label pt-0">Username</label>
                  <input
                    required
                    type="text"
                    className="form-control form-control-lg"
                    name="username"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">Password</label>
                  <input
                    required
                    type="password"
                    className="form-control form-control-lg"
                    name="password"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group form-row mt-3">
                  <div className="col-md-3">
                    <button type="submit" className="btn btn-secondary">
                      LOGIN
                    </button>
                  </div>
                </div>
                {this.state.message !== "" && (
                  <span style={{ color: "red" }}>{this.state.message}</span>
                )}
              </Form>
              <button
                style={{
                  background: "none",
                  border: "none",
                  marginTop: 15,
                  width: "max-content",
                  outline: "none"
                }}
                onClick={() => this.setState({ render: "forgot", message: "" })}
              >
                Forgot Password?
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const background = require("../../assets/images/auth-layer-old.png");

    return (
      <div>
        {/*page-wrapper Start*/}
        <div className="page-wrapper">
          <div className="container-fluid">
            {/*login page start*/}
            <div className="authentication-main">
              <div className="row">
                <div className="col-md-4 p-0">
                  <div
                    className="auth-innerleft"
                    style={{ backgroundImage: "url(" + background + ")" }}
                  >
                    <div className="text-center">
                      <img
                        src={require("../../assets/images/wp-logo.jpg")}
                        className="logo-login"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                {this.state.render === "login"
                  ? this._renderLogin()
                  : this._renderForgotPassword()}
              </div>
            </div>
            {/*login page end*/}
          </div>
        </div>
        {/*page-wrapper Ends*/}
      </div>
    );
  }
}

export default LoginPage;
