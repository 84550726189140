import React, { useMemo, useState } from 'react';
import { Row, Col, Input, Spin, Icon } from 'antd';
import { Button } from 'reactstrap';
import SunEditor from 'suneditor-react';
import { SketchPicker } from 'react-color';
import RenderImageTitle from './RenderImageTitle';
import { ColorBox, ColorPickerWrapper } from '../styles';

const { TextArea } = Input;

function RenderContent({
  type,
  row,
  value,
  onChange,
  title,
  showPicker,
  setShowPicker,
  handleUpload,
  handleChangeFile,
  handleFileRemove,
  file,
  isUploading,
  isUploaded,
  id,
  data,
}) {
  switch (type) {
    case 'textArea':
      return <TextArea value={value} rows={row} onChange={(e) => onChange(e.target.value)} />;

    case 'input':
      return <Input value={value} onChange={(e) => onChange(e.target.value)} />;

    case 'html':
      return (
        <>
          {value && data.length > 0 && (
            // <div style={{ display: 'flex', gap: 10 }}>
            //   <div style={{ width: '50%' }} dangerouslySetInnerHTML={{ __html: value || '' }} />
            //   <TextArea value={value} style={{ width: '50%' }} onChange={(e) => onChange(e)} />
            // </div>
            <SunEditor
              height={400}
              name={`${title}-editor`}
              defaultValue={value}
              // onInput={(e) => {
              //   onChange(e.currentTarget.innerHTML);
              // }}
              onChange={(e) => {
                onChange(e);
              }}
              setOptions={{
                attributesWhitelist: {
                  all: 'style',
                },
                buttonList: [
                  ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                  ['bold', 'underline', 'italic', 'strike', 'removeFormat'],
                  '/',
                  ['align', 'list', 'table'],
                  ['link', 'image', 'fullScreen', 'preview', 'showBlocks', 'codeView'],
                ],
              }}
            />
          )}
        </>
      );

    case 'image':
      return (
        <Row type="flex" style={{ gap: 20 }} align="middle">
          {file ? file.name : value}
          <Button
            size="sm"
            color="primary"
            onClick={() => document.getElementById(`${id}-upload`).click()}
          >
            Select
          </Button>
          <Button size="sm" color="primary" onClick={handleUpload} disabled={isUploaded}>
            {isUploaded ? 'Uploaded' : 'Upload'}
            {isUploading && (
              <Spin
                style={{ marginLeft: 10 }}
                indicator={<Icon type="loading" style={{ fontSize: 16, color: 'white' }} spin />}
              />
            )}
          </Button>
          <Button size="sm" color="primary" onClick={handleFileRemove}>
            Remove
          </Button>

          <input
            accept="image/*"
            type="file"
            id={`${id}-upload`}
            style={{ display: 'none' }}
            onChange={(e) => {
              const uploadFile = e.target.files[e.target.files.length - 1];
              handleChangeFile(uploadFile);
            }}
          />
        </Row>
      );

    case 'number':
      return (
        <Input
          onChange={(e) => onChange(e.target.value)}
          style={{ width: 200 }}
          value={value}
          type="number"
        />
      );

    case 'color':
      return (
        <div style={{ position: 'relative' }}>
          <ColorBox onClick={() => setShowPicker(!showPicker)} color={value} />

          {showPicker && (
            <ColorPickerWrapper>
              <SketchPicker
                triangle="hide"
                color={value}
                onChange={(e) => {
                  onChange(e.hex);
                  setShowPicker(false);
                }}
              />
            </ColorPickerWrapper>
          )}
        </div>
      );

    default:
      return <></>;
  }
}

export default function RenderRow({
  handleUpload = async () => {},
  handleFileRemove = () => {},
  onChange = () => {},
  field = {},
  data = [],
}) {
  const [showPicker, setShowPicker] = useState(false);
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const { type, title, row, key, language } = field || {};
  const target =
    data.find((e) => e.key === key && (language ? e.language === language : true)) || {};
  const { id = '', value = '' } = target || {};
  const targetIndex = data.findIndex(
    (v) => v.id === id && (language ? v.language === language : true)
  );

  return (
    <Row gutter={[0, 20]}>
      <Col span={4}>
        {type === 'image' ? <RenderImageTitle title={title} value={value} file={file} /> : title}
      </Col>
      <Col span={20}>
        <RenderContent
          data={data}
          id={key}
          handleUpload={() => {
            handleUpload(file, key, targetIndex, setIsUploading, setIsUploaded);
          }}
          type={type}
          row={row}
          value={value}
          onChange={(e) => {
            onChange(e, key, targetIndex);
          }}
          title={title}
          showPicker={showPicker}
          setShowPicker={setShowPicker}
          file={file}
          handleFileRemove={() => {
            setIsUploaded(false);
            setFile(null);
            handleFileRemove(key, targetIndex);
          }}
          isUploading={isUploading}
          isUploaded={isUploaded}
          handleChangeFile={(e) => {
            setFile(e);
            setIsUploaded(false);
          }}
        />
      </Col>
    </Row>
  );
}
