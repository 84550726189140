import React, { Component } from 'react'
// Import custom components
import Breadcrumb from '../../components/common/breadcrumb.component'
import Cookies from 'universal-cookie/cjs'

const cookies = new Cookies()

class Homepage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true
    }
  }

  componentDidMount () {
    if (!cookies.get('token')) {
      window.location = '/'
    }

    setTimeout(
      function () {
        this.setState({ isLoading: false })
      }.bind(this),
      1000
    )
  }

  render () {
    return (
      <div>
        {/* Container-fluid starts */}
        <Breadcrumb title="Home" parent="General" />
        {/* Container-fluid Ends */}

        {/* Container-fluid starts */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 p-0">
              <div className="text-center">
                <h2>Welcome!</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 p-0"></div>
            <div className="col-md-4 p-0">
              <div className="text-center">
                {/* <img src={require('../../assets/images/lets-park-logo.png')}
                  className="logo-login" alt="" style={{ width: '80%' }} /> */}
                <hr />
                {/* <div className="social-media">
                  <img src={require('../../assets/images/logo-login-sub.png')}
                    className="logo-login" alt="" style={{ width: '80%' }} />
                </div> */}
              </div>
            </div>
            <div className="col-md-4 p-0"></div>
          </div>
        </div>
        {/* Container-fluid Ends */}
      </div>
    )
  }
}

export default Homepage
