import React from 'react';
import { updateRecord } from '../../../util/formHelper';

const SubmitButtons = ({ back, saveActionURL, body }) => (
  <div className="d-flex justify-content-end mt-3">
    <button
      type="submit"
      className="btn btn-primary btn-sm mr-3"
      onClick={() => {
        if (body.currentCar) {
          const { currentRegistrationMark, currentCarId } = body.currentCar || {};
          if (currentCarId === body.car || currentRegistrationMark === body.registrationMark) {
            alert('Please select/input another Registration Mark');
            return;
          }
        }
        updateRecord(saveActionURL, body);
      }}
    >
      Save
    </button>
    <button type="button" className="btn btn-light btn-sm" onClick={() => back && back()}>
      Cancel
    </button>
  </div>
);

export default SubmitButtons;
