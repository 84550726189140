import styled from 'styled-components';

export const ObjectWrapper = styled.div`
  border: 1px solid #dedede;
  padding: 0px 10px 10px 10px;
  position: relative;
  gap: 40px;
  display: flex;
  flex-direction: column;
`;

export const SectionName = styled.span`
  font-size: 30px;
`;

export const LanguageTitle = styled.span`
  position: absolute;
  top: -15px;
  left: 10px;
  font-size: 20px;
  background: #fff;
`;

export const HomePageSectionInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: #fcfcfc;
  margin-top: ${({ length }) => length === 0 && '40px'};
`;

export const OurCompanySectionInner = styled.div`
  background: rgb(252, 252, 252);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  border: 1px solid rgb(230, 230, 230);
`;

export const ColorBox = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid rgb(222, 222, 222);
  background-color: ${({ color }) => color};
`;

export const ColorPickerWrapper = styled.div`
  position: absolute;
  top: 0px;
  z-index: 99;
  left: 50px;
`;

export const PagesTitle = styled.a``;
