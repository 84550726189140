import React, { useMemo } from 'react';
import { Button } from 'reactstrap';
import RenderRow from '../comoponents/RenderRow';
import { LANGUAGE, OurCompanyFields } from '../constant';
import { ObjectWrapper, LanguageTitle, OurCompanySectionInner } from '../styles';

export default function OurCompany({
  handleUpload,
  handleFileRemove,
  handleOnChange,
  data,
  handleUpdate,
}) {
  const OurCompanyFieldsObject = useMemo(
    () =>
      LANGUAGE.reduce((acc, input) => {
        const languageTitle = input === 'En' ? 'English' : 'Traditional Chinese';
        return {
          ...acc,
          [languageTitle]: Object.values(OurCompanyFields).map((e) =>
            e.map((a) => ({
              ...a,
              language: input,
              key: input === 'En' ? a.key : `${a.key}_zh`,
            }))
          ),
        };
      }, {}),
    []
  );

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: -40 }}>
        <Button size="sm" color="primary" onClick={handleUpdate}>
          Submit
        </Button>
      </div>
      {Object.entries(OurCompanyFieldsObject).map(([languageTitle, values]) => (
        <ObjectWrapper
          key={languageTitle}
          style={{
            padding: '20px 10px 10px 10px',
          }}
        >
          <LanguageTitle>{languageTitle}</LanguageTitle>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 40 }}>
            {values.map((fields, i) => (
              <OurCompanySectionInner key={i}>
                {fields.map((field) => (
                  <RenderRow
                    key={field.key}
                    handleUpload={handleUpload}
                    handleFileRemove={handleFileRemove}
                    onChange={handleOnChange}
                    field={field}
                    data={data}
                  />
                ))}
              </OurCompanySectionInner>
            ))}
          </div>
        </ObjectWrapper>
      ))}
    </>
  );
}
