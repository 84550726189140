import React, { Component } from 'react';
import { Row, Col, Button, Table, Label, FormGroup, Input, Collapse } from 'reactstrap';
import TableRow from '../../../containers/Profile/TableRow';
import { Spin, Radio, DatePicker, Checkbox } from 'antd';
import axios from 'axios';
import { CreateRecordButtons, UpdateRecordButtons } from '../profile';
import {
  cleanRelationData,
  convertLabelFormat,
  // getPaymentMethods
} from '../../../util/generalHelper';
import { cleanDefaultDetails } from '../../../util/detailsHelper';
import { createRecord, updateRecord, deleteRecord } from '../../../util/formHelper';
import CONFIG from '../../../config';
import SunEditor from 'suneditor-react';
import '../../../assets/css/suneditor.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Modal } from 'antd';
import Cookies from 'universal-cookie/cjs';
import FormCustomExtraField from '../../../containers/Profile/FormCustomExtraField';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';


const cookies = new Cookies();

class DefaultForm extends Component {
  state = {
    isLoading: true,
    schema: [],
    details: {},
    relationType: {},
    relationSchema: {},
    relationData: {},
    collapsed: [],
    image: '',
    icon: '',

    paymentMethodOptions: [],

    showModal: false,
  };

  componentDidMount() {
    const {
      fetch,
      relationDataTypeHide,
      cleanData,
      requiredDateFields = [],
      hiddenField = [],
    } = this.props;

    axios
      .get(fetch, { headers: { token: cookies.get('token') } })
      .then((response) => {
        console.log(response);
        //console.log({response})
        const cleaned = cleanData
          ? cleanData(response)
          : cleanDefaultDetails(response, requiredDateFields, hiddenField);
        const imagePath =
          response.data.data && response.data.data.image ? response.data.data.image : '';
        const iconPath =
          response.data.data && response.data.data.icon ? response.data.data.icon : '';
        const chineseImagePath =
          response.data.data && response.data.data.chineseImage
            ? response.data.data.chineseImage
            : '';

        this.setState({
          schema: cleaned.cleanedSchema,
          details: cleaned.dataSource,
          isLoading: false,
          image: imagePath,
          icon: iconPath,
          chineseImage: chineseImagePath,
        });

        if (relationDataTypeHide) {
          cleaned.relations
            .filter(({ value }) => !relationDataTypeHide.includes(value))
            .forEach(({ value, title }) => {
              this.getRelationalData(value, title);
            });
        } else {
          cleaned.relations.forEach(({ value, title }) => {
            this.getRelationalData(value, title);
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        alert('Error loading page');
        console.log(error.response);
      });

    // if (this.props.getPaymentMethods) {
    //   this.getPaymentMethods();
    // }
  }

  getRelationalData(value, title) {
    const relationId = this.props.type === 'edit' ? this.state.details[`${value}Id`] : '';
    const relationEndPoint =
      this.props.type === 'edit'
        ? `${process.env.REACT_APP_API_DOMAIN}/${value}/${relationId}`
        : `${process.env.REACT_APP_API_DOMAIN}/schema/${value}`;

    console.log({
      edit: `${process.env.REACT_APP_API_DOMAIN}/${value}/${relationId}`,
      add: `${process.env.REACT_APP_API_DOMAIN}/schema/${value}`,
    });

    axios
      .get(relationEndPoint, { headers: { token: cookies.get('token') } })
      .then((response) => {
        let cleanedRelationalData = cleanRelationData(
          response.data.schema,
          response.data.data,
          value,
          title,
          this.props.type
        );
        this.setState({
          relationType: {
            ...this.state.relationType,
            ...cleanedRelationalData.relationType,
          },
          relationSchema: {
            ...this.state.relationSchema,
            ...cleanedRelationalData.relationSchema,
          },
          relationData: {
            ...this.state.relationData,
            ...cleanedRelationalData.relationData,
          },
        });
      })
      .catch((error) => {
        this.getRelationSchema(value, title);
        console.log(error);
      });
  }

  getRelationSchema(value, title) {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/schema/${value}`, {
        headers: { token: cookies.get('token') },
      })
      .then((response) => {
        let cleanedRelationalData = cleanRelationData(
          response.data.schema,
          response.data.data,
          value,
          title,
          this.props.type
        );
        this.setState({
          relationType: {
            ...this.state.relationType,
            ...cleanedRelationalData.relationType,
          },
          relationSchema: {
            ...this.state.relationSchema,
            ...cleanedRelationalData.relationSchema,
          },
          relationData: {
            ...this.state.relationData,
            ...cleanedRelationalData.relationData,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleInput = (e) => {
    this.setState(
      {
        details: { ...this.state.details, [e.target.name]: e.target.value },
      },
      () => console.log({ details: this.state.details })
    );
  };

  handleOptionSelect = (selected, type) => {
    this.setState(
      {
        details: { ...this.state.details, [type]: selected.value },
      },
      () => console.log({ details: this.state.details })
    );
  };

  handleSelect = (selected, index) => {
    const currentRelationData = [...this.state.relationData.paymentMethod];

    currentRelationData[index] = {
      ...currentRelationData[index],
      name: selected.value,
    };

    this.setState(
      (prevState) => ({
        details: {
          ...prevState.details,
          PaymentMethodId: selected.value,
        },
        relationData: {
          ...prevState.relationData,
          paymentMethod: currentRelationData,
        },
      }),
      () => console.log(this.state.relationData)
    );
  };

  handleUserInput = (e) => {
    this.setState({
      relationData: {
        ...this.state.relationData,
        User: {
          ...this.state.relationData.User,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  handleRichTextInput = (name, value) => {
    // const data = value.target.value;
    // this.setState({
    //   details: { ...this.state.details, [name]: data === '<p><br></p>' ? '' : data },
    // });
    this.setState({
      details: { ...this.state.details, [name]: value === '<p><br></p>' ? '' : value },
    });
  };

  handleSelect = (selected, name) => {
    this.setState({
      details: { ...this.state.details, [name]: selected },
    });
  };

  onChangeDate = (date, dateString, value) => {
    this.setState({
      details: { ...this.state.details, [value]: dateString },
    });
  };

  handleSave(e) {
    e.preventDefault();
    this.handleSubmitRecord();
  }

  handleAddFile(imageFile, name) {
    axios
      .post(`${process.env.REACT_APP_API_DOMAIN}/upload`, imageFile)
      .then((response) => {
        console.log({ response });
        this.setState({
          details: {
            ...this.state.details,
            [name]: response.data[0].replace('static', ''),
          },
        });
      })
      .catch((error) => {
        alert('Unable to upload your image.');
        console.log(error);
      });
  }

  handleSubmitRecord() {
    const { type, update, create } = this.props;

    const body = {
      ...this.state.details,
      relation: this.state.relationData,
    };

    if (type === 'edit') {
      updateRecord(update, body);
    } else {
      createRecord(create, body);
    }
  }

  handleUploadFile = (e, value) => {
    e.persist();
    if (e.target.files && e.target.files[0]) {
      const fd = new FormData();
      fd.append('image', e.target.files[0], e.target.files[0].name);

      //console.log(fd);
      // this.setState({
      //   details: {
      //     ...this.state.details,
      //     [value]: fd
      //   }
      // });

      this.handleAddFile(fd, value);
    }
  };

  handelArray = (action, name, array, index, input) => {
    if (action === 'change') {
      array.splice(index, 1, input);
      this.setState({
        details: { ...this.state.details, [name]: array },
      });
    }
    if (action === 'add') {
      if (!array) {
        this.setState({
          details: { ...this.state.details, [name]: [''] },
        });
        return;
      }
      array.push('');
      this.setState({
        details: { ...this.state.details, [name]: array },
      });
      return;
    }
    if (action === 'remove') {
      array.splice(index, 1);
      this.setState({
        details: { ...this.state.details, [name]: array },
      });
    }
  };

  handelCheckboxChange = (e, value, name) => {
    const targetIndex = this.state.details[name].findIndex((e) => e.toString());
  };

  onImageUploadBefore  = () => {
    return (files, _info, uploadHandler) => {
      (async () => {
        const fd = new FormData();
        fd.append('image', files[0], files[0].name);
        const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/upload`, fd)
        const filePath = response.data[0].replace('static', '');
        const url = `${CONFIG.CP_MEDIA_DOMAIN}${filePath}`;
        const res = {
          result: [
            {
              url,
              name: files[0].name,
            },
          ],
        };

        uploadHandler(res);
      })();

      uploadHandler();
    };
  }


  _renderInput(title, value, type, options) {
    const { details } = this.state;
    const REGEX = /id$/gi;
    const isDisabled = value.includes('Major') ? false : REGEX.test(value);

    if (options && Array.isArray(options) && options.length > 0) {
      const { customSelectOptions = {} } = this.props;
      const isCustomOption =
        customSelectOptions[value] && Array.isArray(customSelectOptions[value]);

      const select = details[value];
      return (
        <Col md={6} className="mb-4" key={value}>
          <Label>{title}</Label>
          <Select
            value={select ? { label: select, value: select } : undefined}
            menuPlacement="auto"
            menuPosition="fixed"
            options={isCustomOption ? customSelectOptions[value] : options}
            onChange={(selected) => this.handleOptionSelect(selected, value)}
          />
        </Col>
      );
    }

    const isDisplayRadio =
      value.includes('Major') ||
      value === 'isIMonthly' ||
      value === 'onlineShow' ||
      value === 'isShowIcon' ||
      value === 'isIHourly' ||
      value === 'isEnable' ||
      value === 'isOnlineMethod' ||
      value === 'isSearchParam';

    const isRequired = (this.props.requiredFields || []).some((field) => field === value);

    const isRemarked = (this.props.remarkFields || []).some((field) => field === value);

    if(value === 'smsContent' || value === 'chineseSmsContent'){
      type = 'STRING'
    }

    switch (type) {
      case 'BOOLEAN':
        return isDisplayRadio ? (
          <Col md={6} key={value} style={{ display: isDisabled & (title !== 'Id') && 'none' }}>
            <div className="d-flex flex-column">
              <Label>
                {title}
                {isRequired && <span style={{ color: 'red' }}>*</span>}
                {isRemarked && <span style={{ color: '#fd980f' }}>*</span>}
              </Label>
              <Radio.Group
                onChange={this.handleInput}
                name={value}
                value={details[value] || false}
                className="ml-1 mt-1 mb-4"
              >
                <Radio value={true} className="mr-5">
                  Yes
                </Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </div>
          </Col>
        ) : (
          []
        );
      case 'TEXT':
        // if(value === 'chineseEmailContent'){
        //   console.log(details[value]);
        // }

        return (

          <Col md={12} className="mb-4" key={value}>
            <FormGroup>
              <Label>
                {title}
                {isRequired && <span style={{ color: 'red' }}>*</span>}
                {isRemarked && <span style={{ color: '#fd980f' }}>*</span>}
              </Label>
              {/* <div style={{display:'flex'}}>
                <div style={{width:'50%'}} dangerouslySetInnerHTML={{__html:details[value] || ''}}></div>
                <TextArea  value={details[value] || ''} style={{width:'50%'}} onChange={this.handleRichTextInput.bind(this, value)}></TextArea>
              </div>
          */}

              <SunEditor
                height="500"
                name={`${value}-editor`}
                setContents={details[value] || ''}
                onChange={this.handleRichTextInput.bind(this, value)}
                onImageUploadBefore={this.onImageUploadBefore()}
                setOptions={{
                  buttonList: [
                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                    ['bold', 'underline', 'italic', 'strike', 'removeFormat'],
                    '/',
                    ['align', 'list', 'table'],
                    ['link', 'image', 'fullScreen', 'preview', 'showBlocks', 'codeView'],
                  ],
                }}
              />
            </FormGroup>
          </Col>
        );


      case 'INTEGER':
        return (
          <Col
            md={6}
            className="mb-4"
            key={value}
            style={{ display: isDisabled & (title !== 'Id') && 'none' }}
          >
            <FormGroup>
              <Label style={{ display: isDisabled & (title !== 'Id') && 'none' }}>
                {title}
                {isRequired && <span style={{ color: 'red' }}>*</span>}
                {isRemarked && <span style={{ color: '#fd980f' }}>*</span>}
              </Label>
              <Input

                disabled={isDisabled}
                type={value === 'amount' ? 'number' : 'text'}
                name={value}
                value={details[value] || ''}
                onChange={(e) => this.handleInput(e, value)}
              />
            </FormGroup>
          </Col>
        );
      case 'STRING':
        return (
          <Col md={6} className="mb-4" key={value}>
            <FormGroup>
              <Label>
                {title}
                {isRequired && <span style={{ color: 'red' }}>*</span>}
                {isRemarked && <span style={{ color: '#fd980f' }}>*</span>}
              </Label>
              {value === 'image' || value === 'chineseImage' || value === 'icon' ? (
                <>
                  <Input
                    type="file"
                    accept="image/*"
                    name={value}
                    style={{
                      border: '1px solid #ced4da',
                      padding: 5,
                      borderRadius: '4px',
                      marginBottom: 10,
                    }}
                    onChange={(e) => this.handleUploadFile(e, value)}
                  />
                  {this.state[value] && (
                    <>
                      <span>Current file: {this.state[value]}</span>

                      <div
                        style={{
                          marginTop: 10,
                          background: '#e9ecef',
                          border: '1px solid #ced4da',
                          borderRadius: 5,
                          padding: 10,
                          display: 'flex',
                          justifyContent: 'center',
                          overflow: 'hidden',
                          maxHeight: 150,
                        }}
                      >
                        <img
                          src={`${CONFIG.CP_MEDIA_DOMAIN}${this.state.details[value]}`}
                          style={{ objectFit: 'contain', width: '100%' }}
                          alt={this.state.details[value]}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <Input
                  disabled={isDisabled}
                  type="text"
                  name={value}
                  value={details[value] || ''}
                  onChange={this.handleInput}

                />
              )}
            </FormGroup>
          </Col>
        );
      case 'DATE':
        return (
          <Col md={6} className="mb-4" key={value}>
            <div className="d-flex flex-column">
              <Label>
                {title}
                {isRequired && <span style={{ color: 'red' }}>*</span>}
                {isRemarked && <span style={{ color: '#fd980f' }}>*</span>}
              </Label>
              <DatePicker
                value={details[value] ? moment(details[value], 'YYYY-MM-DD') : null}
                className="mb-3"
                onChange={(date, dateString) => this.onChangeDate(date, dateString, value)}
              />
            </div>
          </Col>
        );
      case 'JSONTYPE':
        const {
          longitude,
          latitude,
          title: parkingTitle,
          link: parkingLink,
        } = details[value] || {};

        const onChangeValue = (e, type, subType) => {
          this.setState({
            details: {
              ...this.state.details,
              [type]: {
                ...this.state.details[type],
                [subType]: e.target.value,
              },
            },
          });
        };

        return (
          <>
            {value === 'eFaxEmails' && (
              <Col md={6} className="mb-4" key={value}>
                <Label>{title}</Label>
                <div style={{ width: '100%' }}>
                  <div
                    style={{
                      marginBottom: 10,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {details[value] &&
                      details[value].length > 0 &&
                      details[value].map((e, index) => {
                        return (
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: 5,
                            }}
                          >
                            <Input
                              disabled={isDisabled}
                              type="text"
                              name={value}
                              value={e}
                              onChange={(e) =>
                                this.handelArray(
                                  'change',
                                  value,
                                  details[value],
                                  index,
                                  e.target.value
                                )
                              }
                            />
                            <MinusCircleOutlined
                              style={{ cursor: 'pointer', marginLeft: 10 }}
                              onClick={() =>
                                this.handelArray('remove', value, details[value], index)
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
                  >
                    <Button
                      className="btn-light"
                      style={{ padding: '5px 5px', backgroundColor: '#000', width: '100%' }}
                      onClick={() => this.handelArray('add', value, details[value])}
                    >
                      <PlusOutlined style={{ fontSize: 10, marginRight: 10 }} />
                      Add eFax
                    </Button>
                  </div>
                </div>
              </Col>
            )}
            {value === 'displayPaymentMethods' && (
              <Col md={6} className="mb-4" key={value}>
                <Label>{title}</Label>
                <Checkbox.Group
                  style={{ width: '100%' }}
                  value={details[value]}
                  onChange={(e) => {
                    this.setState({ details: { ...this.state.details, [value]: e } });
                  }}
                  options={[
                    'octopus',
                    'creditCard',
                    'cash',
                    'unionPay',
                    'weChat',
                    'aliPay',
                    'applePay',
                    'googlePay',
                  ]}
                />
              </Col>
            )}
            {value === 'freeParking' && (
              <>
                <Col md={3} className="mb-4">
                  <FormGroup>
                    <Label>Free Parking Title</Label>
                    <Input
                      type="text"
                      name={value}
                      value={parkingTitle || ''}
                      onChange={(e) => onChangeValue(e, 'freeParking', 'title')}
                    />
                  </FormGroup>
                </Col>

                <Col md={3} className="mb-4">
                  <FormGroup>
                    <Label>Free Parking Link</Label>
                    <Input
                      type="text"
                      name={value}
                      value={parkingLink || ''}
                      onChange={(e) => onChangeValue(e, 'freeParking', 'link')}
                    />
                  </FormGroup>
                </Col>
              </>
            )}

            {value === 'chineseFreeParking' && (
              <>
                <Col md={3} className="mb-4">
                  <FormGroup>
                    <Label>Chinese Free Parking Title</Label>
                    <Input
                      type="text"
                      name={value}
                      value={parkingTitle || ''}
                      onChange={(e) => onChangeValue(e, 'chineseFreeParking', 'title')}
                    />
                  </FormGroup>
                </Col>

                <Col md={3} className="mb-4">
                  <FormGroup>
                    <Label>Chinese Free Parking Link</Label>
                    <Input
                      type="text"
                      name={value}
                      value={parkingLink || ''}
                      onChange={(e) => onChangeValue(e, 'chineseFreeParking', 'link')}
                    />
                  </FormGroup>
                </Col>
              </>
            )}

            {value === 'coordinates' && (
              <>
                <Col md={3} className="mb-4">
                  <FormGroup>
                    <Label>Longitude</Label>
                    <Input
                      type="text"
                      name={value}
                      value={longitude || ''}
                      onChange={(e) => onChangeValue(e, 'coordinates', 'longitude')}
                    />
                  </FormGroup>
                </Col>

                <Col md={3} className="mb-4">
                  <FormGroup>
                    <Label>Latitude</Label>
                    <Input
                      type="text"
                      name={value}
                      value={latitude || ''}
                      onChange={(e) => onChangeValue(e, 'coordinates', 'latitude')}
                    />
                  </FormGroup>
                </Col>
              </>
            )}
          </>
        );
      default:
        break;
    }
  }

  renderConfirmDeleteModal(update) {
    return (
      <Modal
        title="Delete record?"
        visible={this.state.showModal}
        onOk={() => deleteRecord(update)}
        okText="Confirm"
        onCancel={() => this.setState({ showModal: false })}
        centered
      >
        Please click "Confirm" to delete the record.
      </Modal>
    );
  }

  renderCustomExtraField(customExtraField) {
    const isRequired = (this.props.requiredFields || []).some(
      (field) => field === customExtraField
    );
    switch (customExtraField) {
      case 'PaymentMethods':
        return (
          <FormCustomExtraField
            relation={'PaymentMethods'}
            value={this.state.details.PaymentMethods || []}
            onChange={(selected) => this.handleSelect(selected, 'PaymentMethods')}
            isRequired={isRequired}
          />
        );
      case 'Location':
        return (
          <FormCustomExtraField
            relation={'Location'}
            value={this.state.details.LocationId}
            onChange={(selected) => this.handleOptionSelect(selected, 'LocationId')}
            isRequired={isRequired}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const labels = convertLabelFormat(this.state.schema);
    const { type, update, cancel, RemarkComponent } = this.props;

    return (
      <>
        {this.state.isLoading ? (
          <div className="d-flex justify-content-center">
            <Spin />
          </div>
        ) : (
          <>
            <Row>
              {labels &&
                labels.map((item) =>
                  this._renderInput(item.title, item.value, item.type, item.values)
                )}
            </Row>

            {/* Custom extra fields */}
            {Array.isArray(this.props.customExtraField) &&
              this.props.customExtraField.map((field) => this.renderCustomExtraField(field))}

            <br />

            {Object.keys(this.state.relationType).map((type) => {
              return (
                <div key={type}>
                  <Button
                    color="light"
                    style={{
                      marginBottom: '1rem',
                      textAlign: 'left',
                      width: '100%',
                    }}
                    onClick={() => {
                      const isCollapsed = this.state.collapsed.includes(type)
                        ? this.state.collapsed.filter((existingType) => existingType !== type)
                        : [...this.state.collapsed, type];

                      this.setState({
                        collapsed: isCollapsed,
                      });
                    }}
                  >
                    <span>&#9660; &nbsp; {this.state.relationType[type]}</span>
                  </Button>

                  <Collapse
                    isOpen={!!this.state.collapsed.includes(type)}
                    style={{ marginBottom: 50, overflowX: 'auto' }}
                  >
                    {type === 'User' ? (
                      <Table>
                        <thead>
                          <tr>
                            {this.state.relationSchema[type].map((schema) => {
                              return (
                                <th scope="col" key={schema.value}>
                                  {schema.title}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <FormGroup>
                                <Input
                                  type="text"
                                  value={this.state.relationData.User.username}
                                  name="username"
                                  onChange={this.handleUserInput}
                                />
                              </FormGroup>
                            </td>
                            <td>
                              <FormGroup>
                                <Input
                                  type="password"
                                  value={this.state.relationData.User.password}
                                  name="password"
                                  onChange={this.handleUserInput}
                                />
                              </FormGroup>
                            </td>
                            <td>
                              <FormGroup>
                                <Input
                                  type="email"
                                  value={this.state.relationData.User.email}
                                  name="email"
                                  onChange={this.handleUserInput}
                                />
                              </FormGroup>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    ) : (
                      <Table striped>
                        <thead>
                          <tr>
                            {this.state.relationSchema[type].map((schema) => {
                              return schema.title.includes('Id') ? (
                                []
                              ) : (
                                <th scope="col" key={schema.value}>
                                  {schema.title}
                                </th>
                              );
                            })}
                            <th scope="col">
                              <button
                                className="btn btn-light btn-sm d-flex"
                                style={{ margin: 'auto', marginRight: 0 }}
                                onClick={() => {
                                  let currentRelationData = [...this.state.relationData[type]];
                                  const emptyData = {};

                                  this.state.relationSchema[type].map(
                                    ({ value }) => (emptyData[value] = '')
                                  );
                                  currentRelationData.push(emptyData);

                                  this.setState((prevState) => ({
                                    relationData: {
                                      ...prevState.relationData,
                                      [type]: currentRelationData,
                                    },
                                  }));
                                }}
                              >
                                <i className="fa fa-plus" />
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <TableRow
                            handleSelect={this.handleSelect}
                            paymentMethods={
                              this.state.paymentMethods !== undefined
                                ? this.state.paymentMethods
                                : []
                            }
                            rows={this.state.relationData[type]}
                            handleUploadFile={(e, index) => {
                              e.persist();
                              if (!e.target.files || !e.target.files[0]) {
                              } else {
                                const fd = new FormData();

                                fd.append('image', e.target.files[0], e.target.files[0].name);

                                const currentRelationData = [...this.state.relationData[type]];

                                axios
                                  .post(`${process.env.REACT_APP_API_DOMAIN}/upload`, fd)
                                  .then((response) => {
                                    currentRelationData[index] = {
                                      ...currentRelationData[index],
                                      [e.target.name]: response.data[0],
                                    };

                                    this.setState(
                                      (prevState) => ({
                                        relationData: {
                                          ...prevState.relationData,
                                          [type]: currentRelationData,
                                        },
                                      }),
                                      () => console.log(this.state.relationData[type])
                                    );
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              }
                            }}
                            onChange={(e, index) => {
                              const currentRelationData = [...this.state.relationData[type]];

                              currentRelationData[index] = {
                                ...currentRelationData[index],
                                [e.target.name]: e.target.value,
                              };

                              this.setState(
                                (prevState) => ({
                                  relationData: {
                                    ...prevState.relationData,
                                    [type]: currentRelationData,
                                  },
                                }),
                                () => console.log(this.state.relationData[type])
                              );
                            }}
                            removeRow={(index) => (
                              <button
                                className="btn btn-light btn-sm d-flex"
                                style={{ margin: 'auto', marginRight: 0 }}
                                onClick={() => {
                                  let currentRelationData = [...this.state.relationData[type]];
                                  currentRelationData.splice(index, 1);

                                  this.setState((prevState) => ({
                                    relationData: {
                                      ...prevState.relationData,
                                      [type]: currentRelationData,
                                    },
                                  }));
                                }}
                              >
                                <i className="fa fa-minus" />
                              </button>
                            )}
                          />
                        </tbody>
                      </Table>
                    )}
                  </Collapse>
                </div>
              );
            })}

            {RemarkComponent && <RemarkComponent />}

            {type === 'edit' ? (
              <>
                {this.renderConfirmDeleteModal(update)}
                <UpdateRecordButtons
                  handleSave={(e) => this.handleSave(e)}
                  handleDelete={() => this.setState({ showModal: true })}
                  cancel={cancel}
                  allowDelete={this.props.allowDelete}
                />
              </>
            ) : (
              <CreateRecordButtons handleSave={(e) => this.handleSave(e)} cancel={cancel} />
            )}
          </>
        )}
      </>
    );
  }
}

export default DefaultForm;
