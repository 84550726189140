import React, { useState } from 'react';
import CarparkPaymentMethods from './CarparkPaymentMethods';

const CarparkActions = ({ id, PaymentMethods = [], allowEdit }) => {
  const [isShow, setIsShow] = useState(false);
  return (
    <div className="d-flex flex-column">
      <>
        <span>{PaymentMethods.length > 0 ? `${PaymentMethods.length} methods` : 'Default'}</span>
        {allowEdit && (
          <button
            className="btn btn-light btn-xs mt-2 mb-2"
            onClick={() => {
              setIsShow(true);
            }}
          >
            Edit
          </button>
        )}
      </>
      <CarparkPaymentMethods
        carParkId={id}
        records={PaymentMethods}
        showModal={isShow}
        setShowModal={setIsShow}
      />
    </div>
  );
};

export default CarparkActions;
