import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Cookies from 'universal-cookie/cjs';
import { Button } from 'reactstrap';
import { Modal, List, Avatar } from 'antd';
import OurCompany from './containers/OurCompany';
import HomePage from './containers/HomePage';
import { pagesOptions } from './constant';
import Pages from './containers/Pages';
import { PagesTitle } from './styles';

const cookies = new Cookies();

function StaticInfoTable({ getAllRecords, defaultSorting = {}, edit, pageName }) {
  const location = useLocation();
  const { page: pageFromQuery = 1 } = queryString.parse(location.search);

  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    pageSize: 100,
    current: parseInt(pageFromQuery),
  });
  const [data, setData] = useState([]);
  const [showEditModal, setEditModal] = useState(false);
  const [pages, setPages] = useState('');
  const PAGENAME = pageName === 'homePageAndPages' ? pages : pageName;
  const [changedValue, setChangedValue] = useState({ pageName: PAGENAME });

  const fetch = (params = {}) => {
    setIsLoading(true);

    if (!PAGENAME) {
      return;
    }

    const {
      pagination,
      sortField = defaultSorting.sortField || 'id',
      sortOrder = defaultSorting.sortOrder || 'descend',
    } = params;

    const updatedParams = {
      ...pagination,
      sortField,
      sortOrder,
      pageName: PAGENAME,
    };

    // Convert Antd Table "pagination.current" to API query param "page"
    updatedParams.page = updatedParams.current;
    delete updatedParams.current;

    axios
      .get(getAllRecords, {
        params: updatedParams,
        headers: { token: cookies.get('token') },
      })
      .then(async (response) => {
        setData(response.data.data);
        setPagination({ ...params.pagination, total: response.data.count });
        setIsLoading(false);
      })
      .catch((error) => {
        setData([]);
        console.log(error);
        if (error.response && error.response.status) {
          const errorType = error.response.status.toString()[0];
          if (errorType === '5') {
            alert('Filter not available at the moment');
          }
        }
        setIsLoading(false);
      });
  };

  function handleOnChange(e, key, targetIndex) {
    const array = [...data];
    array[targetIndex] = {
      ...array[targetIndex],
      value: e,
    };

    setData(array);
    setChangedValue({ ...changedValue, [key]: e });
  }

  function handleUpload(file, key, targetIndex, setIsUploading, setIsUploaded) {
    if (!file) {
      return;
    }
    setIsUploading(true);
    const formData = new FormData();
    formData.append('image', file);
    axios
      .post(`${process.env.REACT_APP_API_DOMAIN}/upload`, formData)
      .then((response) => {
        handleOnChange(response.data[0], key, targetIndex);
        setIsUploading(false);
        setIsUploaded(true);
        alert('image successfully uploaded');
      })
      .catch(() => {
        alert('upload error,please try again');
        setIsUploading(false);
        setIsUploaded(false);
      });
  }

  function handleFileRemove(key, targetIndex) {
    const array = [...data];
    array[targetIndex] = {
      ...array[targetIndex],
      value: '',
    };

    setData(array);
    setChangedValue({ ...changedValue, [key]: '' });
  }

  function handleUpdate() {
    axios
      .put(`${process.env.REACT_APP_API_DOMAIN}/staticInfo`, changedValue)
      .then(() => {
        setPages('');
        setEditModal(false);
        alert('Record successfully updated');
      })
      .catch(() => {
        alert('unknow error!');
      });
  }

  useEffect(() => {
    fetch({ pagination: { current: parseInt(pageFromQuery) } });
  }, [PAGENAME]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 60 }}>
      {pageName === 'OurCompany' && (
        <OurCompany
          edit={edit}
          data={data}
          handleOnChange={handleOnChange}
          handleUpload={handleUpload}
          handleFileRemove={handleFileRemove}
          handleUpdate={handleUpdate}
        />
      )}

      {pageName === 'homePageAndPages' && (
        <List
          itemLayout="horizontal"
          dataSource={pagesOptions}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <PagesTitle
                    type="button"
                    onClick={() => {
                      setEditModal(true);
                      setPages(item.value);
                      setChangedValue({ pageName: item.value });
                    }}
                  >
                    {item.label}
                  </PagesTitle>
                }
                description={item.path}
                avatar={<Avatar size={40} icon="file" />}
              />
            </List.Item>
          )}
        />
      )}

      <Modal
        title="Edit"
        visible={showEditModal}
        onCancel={() => {
          setPages('');
          setEditModal(false);
          setIsLoading(true);
          setData([]);
        }}
        width="60%"
        footer={[
          <Button key={1} size="sm" color="primary" onClick={handleUpdate}>
            Submit
          </Button>,
        ]}
      >
        {pages === 'HomePage' && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 40 }}>
            <HomePage
              edit={edit}
              data={data}
              handleOnChange={handleOnChange}
              handleUpload={handleUpload}
              handleFileRemove={handleFileRemove}
              handleUpdate={handleUpdate}
            />
          </div>
        )}
        {pages !== 'HomePage' && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 40 }}>
            <Pages
              edit={edit}
              data={data}
              handleOnChange={handleOnChange}
              handleUpload={handleUpload}
              handleFileRemove={handleFileRemove}
              handleUpdate={handleUpdate}
            />
          </div>
        )}
      </Modal>
    </div>
  );
}
export default StaticInfoTable;
