export const PaymentTableColumns = [
  'id',
  'accountCode',
  'amount',
  'paid',
  'status',
  'refundAmount',
  'createdAt',
  'paymentMethod',
  'orderId',
  'registrationMark',
  'customerPhone',
];

export const paymentStatusOptions = [
  { value: 'overpaid', label: 'Overpaid' },
  { value: 'underpaid', label: 'Underpaid' },
  { value: 'settled', label: 'Settled' },
  { value: 'pending', label: 'Pending' },
];

export const paymentFilter = [
  {
    title: 'Status',
    value: 'status',
    type: 'multiSelect',
    options: [
      { value: 'overpaid', label: 'Overpaid' },
      { value: 'underpaid', label: 'Underpaid' },
      { value: 'settled', label: 'Settled' },
      { value: 'pending', label: 'Pending' },
    ],
  },
];

export const PaymentSchema = [
  { name: 'id', label: 'Id', type: 'string' },
  { name: 'accountCode', label: 'Account Code', type: 'string' },
  { name: 'amount', label: 'Amount', type: 'string' },
  { name: 'status', label: 'Status', type: 'dropdown' },
  { name: 'paymentMethod', label: 'Payment Method', type: 'dropdown' },
  { name: 'remark', label: 'Remark', type: 'string' },
  { name: 'refundAmount', label: 'Refund Amount', type: 'string' },
  { name: 'refundDate', label: 'Refund Date', type: 'date' },
];
