import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import Select from 'react-select';
import CONFIG from '../../config';

const TableRow = ({
  rows,
  removeRow,
  onChange,
  handleUploadFile,
  paymentMethods,
  handleSelect,
}) => {
  const renderInputFields = (field, index, data) => {
    switch (field) {
      case 'name':
        return (
          <div style={{ minWidth: '130px' }}>
            <Select
              menuPlacement="auto"
              menuPosition="fixed"
              // defaultValue={data !== ''}
              options={paymentMethods}
              onChange={(selected) => handleSelect(selected, index)}
            />
          </div>
        );
      case 'icon':
      case 'image':
        return (
          <>
            <Input
              type="file"
              accept="image/*"
              name={field}
              style={{
                border: '1px solid #ced4da',
                padding: 5,
                borderRadius: '4px',
                background: '#fff',
              }}
              onChange={(e) => handleUploadFile(e, index)}
            />
            {data !== '' && (
              <>
                <span>
                  Current file:
                  {data}
                </span>

                <div
                  style={{
                    marginTop: 10,
                    background: '#e9ecef',
                    border: '1px solid #ced4da',
                    borderRadius: 5,
                    padding: 5,
                    display: 'flex',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    maxHeight: 50,
                  }}
                >
                  <img
                    src={`${CONFIG.CP_MEDIA_DOMAIN}${data}`}
                    style={{ objectFit: 'contain' }}
                    alt={data}
                  />
                </div>
              </>
            )}
          </>
        );
      default:
        return (
          <Input
            type="text"
            value={data}
            name={field}
            onChange={(e) => onChange && onChange(e, index)}
          />
        );
    }
  };

  return (
    Array.isArray(rows) &&
    rows.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {Object.keys(row).map((field) => {
          const data = row[field] !== null ? row[field] : '';

          return field.includes('Id') ? (
            []
          ) : (
            <td key={field}>
              <FormGroup>{renderInputFields(field, rowIndex, data)}</FormGroup>
            </td>
          );
        })}

        <td>{removeRow && removeRow(rowIndex)}</td>
      </tr>
    ))
  );
};

export default TableRow;
