export const parkingPlanFilter = [
  { title: 'Car Park ID', value: 'CarParkId' },
  { title: 'Car Park Code', value: 'CarPark' },
  { title: 'Parking Service', value: 'ParkingService' },
  { title: 'Sub Title', value: 'subTitle' },
  {
    title: 'Is Enable',
    value: 'isEnable',
    type: 'dropdown',
    options: [
      { value: '', label: 'All' },
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
  },
];

export const ParkingPlanTableColumns = [
  'id',
  'carParkCode',
  'parkingService',
  'carType',
  'subTitle',
  'quota',
  'amount',
  'isEnable',
  'priority',
  'isFixedQuota',
  'usedQuotaCache',
  'currentBookingCount',
];

export const ParkingPlanSchema = [
  { name: 'id', label: 'Id', type: 'string' },
  { name: 'subTitle', label: 'Sub Title', type: 'string' },
  { name: 'chineseSubTitle', label: 'Chinese Sub Title', type: 'string' },
  { name: 'skidataSurname', label: 'Skidata Surname', type: 'string' },
  { name: 'timeOfDay', label: 'Time Of Day', type: 'string' },
  { name: 'amount', label: 'Amount', type: 'string' },
  { name: 'quota', label: 'Quota', type: 'string' },
  { name: 'carParkCode', label: 'Car Park Code', type: 'dropdown' },
  { name: 'parkingService', label: 'Parking Service', type: 'dropdown' },
  { name: 'carType', label: 'Car Type', type: 'dropdown' },
  { name: 'isEnable', label: 'Is Enable', type: 'radio' },
  { name: 'priority', label: 'Priority', type: 'string' },
  { name: 'isFixedQuota', label: 'Fixed Quota', type: 'radio' },
  { name: 'isWaitingList', label: 'Enable Waiting List', type: 'radio' },
];

export const ParkingPlanCustomAPISchema = [
  {
    name: 'cartBookingCount',
    label: 'Temporary Hold Quota (1st 15 mins)',
    type: 'string',
    displayZero: true,
  },
  {
    name: 'tempBookingCount',
    label: 'Payment Temporary Hold Quota (2nd 15 mins)',
    type: 'string',
    displayZero: true,
  },
  {
    name: 'confirmedBookingCount',
    label: `Current Month's Booking`,
    type: 'string',
    displayZero: true,
  },
];
