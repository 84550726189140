import React, { useEffect, useState } from 'react';
import { Row, Col, Label, FormGroup, Input } from 'reactstrap';
import { DatePicker } from 'antd';
import 'antd/dist/antd.css';
import axios from 'axios';
import Select from 'react-select';
import Cookies from 'universal-cookie/cjs';
import moment from 'moment';
import SubmitButtons from './SubmitButtons';

const cookies = new Cookies();

const defaultDetails = {
  car: { value: '', label: '' },
  changeType: { value: '', label: '' },
  startDate: '',
};

const changeTypeOptions = [
  { value: 'temporary', label: 'Temporary' },
  { value: 'permanent', label: 'Permanent' },
];

function ChangeLpn({ id, back, saveActionURL }) {
  const [details, setDetails] = useState(defaultDetails);
  const [lpnOptions, setLpnOptions] = useState([{ value: 'new', label: 'Add New' }]);
  const [registrationMark, setRegistrationMark] = useState('');

  useEffect(() => {
    async function fetchData() {
      const {
        data: { data: bookingData },
      } = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/booking/${id}`, {
        headers: { token: cookies.get('token') },
      });

      const {
        CustomerId,
        ParkingPlan: { CarTypeId },
      } = bookingData;

      const { Car, validFrom: validFromValue } = bookingData || {};
      const { registrationMark: currentRegistrationMark, id: currentCarId } = Car || {};

      // where to get type of change?
      const car = {
        value: currentCarId,
        label: `${currentRegistrationMark} (current)`,
        isDisabled: true,
      };
      const changeType = { value: '', label: '' };
      const validFrom = validFromValue ? moment(validFromValue, 'YYYY-MM-DD') : validFromValue;

      setDetails({
        car,
        changeType,
        validFrom,
        currentCar: { currentRegistrationMark, currentCarId },
      });

      try {
        const {
          data: { data: carData },
        } = await axios.get(
          `${process.env.REACT_APP_API_DOMAIN}/car?CustomerId=${CustomerId}&CarTypeId=${CarTypeId}`,
          { headers: { token: cookies.get('token') } }
        );

        const allLpns = carData.map(({ id: carId, registrationMark }) => {
          if (carId === currentCarId) {
            return {
              value: carId,
              label: `${registrationMark} (current)`,
              isDisabled: true,
            };
          }
          return {
            value: carId,
            label: registrationMark,
          };
        });

        if (allLpns.findIndex(({ value }) => value === currentCarId) === -1) {
          allLpns.push({
            value: currentCarId,
            label: `${currentRegistrationMark} (current)`,
            isDisabled: true,
          });
        }

        lpnOptions.push(...allLpns);
        setLpnOptions(lpnOptions);
      } catch (err) {
        lpnOptions.push({
          label: `${currentRegistrationMark} (current)`,
          value: currentCarId,
          isDisabled: true,
        });
        setLpnOptions(lpnOptions);
      }
    }

    fetchData();
  }, []);

  const handleSelect = (selected, type) => {
    setDetails({ ...details, [type]: selected });
  };

  const bodyData = () => {
    const { car, changeType, validFrom, currentCar } = details;
    const { value: CarId, label: newRegistrationMark } = car || {};
    const { value: changeTypeValue } = changeType || {};

    if (CarId === 'new') {
      return {
        registrationMark,
        changeType: changeTypeValue,
        validFrom,
        PreviousBookingId: id,
        currentCar,
      };
    }

    return {
      registrationMark: newRegistrationMark.replace(' (current)', ''),
      car: CarId,
      changeType: changeTypeValue,
      validFrom,
      PreviousBookingId: id,
      currentCar,
      origin: 'cms',
    };
  };

  return (
    <div>
      <Row>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label>Car</Label>
            <Select
              menuPlacement="auto"
              menuPosition="fixed"
              options={lpnOptions}
              value={details.car}
              onChange={(selected) => {
                handleSelect(selected, 'car');
              }}
              isOptionDisabled={(option) => option.isDisabled}
            />
            {details.car.value === 'new' && (
              <Input
                type="text"
                className="mt-2"
                name={details.car}
                value={registrationMark}
                onChange={(e) => setRegistrationMark(e.target.value)}
              />
            )}
          </FormGroup>
        </Col>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label>Type of Change</Label>
            <Select
              menuPlacement="auto"
              menuPosition="fixed"
              options={changeTypeOptions}
              value={details.changeType}
              onChange={(selected) => handleSelect(selected, 'changeType')}
            />
          </FormGroup>
        </Col>
        <Col md={6} className="mb-4">
          <div className="d-flex flex-column">
            <Label>Valid From</Label>
            <DatePicker
              className="mb-4"
              value={moment(details.validFrom)}
              onChange={(date, dateString) => setDetails({ ...details, validFrom: dateString })}
            />
          </div>
        </Col>
      </Row>

      <SubmitButtons id={id} back={back} body={bodyData()} saveActionURL={saveActionURL} />
    </div>
  );
}

export default ChangeLpn;
