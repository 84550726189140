import moment from 'moment';
import React from 'react';
import _ from 'lodash';

export const SkiDataMonthlyParkerEditRemark = () => (
  <span>
    <span style={{ color: '#fd980f' }}>*</span>
    only following fields will be synced to SkiData System automatically.
  </span>
);

export const SkiDataMonthlyParkerColumns = [
  'id',
  'CarPark code',
  'LPN',
  'Surename',
  'Phone',
  'Service plan',
  'PP card',
  'Service start date',
  'Service end date',
  'Import Button',
  'CustomerId',
  'createdAt',
  'updatedAt',
  'CardBlockedDate',
  'CardNo',
  'CustomerNo',
  'UserFirstName',
  'deletedAt',
  // 'ArticleDesig',
  // 'ArticleNo',
  // 'CardIsBlocked',
  // 'FacilityNo',
  // 'UserNo',
  // 'CarId',
  // 'CarParkId',
];

export const SKiDataTableDefaultColumn = [
  'ArticleDesig',
  'ArticleNo',
  'CardBlockedDate',
  'CardIsBlocked',
  'CardNo',
  'CardValidFrom',
  'CardValidUntil',
  'CustomerNo',
  'CustomerSurname',
  'FacilityNo',
  'SerialNo',
  'UserDepartment',
  'UserEmail',
  'UserFirstName',
  'UserNo',
  'UserSurname',
  'UserTitle',
  'CarParkId',
  'createdAt',
  'updatedAt',
];

function parseDate(dateValue, format = 'DD MMM YYYY') {
  if (!dateValue || dateValue === 'N/A') return 'N/A';
  return moment(dateValue).utcOffset(0).startOf('day').format(format);
}

export function cleanSkiDataMonthlyParkerData(resource) {
  const successRecords = resource.successRecords.map((item) => {
    const {
      ArticleDesig,
      ArticleNo,
      CardBlockedDate,
      CardIsBlocked,
      CardNo,
      CardValidFrom,
      CardValidUntil,
      CustomerNo,
      CustomerSurname,
      FacilityNo,
      SerialNo,
      UserDepartment,
      UserEmail,
      UserFirstName,
      UserNo,
      UserSurname,
      UserTitle,
      CarParkId,
      createdAt,
      updatedAt,
    } = item;

    return {
      ArticleDesig,
      ArticleNo,
      CardBlockedDate,
      CardIsBlocked,
      CardNo,
      CardValidFrom,
      CardValidUntil,
      CustomerNo,
      CustomerSurname,
      FacilityNo,
      SerialNo,
      UserDepartment,
      UserEmail,
      UserFirstName,
      UserNo,
      UserSurname,
      UserTitle,
      CarParkId,
      createdAt: parseDate(createdAt),
      updatedAt: parseDate(updatedAt),
    };
  });

  const failedRecords = resource.failedRecords.map((item) => {
    const { differences = [], error, ...rest } = item;

    const objectWithDifferences = Object.fromEntries(
      Object.entries(rest).map(([key, value]) => {
        const targetDifferences = differences.filter((diff) => diff.path.includes(key));

        if (targetDifferences.length > 0) {
          const component = targetDifferences.map(({ lhs, rhs }) => (
            <div
              style={{
                padding: 20,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                border: '1px solid red',
              }}
            >
              <span style={{ whiteSpace: 'nowrap', color: 'red' }}>{error}</span>
              <span style={{ textDecoration: ' line-through', fontSize: 14 }}>
                {lhs === '*' ? '＊' : lhs || 'N/A'}
              </span>
              <span style={{ fontSize: 16, fontWeight: 'bold' }}>{rhs}</span>
            </div>
          ));

          return [key, component];
        }

        return [key, value];
      })
    );

    const {
      ArticleDesig,
      ArticleNo,
      CardBlockedDate,
      CardIsBlocked,
      CardNo,
      CardValidFrom,
      CardValidUntil,
      CustomerNo,
      CustomerSurname,
      FacilityNo,
      SerialNo,
      UserDepartment,
      UserEmail,
      UserFirstName,
      UserNo,
      UserSurname,
      UserTitle,
      CarParkId,
      createdAt,
      updatedAt,
    } = objectWithDifferences;

    return {
      ArticleDesig,
      ArticleNo,
      CardBlockedDate,
      CardIsBlocked,
      CardNo,
      CardValidFrom,
      CardValidUntil,
      CustomerNo,
      CustomerSurname,
      FacilityNo,
      SerialNo,
      UserDepartment,
      UserEmail,
      UserFirstName,
      UserNo,
      UserSurname,
      UserTitle,
      CarParkId,
      createdAt: parseDate(createdAt),
      updatedAt: parseDate(updatedAt),
    };
  });

  return { successRecords, failedRecords };
}

export const skiDataFailedRecordsColumn = (failedRecords = []) => {
  const columns = SKiDataTableDefaultColumn.map((v) => {
    const title = _.lowerCase(v)
      .split(' ')
      .map((word) => _.capitalize(word))
      .join(' ');
    // const key = dataIndex;

    return {
      title,
      dataIndex: v,
      sorter: true,
      render: (text) => text,
    };
  });

  return columns;
};
