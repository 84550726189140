import React, { useEffect, useState } from 'react';
import { Row, Col, Label } from 'reactstrap';
import { Spin, DatePicker } from 'antd';
import axios from 'axios';
import moment from 'moment';
import Cookies from 'universal-cookie/cjs';
import SubmitButtons from './SubmitButtons';

const cookies = new Cookies();

const Renew = ({ id, back, saveActionURL }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [periodFrom, setPeriodFrom] = useState('');
  const [periodTo, setPeriodTo] = useState('');

  useEffect(() => {
    async function fetchPeriod() {
      const {
        data: { data },
      } = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/booking/${id}`, {
        headers: { token: cookies.get('token') },
      });

      const { validTo } = data;

      setPeriodFrom(moment(validTo).utcOffset(0).add(1, 'month').startOf('month').format('YYYY-MM-DDTHH:mm:ss.sssZ'));
      setPeriodTo(moment(validTo).utcOffset(0).add(1, 'month').endOf('month').format('YYYY-MM-DDTHH:mm:ss.sssZ'));

      setIsLoading(false);
    }

    fetchPeriod();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spin />
        </div>
      ) : (
        <div>
          <Row>
            <Col md={6} className="mb-4">
              <div className="d-flex flex-column">
                <Label>Period From</Label>
                <DatePicker
                  className="mb-3"
                  value={periodFrom ? moment(periodFrom, 'YYYY-MM-DD') : null}
                  onChange={(date, dateString) => setPeriodFrom(dateString)}
                  disabled
                />
              </div>
            </Col>
            <Col md={6} className="mb-4">
              <div className="d-flex flex-column">
                <Label>Period To</Label>
                <DatePicker
                  className="mb-3"
                  value={periodTo ? moment(periodTo, 'YYYY-MM-DD') : null}
                  onChange={(date, dateString) => setPeriodTo(dateString)}
                />
              </div>
            </Col>
          </Row>

          <SubmitButtons
            id={id}
            back={back}
            body={{
              periodFrom,
              periodTo,
              PreviousBookingId: id,
            }}
            saveActionURL={saveActionURL}
          />
        </div>
      )}
    </>
  );
};

export default Renew;
