import { isEmpty } from 'lodash';
import React from 'react';

export default function ErrorMessage({ errors = {}, name = '' }) {
  if (!name && !isEmpty(errors) && !errors[name]) {
    return <></>;
  }

  return <span style={{ color: 'red', fontSize: '12px' }}>{errors[name]}</span>;
}
