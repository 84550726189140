export const customerFilter = [
  { title: 'Phone', value: 'phone' },
  { title: 'Email', value: 'email' },
];

export const CustomerTableColumns = [
  'id',
  'title',
  'lastName',
  'chineseLastName',
  'email',
  'phone',
  'status',
  'ReceiveSms',
  'createdAt',
];

export const customerTitleOptions = [
  { value: 'Mr', label: 'Mr' },
  { value: 'Mrs', label: 'Mrs' },
  { value: 'Ms', label: 'Ms' },
];

export const customerStatusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];

export const customerLanguageOptions = [
  { value: 'en', label: 'English' },
  { value: 'zh', label: 'Chinese' },
];

export const CustomerFormSchema = [
  { value: 'id', label: 'Id', type: 'text' },
  {
    value: 'title',
    label: 'Title',
    type: 'dropDown',
    options: [
      { value: 'Mr', label: 'Mr' },
      { value: 'Mrs', label: 'Mrs' },
      { value: 'Ms', label: 'Ms' },
    ],
  },
  { value: 'lastName', label: 'Last Name', type: 'text' },
  { value: 'firstName', label: 'First name', type: 'text' },
  { value: 'chineseLastName', label: 'Chinese Last Name', type: 'text' },
  { value: 'chineseFirstName', label: 'Chinese First name', type: 'text' },
  { value: 'email', label: 'Email', type: 'text' },
  { value: 'phone', label: 'Phone', type: 'text' },
  {
    value: 'status',
    label: 'Status',
    type: 'dropDown',
    options: [
      { value: 'Active', label: 'Active' },
      { value: 'Inactive', label: 'Inactive' },
    ],
  },
  {
    value: 'language',
    label: 'Language',
    type: 'dropDown',
    options: [
      { value: 'en', label: 'English' },
      { value: 'zh', label: 'Chinese' },
    ],
  },
  { value: 'address', label: 'Address', type: 'richText' },
  { value: 'isMajor', label: 'Is Major', type: 'radio' },
  { value: 'MajorCustomerId', label: 'Major Customer Id', type: 'text' },
  { value: 'isAgreedToNotice', label: 'Is Agreed To Notice', type: 'radio' },
  { value: 'password', label: 'Password', type: 'password' },
];

export const CustomerSchema = [
  { name: 'id', label: 'Id', type: 'string' },
  { name: 'originalId', label: 'Original Id', type: 'string' },
  { name: 'title', label: 'Title', type: 'dropdown' },
  { name: 'lastName', label: 'Last Name', type: 'string', required: true },
  { name: 'firstName', label: 'First Name', type: 'string', required: true },
  { name: 'chineseLastName', label: 'Chinese Last Name', type: 'string' },

  { name: 'chineseFirstName', label: 'Chinese First Name', type: 'string' },
  { name: 'email', label: 'Email', type: 'string', required: true },
  { name: 'phone', label: 'Phone', type: 'string', required: true },
  { name: 'status', label: 'Status', type: 'dropdown', required: true },
  { name: 'language', label: 'Language', type: 'dropdown' },
  {
    name: 'isEmailVerified',
    label: 'Email Verified',
    type: 'radio',
    remarks:
      'If Status is "inactive" and Email verified is "No", a verification email will be sent to customer.',
  },
  { name: 'address', label: 'Address', type: 'textarea' },
  { name: 'region', label: 'Region', type: 'dropdown' },
  { name: 'district', label: 'District', type: 'dropdown' },

  { name: 'isReceiveSms', label: 'Receive Sms', type: 'radio', required: true },
  // { name: 'isReceivePromotion', type: 'BOOLEAN' },
  // { name: 'isAgreedToTerms', type: 'BOOLEAN' },
  // { name: 'isAgreedToPrivacy', type: 'BOOLEAN' },
  // { name: 'isAgreedToNotice', type: 'BOOLEAN' },

  { name: 'isMajor', label: 'Is Major', type: 'radio', required: true },
  { name: 'majorCustomerId', label: 'Major Customer Id', type: 'string' },
  { name: 'isAgreedToNotice', label: 'Is Agreed To Notice', type: 'radio' },
  { name: 'password', label: 'Password', type: 'password' },
  { name: 'isReceivePromotion', label: 'Receive Promotion', type: 'radio' },
];
