import React from 'react';
import axios from 'axios';

export const SkiDataActions = (id) => {
  const retryAPI = async () => {
    const retryURL = `${process.env.REACT_APP_API_DOMAIN}/skidata/${id}/retry`;

    try {
      const response = await axios.post(retryURL);
      console.log({ response });
      alert('Record successfully created');
    } catch (e) {
      console.log({ e });
      alert('Something went wrong.');
    }
  };

  return (
    <button className="btn btn-light btn-xs" onClick={retryAPI}>
      Retry
    </button>
  );
};
