import React, { useEffect, useState } from 'react'
import { Input } from 'reactstrap'
import { Spin } from 'antd'
import { CreateRecordButtons } from '../../../components/common/profile'
import axios from 'axios'
import Cookies from 'universal-cookie/cjs';

const cookies = new Cookies();

const UploadFile = ({ type, back, name, fileType, uploadFileURL, getDataURL }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [uploadingMsg, setUploadingMsg] = useState(false)
  const [currentFile, setCurrentFile] = useState('')
  const [file, setFile] = useState('')

  useEffect(() => {
    if (type === 'edit') {
      setIsLoading(true)

      axios
        .get(getDataURL, { headers: { token: cookies.get('token') } })
        .then(({ data: { data } }) => {
          const { file } = data || {}
          setCurrentFile(file)
          setIsLoading(false)
        })
        .catch(error => {
          setCurrentFile('Error retrieving file')
          console.log(error)
          setIsLoading(false)
        })
    }
  }, [])

  const handleChooseFile = e => {
    e.persist()
    const fd = new FormData()
    const chosenFile = e.target.files

    if (chosenFile.length > 0) {
      fd.append('file', chosenFile[0], chosenFile[0].name)
      setFile(fd)
    }
  }

  const handleSave = () => {
    setIsLoading(true)
    setUploadingMsg(true)

    const submitFile =
      type === 'add' ? axios.post(uploadFileURL, file) : axios.put(uploadFileURL, file)

    submitFile
      .then(() => {
        setIsLoading(false)
        setUploadingMsg(false)

        if (window.confirm('File successfully uploaded')) {
          back && back()
        }
      })
      .catch(error => {
        setIsLoading(false)
        setUploadingMsg(false)

        alert('Something went wrong. Please try again.')
        console.log(error.response)
      })
  }

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          {uploadingMsg && <h5 style={{ marginRight: 15 }}>Uploading file...</h5>}
          <Spin />
        </div>
      ) : (
        <>
          <Input
            type="file"
            accept={`.${fileType}`}
            name={name}
            style={{
              border: '1px solid #ced4da',
              padding: 5,
              borderRadius: '4px',
              marginBottom: 10
            }}
            onChange={handleChooseFile}
          />
          {type === 'edit' && <span>Current File: {currentFile}</span>}

          <CreateRecordButtons handleSave={handleSave} cancel={back} />
        </>
      )}
    </>
  )
}

export default UploadFile
