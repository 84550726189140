function callPostAPI (url, data, successCallback, failCallback, headers = {}) {
  console.log('>>>>>POST ' + url)
  console.log(data)
  headers = {
    'Accept': 'application/json, text/plain, /',
    'Content-Type': 'application/json',
    ...headers
  }

  fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers
  })
    .then(res => {
      if (res.status === 200 || res.status === 201) { return res.json() } else { throw res }
    })
    .then(response => successCallback(response))
    .catch(error => failCallback(error))
}

function callPutAPI (url, data, successCallback, failCallback, headers = {}) {
  console.log('>>>>>PUT ' + url)
  console.log(data)
  headers = {
    'Accept': 'application/json, text/plain, /',
    'Content-Type': 'application/json',
    ...headers
  }

  fetch(url, {
    method: 'PUT',
    body: JSON.stringify(data), // must be an object
    headers
  })
    .then(res => {
      if (res.status === 200 || res.status === 201) { return res.json() } else { throw res }
    })
    .then(response => successCallback(response))
    .catch(error => failCallback(error))
}

function callGetAPI (url, successCallback, failCallback, headers = {}) {
  console.log('>>>>>GET ' + url)
  headers = {
    'Accept': 'application/json, text/plain, /',
    ...headers
  }

  fetch(url, { method: 'GET', headers })
    .then(res => {
      if (res.status === 200) { return res.json() } else { throw res }
    })
    .then(response => successCallback(response))
    .catch(error => failCallback(error))
}

export { callPostAPI, callGetAPI, callPutAPI }
