import React, { useEffect, useRef, useState } from 'react';
import { isArray } from 'lodash';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import { capitalize } from '../../../util/generalHelper';
import {OperationReportPaymentCsv, OperationReportSummaryCsv} from "../../../containers/Custom/Report";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ExportButton = styled.div`
  border: none;
  background: transparent;
  color: rgb(24, 144, 255);
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export default function useRenderOperationReport({
  getAllRecords,
  filter,
  cookies,
  wrapExcelString,
  sortBy,
  pagination,
}) {
  const [operationReport, setOperationReport] = useState({
    paymentReport: [],
    detailedSummaryReport: [],
    generalSummaryReport: [],
  });

  const paymentReportRef = useRef();
  const detailedSummaryReportRef = useRef();
  const generalSummaryReportRef = useRef();

  function getOperationReport(type, ref) {
    const updatedFilter = Object.entries(filter).reduce((output, [key, value]) => {
      if (value !== '' && !Array.isArray(value)) {
        output[key] = value;
      }
      if (Array.isArray(value) && value.length > 0) {
        if (value.filter((e) => e).length === 0) return output;
        output[key] = value.filter((e) => e).toString();
      }
      if (value.hasOwnProperty('value') && value.hasOwnProperty('label')) {
        output[key] = value.value;
      }

      return output;
    }, {});

    axios
      .get(getAllRecords, {
        params: {
          reportType: type,
          ...updatedFilter,
          limit: 3000,
          page: pagination.current,
          sortField: sortBy.columnKey,
          sortOrder: sortBy.order,
        },
        headers: { token: cookies.get('token') },
      })
      .then((response) => {
        let responseData = response.data.data.rows || response.data.data;
        if (responseData.length === 0) {
          alert('No data found');
          return {};
        }

        let { requiredKeys, engHeader, chineseHeader } = OperationReportSummaryCsv;
        if (type === 'paymentReport') {
          requiredKeys = OperationReportPaymentCsv.requiredKeys;
          engHeader = OperationReportPaymentCsv.engHeader;
          chineseHeader = OperationReportPaymentCsv.chineseHeader;
        }
        responseData = responseData.map(
          ({
             prevRegistrationMark,
             registrationMark,
             ...restData
           }) => ({
            ...restData,
            registrationMark,
            originalRegistrationMark: prevRegistrationMark === registrationMark ? "N/A" : prevRegistrationMark,
          }));
        const filteredData = responseData
          .map((e) =>
            Object.fromEntries(
              Object.entries(e).filter(([key]) => requiredKeys.indexOf(key) !== -1)
            )
          )
          .map((e) => {
            if (Object.keys(e).filter((v) => v === 'paymentMethod').length === 0) {
              return { ...e, paymentMethod: 'paymentMethod' };
            }
            return e;
          });

        const allRecords = filteredData.map(({ id, refundAmount, ...rest }) =>
          requiredKeys
            .filter((e) => e !== 'refundAmount')
            .map((requiredKey) => {
              if (rest.paymentStatus && refundAmount && requiredKey === 'paymentStatus')
                return wrapExcelString(`${rest.paymentStatus}(refunded)`);
              if (!rest.balance && requiredKey === 'balance')
                return wrapExcelString('0');
              if (!rest.other && requiredKey === 'other')
                return wrapExcelString(' ');
              return wrapExcelString(rest[requiredKey] || '');
            })
        );

        setOperationReport({ ...operationReport, [type]: [chineseHeader, engHeader, ...allRecords] });

        ref.current.link.click();
      });
  }

  function RenderOperationReportButton() {
    return (
      <Container>
        <ExportButton
          type="button"
          onClick={() => {
            getOperationReport('paymentReport', paymentReportRef);
          }}
        >
          Download Payment Report CSV file
        </ExportButton>
        <CSVLink
          ref={paymentReportRef}
          data={operationReport.paymentReport}
          filename="Payment_Report.csv"
        />

        <ExportButton
          type="button"
          onClick={() => {
            getOperationReport('detailedSummaryReport', detailedSummaryReportRef);
          }}
        >
          Download Detailed On/ Offline Payment Report CSV file
        </ExportButton>
        <CSVLink
          ref={detailedSummaryReportRef}
          data={operationReport.detailedSummaryReport}
          filename="Detailed_On/Offline_Payment_Report.csv"
        />

        <ExportButton
          type="button"
          onClick={() => {
            getOperationReport('generalSummaryReport', generalSummaryReportRef);
          }}
        >
          Download Payment Summary Report CSV file
        </ExportButton>
        <CSVLink
          ref={generalSummaryReportRef}
          data={operationReport.generalSummaryReport}
          filename="Payment_Summary_Report.csv"
        />
      </Container>
    );
  }

  return {
    RenderOperationReportButton,
  };
}
