import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie/cjs';
import axios from 'axios';
import { Radio, Spin } from 'antd';
import { Button, Col, Collapse, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { adminRoleDefaultObj } from '../Order/constants';
import { CreateRecordButtons, UpdateRecordButtons } from '../../../components/common/profile';
import { createRecord, updateRecord } from '../../../util/formHelper';

const cookies = new Cookies();
const ROLE_PERMISSION = 'role-permission';

const PERMISSION_ACTIONS = [
  { value: 'READ', label: 'Read' },
  { value: 'CREATE', label: 'Create' },
  { value: 'UPDATE', label: 'Update' },
  { value: 'DELETE', label: 'Delete' },
  { value: 'EXPORT', label: 'Export' },
];

const PERMISSION_MODULES = [
  { value: 'Admin', label: 'Admin', levels: ['Company'] },
  { value: 'AdminRole', label: 'Admin Role', levels: ['Company'] },
  { value: 'PPCard', label: 'PPCard', levels: ['Company'] },
  { value: 'News', label: 'News & Announcement', levels: ['Company'] },
  { value: 'FileUpload', label: 'File Upload', levels: ['Company', 'Carpark'] },
  { value: 'StaticInfo', label: 'Static Info', levels: ['Company'] },
  { value: 'NotificationTemplate', label: 'Notification Template', levels: ['Company'] },

  { value: 'CarPark', label: 'CarPark', levels: ['Company', 'Carpark'] },
  { value: 'ParkingService', label: 'Parking Service', levels: ['Company'] },
  { value: 'ParkingPlan', label: 'Parking Plan', levels: ['Company', 'Carpark'] },
  { value: 'Customer', label: 'Customer', levels: ['Company', 'Carpark'] },

  { value: 'SkiData', label: 'Ski Data', levels: ['Company', 'Carpark'] },

  { value: 'Order', label: 'Order', levels: ['Company', 'Carpark'] },

  { value: 'Payment', label: 'Payment', levels: ['Company', 'Carpark'] },
  { value: 'SevenEleven', label: 'Seven Eleven (Raw Data)', levels: ['Company'] },
  { value: 'CQM', label: 'CQM (Raw Data)', levels: ['Company'] },
  { value: 'Award', label: 'Award', levels: ['Company'] },

  {
    value: 'OnlineTransactionReport',
    label: 'Online Transaction Report',
    levels: ['Company', 'Carpark'],
  },
  {
    value: 'OfflineTransactionReport',
    label: 'Offline Transaction Report',
    levels: ['Company', 'Carpark'],
  },
  { value: 'ChangeLpnReport', label: 'Change LPN Report', levels: ['Company', 'Carpark'] },

  { value: 'BuyNewReport', label: 'Buy New Report', levels: ['Company', 'Carpark'] },

  { value: 'SummaryReport', label: 'Summary Report', levels: ['Company', 'Carpark'] },
  { value: 'OperationReport', label: 'Operation Report', levels: ['Company', 'Carpark'] },
  {
    value: 'IParkTransactionReport',
    label: 'IPark Transaction Report',
    levels: ['Company', 'Carpark'],
  },

  { value: 'Banner', label: 'Banner', levels: ['Company'] },
  { value: 'WaitingList', label: 'Waiting List', levels: ['Company', 'Carpark'] },
];

const EXCEPTION_MODULES = [
  { module: 'StaticInfo', action: 'CREATE' },
  { module: 'StaticInfo', action: 'DELETE' },

  { module: 'FileUpload', action: 'DELETE' },
  { module: 'FileUpload', action: 'CREATE' },
  { module: 'FileUpload', action: 'UPDATE' },

  { module: 'SevenEleven', action: 'DELETE' },
  { module: 'SevenEleven', action: 'CREATE' },
  { module: 'SevenEleven', action: 'UPDATE' },

  { module: 'CQM', action: 'DELETE' },
  { module: 'CQM', action: 'CREATE' },
  { module: 'CQM', action: 'UPDATE' },

  { module: 'OperationReport', action: 'DELETE' },
  { module: 'OperationReport', action: 'CREATE' },

  { module: 'OnlineTransactionReport', action: 'CREATE' },
  { module: 'OnlineTransactionReport', action: 'DELETE' },
  { module: 'OnlineTransactionReport', action: 'UPDATE' },

  { module: 'OfflineTransactionReport', action: 'CREATE' },
  { module: 'OfflineTransactionReport', action: 'DELETE' },
  { module: 'OfflineTransactionReport', action: 'UPDATE' },

  { module: 'ChangeLpnReport', action: 'CREATE' },
  { module: 'ChangeLpnReport', action: 'DELETE' },
  { module: 'ChangeLpnReport', action: 'UPDATE' },

  { module: 'BuyNewReport', action: 'CREATE' },
  { module: 'BuyNewReport', action: 'DELETE' },
  { module: 'BuyNewReport', action: 'UPDATE' },

  { module: 'SummaryReport', action: 'CREATE' },
  { module: 'SummaryReport', action: 'DELETE' },
  { module: 'SummaryReport', action: 'UPDATE' },
];

const Checkbox = ({ permissions, module, action, disabled, handleChange }) => {
  const isAllowed = permissions.some(({ module: m, action: a }) => m === module && a === action);
  const isForbidden = EXCEPTION_MODULES.some(
    ({ module: m, action: a }) => m === module && a === action
  );

  const isExport = !module.includes('Report') && action === 'EXPORT';

  return (
    <td>
      <FormGroup check style={{ padding: 0 }}>
        <Input
          type="checkbox"
          style={{ margin: 0, position: 'relative' }}
          checked={isAllowed}
          disabled={disabled || isForbidden || isExport}
          onChange={(e) => handleChange(module, action, e.target.checked)}
        />
      </FormGroup>
    </td>
  );
};

const AdminRoleForm = ({ fetch, update, type, cancel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [AdminRole, setAdminRole] = useState(adminRoleDefaultObj);
  const [RolePermissions, setRolePermissions] = useState([]);
  const [collapsed, setCollapsed] = useState([ROLE_PERMISSION]);

  const fetchExistingRecord = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(fetch, { headers: { token: cookies.get('token') } });
      const { RolePermissions: rolePermissions, ...adminRole } = data;
      setAdminRole(adminRole);
      setRolePermissions(rolePermissions);
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  const handleChange = (module, action, checked) => {
    if (checked) {
      const newRolePermissions = [...RolePermissions];
      newRolePermissions.push({
        AdminRoleId: '',
        action,
        module,
      });
      setRolePermissions(newRolePermissions);
    } else {
      const newRolePermissions = [...RolePermissions].filter(({ module: m, action: a }) => {
        if (action === 'READ' && m === module) return false;
        return !(m === module && a === action);
      });
      setRolePermissions(newRolePermissions);
    }
  };

  const handleSave = async () => {
    const { id: AdminRoleId, name, isAllGranted, isEnable, level } = AdminRole;

    const allowedModules = PERMISSION_MODULES.filter(({ levels }) => levels.includes(level)).map(
      ({ value }) => value
    );
    const cleanedRolePermissions = RolePermissions.map(({ id, action, module }) => ({
      id,
      AdminRoleId,
      action,
      module,
    })).filter(({ module }) => allowedModules.includes(module));

    let body = {
      name,
      isAllGranted,
      RolePermissions: cleanedRolePermissions,
      isEnable,
      level,
    };

    body = JSON.parse(JSON.stringify(body));

    if (type === 'edit') {
      // console.log({ update, body });
      updateRecord(update, body);
    } else {
      // console.log({ body });
      createRecord(`${process.env.REACT_APP_API_DOMAIN}/admin-role`, body);
    }
  };

  useEffect(() => {
    if (type === 'edit') {
      setIsLoading(true);
      fetchExistingRecord();
    } else {
      setAdminRole(adminRoleDefaultObj);
      setRolePermissions([]);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spin />
        </div>
      ) : (
        <>
          <Row>
            <Col md={6} className="mb-4">
              <Label>Role Name</Label>
              <Input
                type="text"
                value={AdminRole.name}
                name="name"
                // onChange={(e) => console.log(e.target) }
                onChange={(e) => setAdminRole({ ...AdminRole, name: e.target.value })}
              />
            </Col>
            <Col md={6} className="mb-4">
              <div className="d-flex flex-column">
                <Label>Is All Granted?</Label>
                <Radio.Group
                  name="isAllGranted"
                  value={!!AdminRole.isAllGranted}
                  onChange={(e) => setAdminRole({ ...AdminRole, isAllGranted: e.target.value })}
                  className="ml-1 mt-1 mb-3"
                >
                  <Radio value className="mr-5">
                    Yes
                  </Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6} className="mb-4">
              <div className="d-flex flex-column">
                <Label>Enabled?</Label>
                <Radio.Group
                  name="isEnable"
                  value={!!AdminRole.isEnable}
                  onChange={(e) => setAdminRole({ ...AdminRole, isEnable: e.target.value })}
                  className="ml-1 mt-1 mb-3"
                >
                  <Radio value className="mr-5">
                    Yes
                  </Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </div>
            </Col>
            <Col md={6} className="mb-4">
              <div className="d-flex flex-column">
                <Label>Level</Label>
                <Radio.Group
                  name="level"
                  value={AdminRole.level}
                  onChange={(e) => setAdminRole({ ...AdminRole, level: e.target.value })}
                  className="ml-1 mt-1 mb-3"
                >
                  <Radio value="Company" className="mr-5">
                    Company
                  </Radio>
                  <Radio value="Carpark">Carpark</Radio>
                </Radio.Group>
              </div>
            </Col>
          </Row>

          {!AdminRole.isAllGranted && (
            <div key={ROLE_PERMISSION}>
              <Button
                color="light"
                style={{
                  marginBottom: '1rem',
                  textAlign: 'left',
                  width: '100%',
                }}
                onClick={() => {
                  const isCollapsed = collapsed.includes(ROLE_PERMISSION)
                    ? collapsed.filter((existingType) => existingType !== ROLE_PERMISSION)
                    : [...collapsed, ROLE_PERMISSION];

                  setCollapsed(isCollapsed);
                }}
              >
                <span>&#9660; &nbsp; Role Permissions</span>
              </Button>
              <Collapse
                isOpen={!!collapsed.includes(ROLE_PERMISSION)}
                style={{ marginBottom: 50, overflowX: 'auto' }}
              >
                <Table>
                  <thead>
                    <tr>
                      <th scope="col">Module</th>
                      {PERMISSION_ACTIONS.map(({ label: action }) => (
                        <th key={action} scope="col">
                          {action}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {PERMISSION_MODULES.filter(({ levels }) =>
                      levels.includes(AdminRole.level)
                    ).map(({ value, label }) => {
                      const isReadAllowed = RolePermissions.some(
                        ({ module, action }) => module === value && action === 'READ'
                      );

                      return (
                        <tr key={label}>
                          <td>{label}</td>
                          {PERMISSION_ACTIONS.map(({ value: action }) => (
                            <Checkbox
                              key={action}
                              permissions={RolePermissions}
                              module={value}
                              action={action}
                              handleChange={handleChange}
                              disabled={!isReadAllowed && action !== 'READ'}
                            />
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Collapse>
            </div>
          )}

          {type === 'edit' ? (
            <UpdateRecordButtons handleSave={handleSave} cancel={cancel} allowDelete={false} />
          ) : (
            <CreateRecordButtons handleSave={handleSave} cancel={cancel} />
          )}
        </>
      )}
    </>
  );
};

export default AdminRoleForm;
