export const SevenElevenTableColumns = [
  'id',
  'file',
  'line',
  'data',
  'account',
  'transactionTime',
  'amount',
  'createdAt',
  'updatedAt',
  'transactionId',
  'status'
]
