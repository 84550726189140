import React, { useState } from 'react';
import { Table as AntTable, Modal } from 'antd';
import axios from 'axios';
import Cookies from 'universal-cookie/cjs';
import { cleanSkiDataMonthlyParkerData, skiDataFailedRecordsColumn } from './constants';

const cookies = new Cookies();

export default function useSyncCarPark() {
  const [showModal, setShowModal] = useState(false);
  const [skiData, setSkiData] = useState({ successRecords: [], failedRecords: [] });
  const [isLoading, setIsLoading] = useState(false);

  function syncCarPark(carParkId, registrationMark = '') {
    setIsLoading(true);
    const data = {};
    if (registrationMark) Object.assign(data, { registrationMark });
    return axios
      .post(`${process.env.REACT_APP_API_DOMAIN}/SkiDataMonthlyParker/syncCarPark/${carParkId}`, data, {
        headers: { token: cookies.get('token') },
      })
      .then(({ data }) => {
        console.log(data);
        const cleanData = cleanSkiDataMonthlyParkerData(data);
        setSkiData(cleanData);
        setIsLoading(false);
        setShowModal(true);
      })
      .catch((err) => {
        setIsLoading(false);
        alert(err.error || err);
      });
  }

  function SkiDataTable() {
    return (
      <>
        <Modal
          onCancel={() => setShowModal(false)}
          title="SkiData Records"
          visible={showModal}
          centered
          footer={null}
          width="80%"
          afterClose={() => setSkiData({})}
        >
          <div>
            <span style={{ fontSize: 20 }}>Success Records</span>
          </div>
          <AntTable
            className="w-100"
            columns={skiDataFailedRecordsColumn()}
            rowKey="id"
            dataSource={skiData.successRecords}
            loading={isLoading}
            scroll={{ x: true }}
            style={{ marginTop: 20 }}
          />

          <div>
            <span style={{ fontSize: 20 }}>Failed Records</span>
          </div>
          <AntTable
            className="w-100"
            columns={skiDataFailedRecordsColumn()}
            rowKey="id"
            dataSource={skiData.failedRecords}
            loading={isLoading}
            scroll={{ x: true }}
            style={{ marginTop: 20 }}
          />
        </Modal>
      </>
    );
  }

  return { SkiDataTable, syncCarPark };
}
