import React from 'react';

const UpdateRecordButtons = ({ handleSave, handleDelete, cancel, allowDelete = true }) => (
  <div className="d-flex justify-content-end mt-5">
    <button className="btn btn-light btn-sm mr-3" onClick={cancel}>
      Cancel
    </button>
    {allowDelete && (
      <button className="btn btn-danger btn-sm mr-3" onClick={handleDelete}>
        Delete
      </button>
    )}
    <button className="btn btn-primary btn-sm" onClick={handleSave}>
      Save
    </button>
  </div>
);

export default UpdateRecordButtons;
