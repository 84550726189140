import { capitalize } from '../../../util/generalHelper';

export const IParkCouponFilter = [
  { title: 'Created Date From', value: 'createdDateFrom', type: 'datePicker' },
  { title: 'Created Date To', value: 'createdDateTo', type: 'datePicker' },
  { title: 'Updated Date From', value: 'updatedDateFrom', type: 'datePicker' },
  { title: 'Updated Date To', value: 'updatedDateTo', type: 'datePicker' },
  { title: 'Coupon ID', value: 'couponId' },
  { title: 'Promotion ID', value: 'promotionId' },
  { title: 'Member Phone Number', value: 'memberPhoneNumber' },
];
export const IParkTransactionFilter = [
  { title: 'Settlement Date From', value: 'settlementDateTimeFrom', type: 'datePicker' },
  { title: 'Settlement Date To', value: 'settlementDateTimeTo', type: 'datePicker' },
  { title: 'Transaction Date From', value: 'transactionDateTimeFrom', type: 'datePicker' },
  { title: 'Transaction Date To', value: 'transactionDateTimeTo', type: 'datePicker' },
  { title: 'Transaction Id', value: 'transactionId' },
  { title: 'Carpark Code', value: 'carParkCode' },
  { title: 'LPN', value: 'lpn' },
  {
    title: 'Status',
    value: 'status',
    type: 'checkbox',
    options: [
      { label: 'All', value: '' },
      {
        label: 'Pending',
        value: 'Pending',
      },
      {
        label: 'Settled',
        value: 'Settled',
      },
    ],
  },
];

export const reportFilter = [
  { title: 'Transaction Date From', value: 'transactionDateFrom', type: 'datePicker' },
  { title: 'Transaction Date To', value: 'transactionDateTo', type: 'datePicker' },
];

export const orderFilter = [{ title: 'Registration Mark', value: 'registrationMark' }];

export const skidataFilter = [
  {
    title: 'Action',
    value: 'action',
    type: 'dropdown',
    options: [
      {
        label: 'Change License Plate',
        value: 'changeLicensePlate',
      },
      {
        label: 'Change License Plate By Admin',
        value: 'changeLicensePlateByAdmin',
      },
      {
        label: 'Change Back Temporary Lpn',
        value: 'changeBackTempLpn',
      },
      {
        label: 'Renew',
        value: 'renew',
      },
      {
        label: 'Update Info',
        value: 'updateInfo',
      },
    ],
  },
  {
    title: 'Status',
    value: 'status',
    type: 'dropdown',
    options: [
      {
        label: 'Pending',
        value: 'pending',
      },
      {
        label: 'Scheduled',
        value: 'scheduled',
      },
      {
        label: 'Processing',
        value: 'processing',
      },
      {
        label: 'Success',
        value: 'success',
      },
      {
        label: 'Failed',
        value: 'failed',
      },
      {
        label: 'Cancelled',
        value: 'cancelled',
      },
    ],
  },
];

export const operationReportFilter = [
  { title: 'Carpark Code', value: 'carParkCode' },
  { title: 'Registration Mark', value: 'registrationMark' },
  { title: 'Payment Date(From)', value: 'transactionDateFrom', type: 'datePicker' },
  { title: 'Payment Date(To)', value: 'transactionDateTo', type: 'datePicker' },
  { title: 'Parking Service End Date(From)', value: 'bookingEndDateFrom', type: 'datePicker' },
  { title: 'Parking Service End Date(To)', value: 'bookingEndDateTo', type: 'datePicker' },
  {
    title: 'Order',
    value: 'bookingType',
    type: 'checkbox',
    options: [
      { label: 'All', value: '' },
      {
        label: 'New',
        value: 'new',
        exclusive: [
          { key: 'lpnChangeType', value: 'temporary' },
          { key: 'lpnChangeType', value: 'permanent' },
        ],
      },
      {
        label: 'Renew',
        value: 'renew',
        exclusive: [
          { key: 'lpnChangeType', value: 'temporary' },
          { key: 'lpnChangeType', value: 'permanent' },
        ],
      },
    ],
  },
  {
    title: 'LPN Change Type',
    value: 'lpnChangeType',
    type: 'radio',
    options: [
      { label: 'All', value: '' },
      {
        label: 'Temporary',
        value: 'temporary',
        exclusive: [
          { key: 'bookingType', value: 'new' },
          { key: 'bookingType', value: 'renew' },
        ],
      },
      {
        label: 'Permanent',
        value: 'permanent',
        exclusive: [
          { key: 'bookingType', value: 'new' },
          { key: 'bookingType', value: 'renew' },
        ],
      },
    ],
  },
  {
    title: 'Payment Type',
    value: 'paymentType',
    type: 'radio',
    options: [
      { label: 'All', value: '' },
      { label: 'Online', value: 'true' },
      { label: 'Offline', value: 'false' },
    ],
  },
  // {
  //   title: 'LPN',
  //   value: 'LPN',
  //   type: 'radio',
  //   options: [
  //     { label: 'All', value: '' },
  //     { label: 'Temptemporary', value: 'temptemporary' },
  //     { label: 'Permanent', value: 'permanent' },
  //   ],
  // },
  {
    title: 'Payment Status',
    value: 'paymentStatus',
    type: 'checkbox',
    options: [
      { label: 'All', value: '' },
      { label: 'Settled', value: 'settled' },
      { label: 'Underpaid', value: 'underpaid' },
      { label: 'Overpaid', value: 'overpaid' },
      { label: 'Pending', value: 'pending' },
      // {
      //   label: 'Refunded',
      //   value: 'redunded',
      // },
    ],
  },
  {
    title: 'Refunded',
    value: 'isRefunded',
    type: 'radio',
    options: [
      {
        label: 'All',
        value: '',
      },
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false,
      },
    ],
  },
  {
    title: 'Booking Status',
    value: 'bookingStatus',
    type: 'checkbox',
    options: [
      { label: 'All', value: '' },
      { label: 'Paid', value: 'paid' },
      { label: 'Active', value: 'active' },
      { label: 'Cancelled', value: 'cancelled' },
      { label: 'Terminated', value: 'terminated' },
      { label: 'Pending', value: 'pending' },
    ],
  },
];

export const operationReportColumns = [
  'carParkCode',
  'lastPaymentDate',
  'lastPaymentTime',
  'paymentPlatform',
  'originalRegistrationMark',
  'registrationMark',
  'transactionId',
  'validFrom',
  'validTo',
  'vehicleType',
  'parkingPlan',
  'paymentMethod',
  'monthlyFee',
  'paidAmount',
  'paymentStatus',
  'balance',
  'orderType',
  'bookingStatus',
];

export const OnlineTransactionColumns = [
  'paymentDate',
  'time',
  'transactionId',
  'carPark',
  'lpn',
  'paidAmount',
  'paymentMethod',
  'paymentService',
  'parkingServiceStartDate',
  'parkingServiceEndDate',
  'paymentDateTime',
  'platform',
];

export const OnlineTransactionPDF = {
  head: [
    OnlineTransactionColumns.map((column) => capitalize(column.replace(/([A-Z])/g, ' $1').trim())),
  ],
  body: [
    ['David', 'david@example.com', 'Sweden'],
    ['Castille', 'castille@example.com', 'Spain'],
    // ...
  ],
};

export const OfflineTransactionColumns = [
  'id',
  'paymentDate',
  'time',
  'accountCode',
  'carPark',
  'lpn',
  'paidAmount',
  'paymentMethod',
  'paymentService',
  'parkingServiceFee',
  'balance',
  'status',
  'parkingServiceStartDate',
  'parkingServiceEndDate',
  'skidataExtendDate',
  'trasactionId',
  'platform',
];

export const ChangeLpnColumns = [
  'id',
  'requestDate',
  'time',
  'changeLpnMethod',
  'effectiveDate',
  'originalLpn',
  'newLpn',
  'fee',
];

export const BuyNewColumns = [
  'id',
  'paymentDate',
  'time',
  'paymentMethod',
  'transactionId',
  'paidAmount',
  'paymentService',
  'carPark',
  'lpn',
  'parkingServiceStartDate',
  'parkingServiceEndDate',
  'pickUpMethod',
  'deliveryAddress',
];

export const AccMpColumns = [
  'id',
  'carPark',
  'paymentDate',
  'serviceStartDate',
  'serviceExpiryDate',
  'unitPrice',
  'quantity',
  'amount',
];

export const IParkTransactionColumns = [
  'settlementDate',
  'settlementTime',
  'transactionDate',
  'transactionTime',
  'transactionId',
  'merchTranRef',
  'phone',
  'CarPark',
  'lpn',
  'timeIn',
  'timeOut',
  'duration',
  'Status',
  'parkingFee',
  'freeParkingHour',
  'freeParkingAmount',
  'discountType',
  'paidAmount',
  'balance',
  'paymentMethod',
  'cardNumber / Reference Number',
  'Platform',
  'outstandingPaymentErrorCode',
  'errorDescription',
  'remarks',
];

export const IParkCouponColumns = [
  'couponId',
  'promotionId',
  'promotionName',
  'totalValue',
  'outstandingValue',
  'promotionType',
  'memberPhoneNumber',
  'createdDate',
  'updatedDate',
  'validityType',
  'validDays',
  'validFrom',
];

export const SummaryAntTableColumns = [
  // {
  //   title: 'Id',
  //   dataIndex: 'id',
  //   key: 'id'
  // },
  {
    title: 'Transaction Date',
    dataIndex: 'transactionDate',
    key: 'transactionDate',
  },
  {
    title: 'Car Park Code',
    dataIndex: 'carParkCode',
    key: 'carParkCode',
  },
  {
    title: 'Current Month i-Monthly',
    children: [
      {
        title: 'Quantity',
        dataIndex: 'currentQuantity',
        key: 'currentQuantity',
      },
      {
        title: 'Amount',
        dataIndex: 'currentAmount',
        key: 'currentAmount',
      },
    ],
  },
  {
    title: 'Next Month i-Monthly',
    children: [
      {
        title: 'Quantity',
        dataIndex: 'nextQuantity',
        key: 'nextQuantity',
      },
      {
        title: 'Amount',
        dataIndex: 'nextAmount',
        key: 'nextAmount',
      },
    ],
  },
  {
    title: 'Monthly Service Q',
    dataIndex: 'monthlyServiceQ',
    key: 'monthlyServiceQ',
  },
  {
    title: 'Monthly Service A',
    dataIndex: 'monthlyServiceA',
    key: 'monthlyServiceA',
  },
  {
    title: 'Discount Applied',
    dataIndex: 'discountApplied',
    key: 'discountApplied',
  },
  {
    title: 'Hourly Parking',
    children: [
      {
        title: 'Quantity',
        dataIndex: 'hourlyParkingQuantity',
        key: 'hourlyParkingQuantity',
      },
      {
        title: 'Amount',
        dataIndex: 'hourlyParkingAmount',
        key: 'hourlyParkingAmount',
      },
    ],
  },
  {
    title: 'One Time Payment',
    children: [
      {
        title: 'Quantity',
        dataIndex: 'oneTimeQuantity',
        key: 'oneTimeQuantity',
      },
      {
        title: 'Amount',
        dataIndex: 'oneTimeAmount',
        key: 'oneTimeAmount',
      },
    ],
  },
  {
    title: 'Change Reg. Mark',
    children: [
      {
        title: 'Quantity',
        dataIndex: 'changeRegMarkQuantity',
        key: 'changeRegMarkQuantity',
      },
      {
        title: 'Amount',
        dataIndex: 'changeRegMarkAmount',
        key: 'changeRegMarkAmount',
      },
    ],
  },
  {
    title: 'Total Receipt',
    dataIndex: 'totalReceipt',
    key: 'totalReceipt',
  },
  {
    title: 'Total Revenue',
    dataIndex: 'totalRevenue',
    key: 'totalRevenue',
  },
  {
    title: 'Paid By',
    children: [
      {
        title: 'VISA / Master',
        dataIndex: 'visaMaster',
        key: 'visaMaster',
      },
      {
        title: 'PPS',
        dataIndex: 'pps',
        key: 'pps',
      },
      {
        title: '7-11',
        dataIndex: 'sevenEleven',
        key: 'sevenEleven',
      },
      {
        title: 'CQM',
        dataIndex: 'cqm',
        key: 'cqm',
      },
      {
        title: 'FPS',
        dataIndex: 'fps',
        key: 'fps',
      },
      {
        title: 'O!e Pay',
        dataIndex: 'olePay',
        key: 'olePay',
      },
    ],
  },
];

const styles = { valign: 'middle', halign: 'center' };

export const SummaryPdfHead = [
  [
    '',
    '',
    { colSpan: 2, content: 'Current Month i-Monthly', styles },
    { colSpan: 2, content: 'Next Month i-Monthly', styles },
    '',
    '',
    '',
    { colSpan: 2, content: 'Hourly Parking', styles },
    { colSpan: 2, content: 'One Time Payment', styles },
    { colSpan: 2, content: 'Change Reg. Mark', styles },
    '',
    '',
    { colSpan: 6, content: 'Paid By', styles },
  ],
  [
    { content: 'Transaction', styles },
    { content: 'Car Park Code', styles },
    { content: 'Quantity', styles },
    { content: 'Amount', styles },
    { content: 'Quantity', styles },
    { content: 'Amount', styles },
    { content: 'Monthly Service Q', styles },
    { content: 'Monthly Service A', styles },
    { content: 'Discount Applied', styles },
    { content: 'Quantity', styles },
    { content: 'Amount', styles },
    { content: 'Quantity', styles },
    { content: 'Amount', styles },
    { content: 'Quantity', styles },
    { content: 'Amount', styles },
    { content: 'Total Receipt', styles },
    { content: 'Total Revenue', styles },
    { content: 'Visa / Master', styles },
    { content: 'PPS', styles },
    { content: '7-11', styles },
    { content: 'CQM', styles },
    { content: 'FPS', styles },
    { content: 'O!e Pay', styles },
  ],
];

//  CSV

export const SummaryCsvColumns = [
  'Transaction Date',
  'Car Park Code',
  'Current Month i-Monthly Quantity',
  'Current Month i-Monthly Amount',
  'Next Month i-Monthly Quantity',
  'Next Month i-Monthly Amount',
  'Monthly Service Q',
  'Monthly Service A',
  'Discount Applied',
  'Hourly Parking Quantity',
  'Hourly Parking Amount',
  'One Time Payment Quantity',
  'One Time Payment Amount',
  'Change Reg. Mark Quantity',
  'Change Reg. Mark Amount',
  'Total Receipt',
  'Total Revenue',
  'Paid By Visa/Master',
  'Paid By PPS',
  'Paid By 7-11',
  'Paid By CQM',
  'Paid By FPS',
  'Paid By O!e Pay',
];

export const SummaryCsvData = [
  {
    transactionDate: '20-7-2019',
    carParkCode: 'P100',
    currentMonthIMonthly: {
      quantity: 'c/q',
      amount: 'c/a',
    },
    nextMonthIMonthly: {
      quantity: 'n/q',
      amount: 'n/a',
    },
    monthlyServiceQ: 'm/s Q',
    monthlyServiceA: 'm/s A',
    discountApplied: 'd/a',
    hourlyParking: {
      quantity: 'h/p/q',
      amount: 'h/p/a',
    },
    oneTimePayment: {
      quantity: 'o/t/q',
      amount: 'o/t/a',
    },
    changeRegMark: {
      quantity: 'c/r/m/q',
      amount: 'c/r/m/a',
    },
    totalReceipt: 't/rec',
    totalRevenue: 't/rev',
    paidBy: {
      visaMaster: 'v/m',
      pps: 'pps',
      sevenEleven: 's/e',
      cqm: 'cqm',
      fps: 'fps',
      olePay: 'o/p',
    },
  },
];

// export const SummaryCsvHeaders = [
//   { label: 'Transaction Date', key: 'transactionDate' },
//   { label: 'Car Park Code', key: 'carParkCode' },
//   { label: 'Current Month i-Monthly Quantity', key: 'currentMonthIMonthly.quantity' },
//   { label: 'Current Month i-Monthly Amount', key: 'currentMonthIMonthly.amount' },
//   { label: 'Next Month i-Monthly Quantity', key: 'nextMonthIMonthly.quantity' },
//   { label: 'Next Month i-Monthly Amount', key: 'nextMonthIMonthly.amount' },
//   { label: 'Monthly Service Q', key: 'monthlyServiceQ' },
//   { label: 'Monthly Service A', key: 'monthlyServiceA' },
//   { label: 'Discount Applied', key: 'discountApplied' },
//   { label: 'Hourly Parking Quantity', key: 'hourlyParking.quantity' },
//   { label: 'Hourly Parking Amount', key: 'hourlyParking.amount' },
//   { label: 'One Time Payment Quantity', key: 'oneTimePayment.quantity' },
//   { label: 'One Time Payment Amount', key: 'oneTimePayment.amount' },
//   { label: 'Change Reg. Mark Quantity', key: 'changeRegMark.quantity' },
//   { label: 'Change Reg. Mark Amount', key: 'changeRegMark.amount' },
//   { label: 'Total Receipt', key: 'totalReceipt' },
//   { label: 'Total Revenue', key: 'totalRevenue' },
//   { label: 'Paid By Visa/Master', key: 'paidBy.visaMaster' },
//   { label: 'Paid By PPS', key: 'paidBy.visaMaster' },
//   { label: 'Paid By 7-11', key: 'paidBy.sevenEleven' },
//   { label: 'Paid By CQM', key: 'paidBy.cqm' },
//   { label: 'Paid By FPS', key: 'paidBy.fps' },
//   { label: 'Paid By O!e Pay', key: 'paidBy.olePay' }
// ]

export const OperationReportPaymentCsv = {
  requiredKeys: [
    'carParkCode',
    'lastPaymentDate',
    'lastPaymentTime',
    'lastPaymentDateAndTime',
    'paymentPlatform',
    'originalRegistrationMark',
    'registrationMark',
    'transactionId',
    'validFrom',
    'validTo',
    'vehicleType',
    'parkingPlan',
    'paymentMethod',
    'monthlyFee',
    'paidAmount',
    'paymentStatus',
    'balance',
    'orderType',
    'refundAmount',
  ],
  engHeader: [
    'Car Park Code',
    'Last Payment Date',
    'Last Payment Time',
    'Last Payment Date And Time',
    'Payment Platform',
    'Original Registration Mark',
    'Registration Mark',
    'Transaction Id',
    'Valid From',
    'Valid To',
    'Vehicle Type',
    'Parking Plan',
    'Payment Method',
    'Monthly Fee',
    'Paid Amount',
    'Payment Status',
    'Balance',
    'Order Type',
  ],
  chineseHeader: [
    '場號',
    '最後付款日期',
    '最後付款時間',
    '最近付款日期及時間',
    '付款平台',
    '原有車牌號碼',
    '新車牌號碼',
    '交易編號',
    '生效日期',
    '有效日期',
    '車輛種類',
    '服務類別(固定/浮動)',
    '付款方式',
    '月租費用',
    '已付費用',
    '付款情況',
    '多付 / (少付)',
    '新客 / 續期 / 到期',
  ],
};

export const OperationReportSummaryCsv = {
  requiredKeys: [
    'carParkCode',
    'vehicleType',
    'parkingPlan',
    'other',
    'paymentMethod',
    'monthlyFee',
    'volume',
    'revenue',
    'balance',
    'orderType',
  ],
  engHeader: [
    'Car Park Code',
    'Vehicle Type',
    'Parking Plan',
    'Other',
    'Payment Method',
    'Monthly Fee',
    'Volume',
    'Revenue',
    'Balance',
    'Order Type',
  ],
  chineseHeader: [
    '場號',
    '車輛種類',
    '服務類別(固定/浮動)',
    '其他',
    '付款方式',
    '月租費用',
    '數量',
    '收入',
    '多付 / (少付)',
    '新客 / 續期 / 到期',
  ],
};
