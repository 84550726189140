import React, { useEffect, useState } from 'react';
import { Row, Col, Label, FormGroup, Input } from 'reactstrap';
import { Spin, DatePicker } from 'antd';
import axios from 'axios';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import Cookies from 'universal-cookie/cjs';
import {
  getCarParks,
  getPaymentMethods,
  capitalize,
  loadCustomersOptions,
  getCarByCustomerPhone,
  getCustomerByPhone,
} from '../../../util/generalHelper';
import { CreateRecordButtons, UpdateRecordButtons } from '../../../components/common/profile';
import { createRecord, updateRecord, deleteRecord } from '../../../util/formHelper';
import {
  bookingTypeOptions,
  orderStatusOptions,
  orderDefaultObj,
  bookingDefaultObj,
} from './constants';
import { cleanBookingDetails } from '../../../util/detailsHelper';

const cookies = new Cookies();

const OrderForm = ({ fetch, update, type, cancel, allowDelete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [Order, setOrder] = useState(orderDefaultObj);
  const [Bookings, setBookings] = useState([]);
  const [GuestBookings, setGuestBookings] = useState([]);

  const [PaymentMethod, setPaymentMethod] = useState({ value: '', label: '' });

  const [carParkOptions, setCarParkOptions] = useState([]);
  const [regMarkOptions, setRegmarkOptions] = useState([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);

  const [carParks, setCarParks] = useState(new Map());

  const fetchDropdownOptions = async () => {
    const [{ carParksInput, carParkOptions }, { allPaymentMethods }] = await Promise.all([
      getCarParks(),
      getPaymentMethods(),
    ]);

    setCarParks(carParksInput);
    setCarParkOptions(carParkOptions);
    setPaymentMethodOptions(allPaymentMethods);
    if (Array.isArray(allPaymentMethods) && allPaymentMethods.length > 0 && !PaymentMethod.value)
      setPaymentMethod(allPaymentMethods[0]);

    setIsLoading(false);
  };

  const fetchExistingRecord = async () => {
    fetchDropdownOptions();

    try {
      const {
        data: { data },
      } = await axios.get(fetch, { headers: { token: cookies.get('token') } });

      const {
        Bookings: BookingsArr,
        GuestBookings: GuestBookingsArr,
        Customer,
        Payment,
        status,
      } = data;
      const { phone } = Customer || {};
      const { PaymentMethod } = Payment || {};
      const { id, name } = PaymentMethod || {};

      setPaymentMethod({ value: id, label: name });

      let allAccountCodesConcant;

      if (BookingsArr.length > 0) {
        const allAccountCodes = BookingsArr.map(({ accountCode }) => accountCode);
        allAccountCodesConcant = allAccountCodes.join(', ');

        const allBookings = await Promise.all(
          BookingsArr.map((item) => cleanBookingDetails(item, 'form'))
        );

        setBookings(allBookings);
      }

      if (GuestBookingsArr.length > 0) {
        const allBookings = await Promise.all(
          GuestBookingsArr.map((item) => cleanBookingDetails(item, 'form'))
        );
        setGuestBookings(allBookings);
      }

      const orderData = {
        customer: { value: phone, label: phone },
        status: { value: status, label: capitalize(status) },
        accountCode: allAccountCodesConcant || 'N/A',
      };
      setOrder(orderData);
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);

    if (type === 'edit') {
      fetchExistingRecord();
    } else {
      fetchDropdownOptions();
      setBookings([bookingDefaultObj]);
    }
  }, []);

  const handleSave = async () => {
    const { customer, status, accountCode } = Order;

    const { value: customerPhone } = customer || {};
    const { value: statusValue } = status || {};

    console.log(Bookings);

    const cleanedBookings = Bookings.map((item) => {
      const { registrationMark, type, carParkCode, parkingPlan, validFrom, validTo } = item;

      const { value: regMarkValue } = registrationMark || {};
      const { value: typeValue } = type || {};
      const { value: CarParkId } = carParkCode || {};
      const { value: ParkingPlanId } = parkingPlan || {};

      return {
        registrationMark: regMarkValue,
        type: typeValue,
        carParkCode: CarParkId,
        parkingPlan: ParkingPlanId,
        validFrom,
        validTo,
      };
    });

    const CustomerId = await getCustomerByPhone(customerPhone);
    const { value: PaymentMethodId } = PaymentMethod || {};

    const body = {
      customer: CustomerId,
      status: statusValue,
      accountCode,
      Bookings: cleanedBookings,
      PaymentMethodId,
    };

    console.log({ cleanedBookings });

    if (
      !body.customer ||
      !body.PaymentMethodId ||
      cleanedBookings.some(
        (booking) =>
          !booking.registrationMark ||
          !booking.type ||
          !booking.carParkCode ||
          !booking.parkingPlan ||
          !booking.validFrom ||
          !booking.validTo
      )
    ) {
      window.alert('Please fill in all required fields.');
      return;
    }

    if (
      cleanedBookings.some(
        (booking) => !moment(booking.validTo).isSame(moment(booking.validTo).endOf('month'), 'day')
      )
    ) {
      window.alert('Booking end date must be the last day of the month.');
      return;
    }

    if (type === 'edit') {
      updateRecord(update, body);
    } else {
      Object.assign(body, { origin: 'cms' });
      createRecord(`${process.env.REACT_APP_API_DOMAIN}/admin/order`, body);
    }
  };

  const handleSelectCustomer = async (selected) => {
    setOrder({ ...Order, customer: selected });

    const { allCars } = await getCarByCustomerPhone(selected.value);
    setRegmarkOptions(allCars.map((car) => ({ value: car, label: car })));
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spin />
        </div>
      ) : (
        <>
          <h1>Order</h1>
          <Row>
            <Col md={6} className="mb-4">
              <Label>
                Customer Phone
                <span style={{ color: 'red' }}>*</span>
              </Label>
              <AsyncSelect
                menuPlacement="auto"
                menuPosition="fixed"
                loadOptions={loadCustomersOptions}
                onChange={handleSelectCustomer}
                value={Order.customer}
              />
            </Col>
            <Col md={6} className="mb-4">
              <Label>Status</Label>
              <Select
                menuPlacement="auto"
                menuPosition="fixed"
                options={orderStatusOptions}
                value={Order.status}
                onChange={(selected) => setOrder({ ...Order, status: selected })}
              />
            </Col>
            <Col md={6} className="mb-4">
              <FormGroup>
                <Label>Account Code</Label>
                <Input
                  type="text"
                  value={Order.accountCode}
                  onChange={(e) => setOrder({ ...Order, accountCode: e.target.value })}
                />
                <span style={{ color: 'gray', fontSize: '9px' }}>
                  Empty for auto-generate by system
                </span>
              </FormGroup>
            </Col>
          </Row>

          {Array.isArray(Bookings) && Bookings.length > 0 && (
            <BookingRecords
              records={Bookings}
              setRecords={setBookings}
              carParkOptions={carParkOptions}
              regMarkOptions={regMarkOptions}
              carParks={carParks}
              title="Booking"
              type={type}
            />
          )}

          {Array.isArray(GuestBookings) && GuestBookings.length > 0 && (
            <BookingRecords
              records={GuestBookings}
              setRecords={setGuestBookings}
              carParkOptions={carParkOptions}
              regMarkOptions={regMarkOptions}
              carParks={carParks}
              title="Guest Booking"
              type={type}
            />
          )}

          <h5>
            Payment
            {type === 'edit' && (
              <span style={{ color: 'gray', fontSize: '9px' }}>
                <br />
                Please edit the payment information in Order &gt; Payment section
              </span>
            )}
          </h5>
          <br />
          <Row>
            <Col md={6} className="mb-4">
              <Label>
                Payment Method
                <span style={{ color: 'red' }}>*</span>
              </Label>
              <Select
                menuPlacement="auto"
                menuPosition="fixed"
                options={paymentMethodOptions}
                value={PaymentMethod}
                onChange={(selected) => setPaymentMethod(selected)}
                isDisabled={type === 'edit'}
              />
            </Col>
          </Row>

          {type === 'edit' ? (
            <UpdateRecordButtons
              handleSave={handleSave}
              handleDelete={() => deleteRecord(update)}
              cancel={cancel}
              allowDelete={allowDelete}
            />
          ) : (
            <CreateRecordButtons handleSave={handleSave} cancel={cancel} />
          )}
        </>
      )}
    </>
  );
};

export default OrderForm;

const BookingRecords = ({
  records,
  setRecords,
  carParkOptions,
  regMarkOptions,
  carParks,
  title,
  type,
}) => {
  const [parkingPlan, setParkingPlan] = useState([]);

  const handleCarParkCode = (selected, index) => {
    const updateBookings = [...records];

    updateBookings[index] = {
      ...updateBookings[index],
      carParkCode: selected,
    };

    setRecords(updateBookings);

    const plans = carParks.get(selected.value) || [];
    setParkingPlan(plans);
  };

  const handleSelect = (selected, name, index) => {
    const updateBookings = [...records];

    updateBookings[index] = {
      ...updateBookings[index],
      [name]: selected,
    };

    setRecords(updateBookings);
  };

  const onChangeDate = (date, dateString, name, index) => {
    const updateBookings = [...records];

    updateBookings[index] = {
      ...updateBookings[index],
      [name]: dateString,
    };

    setRecords(updateBookings);
  };

  return records.map((item, index) => (
    <div key={index}>
      <h5>
        {title} #
{index + 1}
        {type === 'edit' && (
          <span style={{ color: 'gray', fontSize: '9px' }}>
            <br />
            Please edit the booking information in Order &gt; Last Booking section
          </span>
        )}
      </h5>
      <br />
      <Row>
        <Col md={6} className="mb-4">
          <Label>
            Registration Mark
            <span style={{ color: 'red' }}>*</span>
          </Label>
          <Select
            menuPlacement="auto"
            menuPosition="fixed"
            options={regMarkOptions}
            value={records[index].registrationMark}
            onChange={(selected) => handleSelect(selected, 'registrationMark', index)}
            isDisabled={type === 'edit'}
          />
        </Col>
        <Col md={6} className="mb-4">
          <Label>
            Type
            <span style={{ color: 'red' }}>*</span>
          </Label>
          <Select
            menuPlacement="auto"
            menuPosition="fixed"
            options={bookingTypeOptions}
            value={records[index].type}
            onChange={(selected) => handleSelect(selected, 'type', index)}
            isDisabled={type === 'edit'}
          />
        </Col>
        <Col md={6} className="mb-4">
          <Label>
            Car Park Code
            <span style={{ color: 'red' }}>*</span>
          </Label>
          <Select
            menuPlacement="auto"
            menuPosition="fixed"
            options={carParkOptions}
            value={records[index].carParkCode}
            onChange={(selected) => handleCarParkCode(selected, index)}
            isDisabled={type === 'edit'}
          />
        </Col>
        <Col md={6} className="mb-4">
          <Label>
            Parking Plan
            <span style={{ color: 'red' }}>*</span>
          </Label>
          <Select
            menuPlacement="auto"
            menuPosition="fixed"
            options={parkingPlan}
            value={records[index].parkingPlan}
            onChange={(selected) => handleSelect(selected, 'parkingPlan', index)}
            isDisabled={type === 'edit'}
          />
        </Col>
        <Col md={6} className="mb-4">
          <div className="d-flex flex-column">
            <Label>
              Valid From
              <span style={{ color: 'red' }}>*</span>
            </Label>
            <DatePicker
              className="mb-3"
              value={
                records[index].validFrom ? moment(records[index].validFrom, 'YYYY-MM-DD') : null
              }
              onChange={(date, dateString) => onChangeDate(date, dateString, 'validFrom', index)}
              disabled={type === 'edit'}
            />
          </div>
        </Col>
        <Col md={6} className="mb-4">
          <div className="d-flex flex-column">
            <Label>
              Valid To
              <span style={{ color: 'red' }}>*</span>
            </Label>
            <DatePicker
              className="mb-3"
              value={records[index].validTo ? moment(records[index].validTo, 'YYYY-MM-DD') : null}
              onChange={(date, dateString) => onChangeDate(date, dateString, 'validTo', index)}
              disabled={type === 'edit'}
            />
            <span style={{ color: 'gray', fontSize: '9px' }}>
              Must be the last day of each month
            </span>
          </div>
        </Col>
      </Row>
    </div>
  ));
};
