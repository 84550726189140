import { callPostAPI } from './baseAPIService'

class authAPIService {
  static login (username, password, successCallback, failCallback) {
    let url = `${process.env.REACT_APP_API_DOMAIN}/login`
    let data = {
      username,
      password
    }
    callPostAPI(url, data, successCallback, failCallback)
  }

  static register (username, password, successCallback, failCallback) {
    let url = `${process.env.REACT_APP_API_DOMAIN}/users`
    let data = {
      username,
      password
    }
    callPostAPI(url, data, successCallback, failCallback)
  }

  static updatePassword (
    id,
    old_password,
    password,
    successCallback,
    failCallback
  ) {
    let url = `${process.env.REACT_APP_API_DOMAIN}/customers/${id}`
    let data = {
      old_password,
      password
    }
    callPostAPI(url, data, successCallback, failCallback)
  }
}

export default authAPIService
