import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Label, Input, Row } from 'reactstrap';
import { Table as AntTable, DatePicker, Radio, Collapse, Checkbox } from 'antd';
import 'antd/dist/antd.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import axios from 'axios';
import moment from 'moment';
// import JsPDF from 'jspdf';
// import 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie/cjs';
import AsyncSelect from 'react-select/async';
import { generateTableColumns, cleanDefaultTableData } from '../../../util/tableHelper';
import { capitalize } from '../../../util/generalHelper';

const cookies = new Cookies();

const WaitingListTable = ({
  getAllRecords,
  details,
  edit,
  customSchema = [],
  groupedHeadColumns = undefined,
  customCleanData = undefined,
  customColumn = undefined, // column that the returned table schema does NOT include
  reworkedColumn = undefined, // column that the returned table schema includes, with ADD-ON needed
  filterBy = [],
  // pdfDocTitle = undefined,
  // customPdfHead = undefined,

  customCsvColumns = undefined,
  csvFileName,
  collapseFilter,
  defaultSorting = {},
  canExport,
}) => {
  const csvButtonRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const { page: pageFromQuery = 1, ...queryFilters } = queryString.parse(location.search);

  const { Panel } = Collapse;

  const animatedComponents = makeAnimated();

  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [schema, setSchema] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 50,
    current: parseInt(pageFromQuery),
  });
  const [filter, setFilter] = useState({ ...queryFilters });
  const [sortBy, setSortBy] = useState({
    sortField: '',
    sortOrder: '',
  });

  // const [doc, setDoc] = useState();

  const [csvData, setCsvData] = useState([]);

  const [recordCount, setRecordCount] = useState(0);

  const wrapExcelString = (str) => {
    if (str === false) {
      return `=""${str}""`;
    }

    if (str === null || (str && str.length === 0)) {
      return `=""N/A""`;
    }
    return `=""${str}""`;
  };

  const fetch = async (params = {}, isSetState = true) => {
    try {
      setIsLoading(true);

      const updatedFilter = Object.entries(filter).reduce((output, [key, value]) => {
        if (value !== '' && !Array.isArray(value)) {
          output[key] = value;
        }
        if (Array.isArray(value) && value.length > 0) {
          if (value.filter((e) => e).length === 0) return output;
          output[key] = value.filter((e) => e).toString();
        }
        if (value.hasOwnProperty('value') && value.hasOwnProperty('label')) {
          output[key] = value.value;
        }

        return output;
      }, {});

      const {
        pagination,
        sortField = defaultSorting.sortField || 'id',
        sortOrder = defaultSorting.sortOrder || 'descend',
      } = params;

      const updatedParams = {
        ...updatedFilter,
        ...pagination,
        sortField,
        sortOrder,
      };

      // Convert Antd Table "pagination.current" to API query param "page"
      updatedParams.page = updatedParams.current;
      delete updatedParams.current;

      const response = await axios.get(getAllRecords, {
        params: updatedParams,
        headers: { token: cookies.get('token') },
      });

      let cleaned;

      if (customCleanData === undefined) {
        cleaned = cleanDefaultTableData(response);
        setSchema(cleaned.cleanedSchema);
      } else if (customCleanData && reworkedColumn) {
        cleaned = await customCleanData(response, customColumn, reworkedColumn);
        customSchema && setSchema(customSchema);
      } else {
        cleaned = await customCleanData(response, customColumn);
        customSchema && setSchema(customSchema);
      }

      setIsLoading(false);

      if (!isSetState) {
        return cleaned.newDataSource;
      }

      setDataSource(cleaned.newDataSource);
      setPagination({
        ...params.pagination,
        total: response.data.count,
        pageSize: 50,
      });
      setRecordCount(response.data.count || 0);
      return cleaned.newDataSource;
    } catch (error) {
      if (error.response && error.response.status) {
        const errorType = error.response.status.toString()[0];
        if (errorType === '5') {
          alert('Filter not available at the moment');
        }
      }
      setDataSource([]);
      setIsLoading(false);
      return [];
    }
  };

  const getCSVData = useCallback(async () => {
    const { columnKey, order } = sortBy || {};

    const data = await fetch(
      {
        sortField: columnKey,
        sortOrder: order,
        pagination: { current: pagination.current },
      },
      false
    );

    let head;
    if (customCsvColumns) {
      head = customCsvColumns;
    } else {
      head = customSchema
        .filter((item) => item !== 'id')
        .map((column) => capitalize(column.replace(/([A-Z])/g, ' $1').trim()));
    }

    const allRecords = data.map(({ id, Booking, ...rest }) =>
      Object.values(rest).map((v) => wrapExcelString(v))
    );

    setCsvData([head, ...allRecords]);

    csvButtonRef.current.link.click();
  }, [sortBy, customSchema, customCsvColumns, pagination.current]);

  const handleTableChange = (pagination, filters, sorter) => {
    const page = filters ? pagination.current : 1;
    const { columnKey, order } = sorter || {};

    let search = `?page=${page}`;
    if (filter) {
      Object.keys(filter).forEach((filterKey) => {
        let value = filter[filterKey];

        if (typeof filter[filterKey] === 'object') value = filter[filterKey].value;
        if (Array.isArray(filter[filterKey])) value = filter[filterKey].toString();
        if (!value) return '';
        search += `&${filterKey}=${value}`;
      });
    }

    history.replace({
      pathname: location.pathname,
      search,
    });

    setSortBy({ page: pagination.current, columnKey, order });

    fetch({
      pagination: { current: page },
      sortField: columnKey,
      sortOrder: order,
    });
  };

  const renderDetailsButton = (id) => (
    <button
      type="button"
      className="btn btn-primary btn-xs mr-3"
      onClick={() => details && details(id)}
    >
      Details
    </button>
  );

  const renderEditButton = (id) => (
    <button type="button" className="btn btn-primary btn-xs mr-3" onClick={() => edit && edit(id)}>
      Edit
    </button>
  );

  const tableSchema = generateTableColumns(
    schema,
    details ? (id) => renderDetailsButton(id) : undefined,
    edit ? (id) => renderEditButton(id) : undefined
  );

  const handleFilter = (e) => {
    e.persist();
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const handleTableFilter = () => {
    handleTableChange(pagination, filter, sortBy);

    // const newPdfDoc = new JsPDF({
    //   orientation: 'l',
    //   unit: 'pt',
    //   format: 'a3'
    // });

    // setDoc(newPdfDoc);
  };

  const onChangeDate = (date, dateString, value) => {
    setFilter({ ...filter, [value]: dateString });
  };

  const handleSelect = (selected, value) => {
    setFilter({ ...filter, [value]: selected });
  };

  useEffect(() => {
    if (isInitialized && Object.keys(filter).length === 0) {
      handleTableChange(pagination);
    }
  }, [filter]);

  useEffect(() => {
    fetch({ pagination: { current: parseInt(pageFromQuery) } });
    setIsInitialized(true);
    // if (pdfDocTitle) {
    //   JsPDF.autoTableSetDefaults({
    //     headStyles: { fillColor: 'fafafa', textColor: '252525' }
    //   });
    // }
  }, []);

  const renderFilterField = ({ title, value, type, options, loadOptions }) => {
    function getDateDefaultValue() {
      const dayFormat = 'YYYY-MM-DD';
      if (filter[value]) {
        return moment(filter[value], dayFormat);
      }
      if (value === 'bookingEndDateFrom') {
        const startDay = moment().startOf('month').format(dayFormat);
        return moment(startDay, dayFormat);
      }
      if (value === 'bookingEndDateTo') {
        const endDay = moment().endOf('month').format(dayFormat);
        return moment(endDay, dayFormat);
      }
      return undefined;
    }

    function getRadioValue() {
      if (filter[value] === false) {
        return false;
      }
      return filter[value] || '';
    }

    function isExclusiveOptionSelected(option) {
      if (!Array.isArray(option.exclusive) || option.exclusive.length === 0) return false;
      const result = option.exclusive.some(({ key: exclusiveKey, value: exclusiveValue }) => {
        const stringResult =
          typeof filter[exclusiveKey] === 'string' &&
          filter[exclusiveKey].split(',').indexOf(exclusiveValue) !== -1;
        const arrayResult =
          Array.isArray(filter[exclusiveKey]) &&
          filter[exclusiveKey].indexOf(exclusiveValue) !== -1;
        return !!filter[exclusiveKey] && (stringResult || arrayResult);
      });
      return result;
    }

    switch (type) {
      case 'checkbox':
        return (
          <Col
            md={6}
            className="mb-4"
            key={value}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Label>{title}</Label>
            <Checkbox.Group
              style={{ marginTop: -10 }}
              onChange={(e) => {
                if (e.findIndex((e) => e === '') > 0) {
                  handleSelect([''], value);
                  return;
                }
                if (e.includes('') && e.length > 1) {
                  handleSelect(
                    e.filter((v) => v),
                    value
                  );
                  return;
                }
                if (e.length === 0) {
                  handleSelect([''], value);
                  return;
                }
                handleSelect(e, value);
              }}
              value={filter[value] || ['']}
            >
              {options &&
                options.length > 0 &&
                options.map(({ value: checkBoxValue, label, exclusive = [] }) => (
                  <Checkbox
                    style={{ marginTop: 10, marginLeft: 0 }}
                    value={checkBoxValue}
                    disabled={isExclusiveOptionSelected({ exclusive, value: checkBoxValue })}
                  >
                    {label}
                  </Checkbox>
                ))}
            </Checkbox.Group>
          </Col>
        );
      case 'radio':
        return (
          <Col
            md={6}
            className="mb-4"
            key={value}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Label>{title}</Label>
            <Radio.Group
              style={{ marginTop: -10 }}
              onChange={(e) => handleSelect(e.target.value, value)}
              value={getRadioValue()}
              defaultValue={options[0].value}
            >
              {options &&
                options.length > 0 &&
                options.map(({ value: v, label, exclusive = [] }) => (
                  <Radio
                    style={{ marginTop: 10 }}
                    value={v}
                    disabled={isExclusiveOptionSelected({ exclusive, value: v })}
                  >
                    {label}
                  </Radio>
                ))}
            </Radio.Group>
          </Col>
        );
      case 'datePicker':
        return (
          <Col md={6} className="mb-4" key={value}>
            <div className="d-flex flex-column">
              <Label>{title}</Label>
              <DatePicker
                value={getDateDefaultValue()}
                className="mb-4"
                onChange={(date, dateString) => onChangeDate(date, dateString, value)}
              />
            </div>
          </Col>
        );
      case 'dropdown':
        return (
          <Col md={6} className="mb-4" key={value}>
            <Label>{title}</Label>
            <Select
              value={filter[value] || null}
              menuPlacement="auto"
              menuPosition="fixed"
              options={options}
              onChange={(selected) => handleSelect(selected, value)}
            />
          </Col>
        );
      case 'asyncDropdown':
        return (
          <Col md={6} className="mb-4" key={value}>
            <Label>{title}</Label>
            <AsyncSelect
              value={filter[value] || null}
              menuPlacement="auto"
              menuPosition="fixed"
              onChange={(selected) => handleSelect(selected, value)}
              defaultOptions
              loadOptions={loadOptions}
            />
          </Col>
        );
      case 'multiSelect':
        return (
          <Col md={6} className="mb-4" key={value}>
            <Label>{title}</Label>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={filter[value] || []}
              isMulti
              options={options}
              onChange={(selected) => handleSelect(selected, value)}
            />
          </Col>
        );
      default:
        return (
          <Col md={6} className="mb-4" key={value}>
            <Label>{title}</Label>
            <Input type="text" name={value} value={filter[value] || ''} onChange={handleFilter} />
          </Col>
        );
    }
  };

  return (
    <>
      {filterBy.length > 0 && (
        <div className="mb-4">
          {collapseFilter ? (
            <Collapse defaultActiveKey={['1']} style={{ marginBottom: 20 }}>
              <Panel header="Search" key="1">
                <Row>{filterBy.map((item) => renderFilterField(item))}</Row>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={handleTableFilter}
                >
                  Search
                </button>
                <button type="button" className="btn btn-link btn-sm" onClick={() => setFilter({})}>
                  Reset
                </button>
              </Panel>
            </Collapse>
          ) : (
            <>
              <p>Filter by:</p>
              <Row>{filterBy.map((item) => renderFilterField(item))}</Row>
              <button type="button" className="btn btn-primary btn-sm" onClick={handleTableFilter}>
                Search
              </button>
              <button type="button" className="btn btn-link btn-sm" onClick={() => setFilter({})}>
                Reset
              </button>
            </>
          )}
        </div>
      )}

      {canExport && csvFileName && Array.isArray(dataSource) && dataSource.length > 0 && (
        <>
          <button
            type="button"
            onClick={getCSVData}
            style={{
              float: 'right',
              color: '#1890ff',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              outline: 'none',
            }}
          >
            Download CSV file
          </button>
          <CSVLink
            ref={csvButtonRef}
            data={csvData}
            filename={csvFileName}
            style={{ float: 'right', display: 'none' }}
          >
            Download CSV file
          </CSVLink>
          <br />
          <br />
        </>
      )}

      <AntTable
        className="w-100"
        columns={groupedHeadColumns || tableSchema}
        rowKey="id"
        dataSource={dataSource}
        pagination={{ ...pagination, position: 'both', showQuickJumper: true }}
        loading={isLoading}
        onChange={handleTableChange}
        scroll={{ x: true }}
        bordered={!!groupedHeadColumns}
      />

      {recordCount > 0 && (
        <p>
          <b>
            Showing&nbsp;
            {((pagination.current || 1) - 1) * (pagination.pageSize || 50) + 1}
            &nbsp; to&nbsp;
            {Math.min((pagination.current || 1) * (pagination.pageSize || 50), recordCount)}
            &nbsp; of&nbsp;
            {recordCount}
            &nbsp; records.
          </b>
        </p>
      )}
    </>
  );
};

WaitingListTable.propTypes = {
  getAllRecords: PropTypes.string,
  details: PropTypes.func,
  edit: PropTypes.func,
  customSchema: PropTypes.arrayOf(PropTypes.string),
  groupedHeadColumns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      dataIndex: PropTypes.string,
      key: PropTypes.string,
      children: PropTypes.array,
    })
  ),
  customCleanData: PropTypes.func,
  customColumn: PropTypes.func, // column that the returned table schema does NOT include
  reworkedColumn: PropTypes.func, // column that the returned table schema includes, with ADD-ON needed
  filterBy: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  customCsvColumns: PropTypes.arrayOf(PropTypes.string),
  csvFileName: PropTypes.string,
  additionalCSVs: PropTypes.arrayOf(
    PropTypes.shape({
      cleanData: PropTypes.func,
      buttonText: PropTypes.string,
      filename: PropTypes.string,
    })
  ),
};

export default WaitingListTable;
