export const waitingListTableColumns = [
  'id',
  'reference',
  'code',
  'customerName',
  'phone',
  'registrationMark',
  'parkingService',
  'status',
  'remarks',
  'createdAt',
  'manualInputDate',
  'Booking',
  'priority',
];

export const waitingListFilter = [
  {
    title: 'Carpark Code',
    value: 'code',
  },
  {
    title: 'Registration Mark',
    value: 'registrationMark',
  },
  {
    title: 'Phone Number',
    value: 'phone',
  },
  {
    title: 'Status',
    value: 'status',
    type: 'dropdown',
    options: [
      { value: 'paid', label: 'Paid' },
      { value: 'confirmed', label: 'Confirmed' },
      { value: 'processed', label: 'Processed' },
      { value: 'expired', label: 'Expired' },
      { value: 'cancelled', label: 'Cancelled' },
    ],
  },
  { title: 'Created From', value: 'createdAtFrom', type: 'datePicker' },
  { title: 'Created To', value: 'createdAtTo', type: 'datePicker' },
];

export const waitingListDefaultObj = {
  id: '',
  reference: '',
  CarParkId: '',
  CustomerId: '',
  CarId: '',
  ParkingPlanId: '',
  status: '',
  remarks: '',
  createdAt: '',
  manualInputDate: '',
};

export const statusOptions = [
  { value: 'paid', label: 'paid', isDisable: true },
  { value: 'confirmed', label: 'confirmed' },
  { value: 'processed', label: 'processed' },
  { value: 'expired', label: 'expired' },
  { value: 'cancelled', label: 'cancelled' },
];

export const WaitingListCsvColumns = [
  'ID',
  'Reference',
  'Code',
  'Customer Name',
  'Phone',
  'Registration Mark',
  'Parking Service',
  'Status',
  'Remarks',
  'BookingId',
  'Priority',
  'Created At',
  'Manual Input Date',
];
