import { getLocations } from '../../../util/generalHelper';

export const carParkFilter = [
  { title: 'Code', value: 'code' },
  { title: 'Name', value: 'name' },
  { title: 'Chinese Name', value: 'chineseName' },
  {
    title: 'Is i-Monthly',
    value: 'isIMonthly',
    type: 'dropdown',
    options: [
      { value: '', label: 'All' },
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
  },
  {
    title: 'Online Show',
    value: 'onlineShow',
    type: 'dropdown',
    options: [
      { value: '', label: 'All' },
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
  },
  { title: 'Location', value: 'locationId', type: 'asyncDropdown', loadOptions: getLocations },
];

export const CarParkTableColumns = [
  'id',
  'facilityNo',
  'code',
  'name',
  'chineseName',
  'isIMonthly',
  'onlineShow',
  'updatedAt',
  'location',
];

export const CarParkSchema = [
  { name: 'id', label: 'Id', type: 'STRING' },
  { name: 'facilityNo', label: 'Facility', type: 'STRING' },
  { name: 'code', label: 'Code', type: 'STRING' },
  { name: 'name', label: 'Name', type: 'STRING' },
  { name: 'chineseName', label: 'Chinese Name', type: 'STRING' },
  { name: 'isIMonthly', label: 'Is i-Monthly', type: 'BOOLEAN' },
  { name: 'onlineShow', label: 'Online Show', type: 'BOOLEAN' },
  { name: 'updatedAt', label: 'Updated At', type: 'DATE' },
  { name: 'location', label: 'Location', type: 'STRING' },
];
