export const PaymentMethodTableColumns = [
  'id',
  'name',
  'enabled',
  'onlineMethod',
];

export const PaymentMethodSchema = [
  { name: 'id', label: 'Id', type: 'STRING' },
  { name: 'name', label: 'Name', type: 'STRING' },
  { name: 'isEnable', label: 'Enable?', type: 'radio' },
  { name: 'isOnlineMethod', label: 'Online Method?', type: 'radio' },
];
