export const ppCardFilter = [
  {
    title: 'Status',
    value: 'status',
    type: 'multiSelect',
    options: [
      { value: 'occupied', label: 'Occupied' },
      { value: 'available', label: 'Available' },
      { value: 'disabled', label: 'Disabled' }
    ]
  }
];

export const ppCardStatus = [
  { value: 'occupied', label: 'Occupied' },
  { value: 'available', label: 'Available' },
  { value: 'disabled', label: 'Disabled' }
];

export const PPCardSchema = [
  { name: 'id', label: 'Id', type: 'string' },
  { name: 'cardNumber', label: 'Card Number', type: 'number' },
  { name: 'status', label: 'Status', type: 'dropdown' }
];
