const LANGUAGE = ['En', 'Zh'];

const OurCompanyFields = {
  1: [
    {
      title: 'Company Description',
      type: 'textArea',
      row: 6,
      key: 'description',
    },
    {
      title: 'Company Image 1',
      type: 'image',
      key: 'image1',
    },
    {
      title: 'Image1 Description',
      type: 'textArea',
      row: 2,
      key: 'image1_description',
    },
    {
      title: 'Company Image 2',
      type: 'image',
      key: 'image2',
    },
    {
      title: 'Image2 Description',
      type: 'textArea',
      row: 2,
      key: 'image2_description',
    },
    {
      title: 'Company Image 3',
      type: 'image',
      key: 'image3',
    },
    {
      title: 'Image3 Description',
      type: 'textArea',
      row: 2,
      key: 'image3_description',
    },
  ],
  2: [
    {
      title: 'Vision Description',
      type: 'textArea',
      row: 3,
      key: 'vision_description',
    },
    {
      title: 'Mission Description',
      type: 'textArea',
      row: 3,
      key: 'mission_description',
    },
    {
      title: 'Values Description',
      type: 'textArea',
      row: 3,
      key: 'values_description',
    },
  ],
  3: [
    {
      title: 'Quality Assurance',
      type: 'html',
      key: 'quality_assurance',
    },
  ],
};

const HomePageFields = [
  {
    name: 'Floating Icon',
    commonFields: [],
    contentFields: [
      { title: 'Floating Icon Image', type: 'image', key: 'floating_icon_url' },
      { title: 'Floating Icon Destination', type: 'input', key: 'destination_url' },
    ],
  },
  {
    name: 'Promotion Section',
    commonFields: [
      {
        title: 'Upload Image',
        type: 'image',
        key: 'promotion_image',
      },
      {
        title: 'Overlay Color',
        type: 'color',
        key: 'promotion_overlay_color',
      },
      {
        title: 'Overlay Opacity',
        type: 'number',
        key: 'promotion_overlay_opacity',
      },
    ],
    contentFields: [
      {
        title: 'Title',
        type: 'input',
        key: 'promotion_title',
      },
      {
        title: 'Content',
        type: 'html',
        key: 'promotion_content',
      },
      {
        title: 'Button Title',
        type: 'input',
        key: 'promotion_button_title',
      },
      {
        title: 'Button Url',
        type: 'input',
        key: 'promotion_button_url',
      },
    ],
  },
  {
    name: 'Booking Section',
    commonFields: [],
    contentFields: [
      {
        title: 'Title',
        type: 'input',
        key: 'booking_title',
      },
      {
        title: 'Tagline',
        type: 'input',
        key: 'booking_tagline',
      },
      {
        title: 'iMonthly Introduction',
        type: 'textArea',
        row: 3,
        key: 'booking_imonthly_introduction',
      },
      {
        title: 'Monthly Url',
        type: 'input',
        key: 'booking_imonthly_url',
      },
      {
        title: 'iHourly Introduction',
        type: 'textArea',
        row: 3,
        key: 'booking_ihourly_introduction',
      },
      {
        title: 'iHourly Url',
        type: 'input',
        key: 'booking_ihourly_url',
      },
    ],
  },
];

const PagesFields = [
  {
    commonFields: [
      // {
      //   title: 'Parent Page',
      //   type: 'select',
      //   key: 'parent_id',
      // },
      {
        title: 'Persistent Url',
        type: 'input',
        key: 'url',
      },
    ],
    contentFields: [
      {
        title: 'Page Title',
        type: 'input',
        key: 'name',
      },
      {
        title: 'Page Content',
        type: 'html',
        key: 'content',
      },
      {
        title: 'Keywords',
        type: 'input',
        key: 'keywords',
      },
      {
        title: 'Description',
        type: 'input',
        key: 'description',
      },
    ],
  },
];

const pagesOptions = [
  { value: 'HomePage', label: 'Home Page', path: '/' },
  {
    value: 'Pages-Wilson Parking Mobile App',
    label: 'Wilson Parking Mobile App',
    path: '/mobile-app',
  },
  {
    value: 'Pages-Wilson Parking Mobile App - problem',
    label: 'Park + Jetso',
    path: '/mobile-app-problem',
  },
  {
    value: 'Pages-Copyright',
    label: 'Copyright',
    path: '/copyright',
  },
  {
    value: 'Pages-Privacy Policy',
    label: 'Privacy Policy',
    path: '/privacy-policy',
  },
  {
    value: 'Pages-Disclaimer',
    label: 'Disclaimer',
    path: '/disclamer',
  },
  {
    value: 'Pages-i-MONTHLY Terms & Conditions',
    label: 'i-Monthly Terms & Conditions',
    path: '/imonthly-t-and-c',
  },
  {
    value: 'Pages-i-HOURLY Parking Service Tutorial & FAQ',
    label: 'i-HOURLY Parking Service Tutorial & FAQ',
    path: '/ihourly-tutorial-and-faq',
  },

  {
    value: 'Pages-i-MONTHLY Parking Service Tutorial & FAQ',
    label: 'i-MONTHLY Parking Service Tutorial & FAQ',
    path: '/imonthly-tutorial-and-faq',
  },

  {
    value: 'Pages-i-HOURLY Terms & Conditions',
    label: 'i-HOURLY Terms & Conditions',
    path: '/ihourly-t-and-c',
  },
  {
    value: 'Pages-Payment Terms & Conditions',
    label: 'Payment Terms & Conditions',
    path: '/booking-t-and-c',
  },
];

export { LANGUAGE, OurCompanyFields, HomePageFields, pagesOptions, PagesFields };
