import React from 'react';
import DefaultForm from '../../components/common/profile/Form';
import SimplifiedForm from '../../components/common/profile/SimplifiedForm';
import { OrderForm, UploadFile } from '../Custom/Form';
import {
  BookingSchema,
  CarSchema,
  CustomerSchema,
  ParkingPlanSchema,
  PaymentSchema,
  PPCardSchema,
  TransactionSchema,
  SkiDataMonthlyParkerEditRemark,
} from '../Custom/constants';
import {
  cleanBookingDetails,
  cleanCarDetails,
  cleanCustomerDetails,
  cleanParkingPlanDetails,
  cleanPaymentDetails,
  cleanPPCardDetails,
  cleanTransactionDetails,
  cleanBannerData,
} from '../../util/detailsHelper';
import {
  bookingSelectOptions,
  bookingSubmitData,
  carSelectOptions,
  carSubmitData,
  customerSelectOptions,
  customerSubmitData,
  parkingPlanSelectOptions,
  parkingPlanSubmitData,
  paymentSelectOptions,
  paymentSubmitData,
  ppCardSelectOptions,
  ppCardSubmitData,
  transactionSelectOptions,
  transactionSubmitData,
} from '../../util/formHelper';
import AdminForm from '../Custom/Admin/Form';
import AdminRoleForm from '../Custom/AdminRole/Form';
import BannerForm from '../Custom/Banner/Form';
import WaitingListForm from '../Custom/WaitingList/Form';

const { REACT_APP_API_DOMAIN } = process.env;

function NewRecord({ module, id, type, cancel, getForm, updateRecord, createRecord, allowDelete }) {
  switch (module) {
    case '/waitingList':
      return (
        <WaitingListForm
          fetch={getForm}
          create={createRecord}
          update={updateRecord}
          id={id}
          type={type}
          cancel={cancel}
          allowDelete={allowDelete}
        />
      );

    case '/news':
      return (
        <DefaultForm
          fetch={getForm}
          create={createRecord}
          update={updateRecord}
          id={id}
          type={type}
          cancel={cancel}
          allowDelete={allowDelete}
          customSelectOptions={{ type: [{ value: 'news', label: 'news' }] }}
          requiredDateFields={['startDate', 'expiryDate']}
        />
      );
    case '/announcement':
      const announcementApi = `${REACT_APP_API_DOMAIN}/news/${id}`;
      return (
        <DefaultForm
          fetch={type === 'edit' ? announcementApi : `${REACT_APP_API_DOMAIN}/schema/News`}
          create={`${REACT_APP_API_DOMAIN}/news`}
          update={announcementApi}
          id={id}
          type={type}
          cancel={cancel}
          allowDelete={allowDelete}
          customSelectOptions={{ type: [{ value: 'announcement', label: 'announcement' }] }}
          requiredDateFields={['startDate', 'expiryDate']}
          hiddenField={['chineseAbstract', 'abstract']}
        />
      );

    case '/ppcard':
      const ppcardApi = `${REACT_APP_API_DOMAIN}/PPCard/${id}`;
      return (
        <SimplifiedForm
          fetch={ppcardApi}
          create={ppcardApi}
          update={ppcardApi}
          type={type}
          cancel={cancel}
          schema={PPCardSchema}
          getSelectOptions={ppCardSelectOptions}
          cleanFormData={cleanPPCardDetails}
          cleanSubmitData={ppCardSubmitData}
          allowDelete={allowDelete}
        />
      );
    case '/carpark':
      return (
        <DefaultForm
          fetch={getForm}
          create={createRecord}
          update={updateRecord}
          id={id}
          type={type}
          cancel={cancel}
          relationDataTypeHide={[
            'PaymentMethods',
            'ParkingPlans',
            'ParkingServices',
            'Bookings',
            'GuestBookings',
            'Location',
            'POSCarParks',
            'WaitingLists',
          ]}
          customExtraField={['Location']}
          requiredFields={['Location', 'code', 'name']}
          allowDelete={allowDelete}
        />
      );
    case '/skidatamonthlyparker':
      return (
        <DefaultForm
          fetch={getForm}
          create={createRecord}
          update={updateRecord}
          id={id}
          type={type}
          cancel={cancel}
          allowDelete={allowDelete}
          RemarkComponent={SkiDataMonthlyParkerEditRemark}
          relationDataTypeHide={['CarPark', 'Customer', 'Car']}
          remarkFields={['CardValidUntil', 'UserSurname', 'UserDepartment', 'UserEmail']}
        />
      );
    case '/parkingservice':
      return (
        <DefaultForm
          fetch={getForm}
          create={createRecord}
          update={updateRecord}
          id={id}
          type={type}
          cancel={cancel}
          relationDataTypeHide={['CarParks', 'ParkingPlans']}
          requiredFields={['type']}
          allowDelete={allowDelete}
        />
      );
    case '/parkingplan':
      return (
        <SimplifiedForm
          fetch={getForm}
          create={createRecord}
          update={updateRecord}
          type={type}
          cancel={cancel}
          schema={ParkingPlanSchema}
          getSelectOptions={parkingPlanSelectOptions}
          cleanFormData={cleanParkingPlanDetails}
          cleanSubmitData={parkingPlanSubmitData}
          allowDelete={allowDelete}
        />
      );
    case '/customer':
      return (
        <SimplifiedForm
          fetch={getForm}
          create={createRecord}
          update={updateRecord}
          type={type}
          cancel={cancel}
          schema={CustomerSchema}
          getSelectOptions={customerSelectOptions}
          cleanFormData={cleanCustomerDetails}
          cleanSubmitData={customerSubmitData}
          allowDelete={allowDelete}
          requiredFields={CustomerSchema.filter(({ required }) => !!required).map(
            ({ name }) => name
          )}
          remarks={CustomerSchema.reduce((result, { name, remarks }) => {
            if (remarks) Object.assign(result, { [name]: remarks });
            return result;
          }, {})}
        />
      );
    case '/car':
      return (
        <SimplifiedForm
          fetch={getForm}
          create={createRecord}
          update={updateRecord}
          type={type}
          cancel={cancel}
          schema={CarSchema}
          getSelectOptions={carSelectOptions}
          cleanFormData={cleanCarDetails}
          cleanSubmitData={carSubmitData}
          allowDelete={allowDelete}
          requiredFields={CarSchema.filter(({ required }) => !!required).map(({ name }) => name)}
        />
      );
    case '/order':
      return (
        <OrderForm
          fetch={getForm}
          create={createRecord}
          update={updateRecord}
          id={id}
          type={type}
          cancel={cancel}
          allowDelete={allowDelete}
        />
      );
    case '/booking':
      return (
        <SimplifiedForm
          fetch={getForm}
          create={createRecord}
          update={updateRecord}
          type={type}
          cancel={cancel}
          schema={BookingSchema}
          getSelectOptions={bookingSelectOptions}
          cleanFormData={cleanBookingDetails}
          cleanSubmitData={bookingSubmitData}
          getCarParks
          allowDelete={allowDelete}
          addOrigin
        />
      );
    case '/payment':
      return (
        <SimplifiedForm
          fetch={getForm}
          create={createRecord}
          update={updateRecord}
          type={type}
          cancel={cancel}
          schema={PaymentSchema}
          getSelectOptions={paymentSelectOptions}
          cleanFormData={cleanPaymentDetails}
          cleanSubmitData={paymentSubmitData}
          allowDelete={allowDelete}
        />
      );
    case '/transaction':
      return (
        <SimplifiedForm
          fetch={getForm}
          create={createRecord}
          update={updateRecord}
          type={type}
          cancel={cancel}
          schema={TransactionSchema.filter(
            (field) => !['registrationMark', 'carParkCode'].includes(field.name)
          )}
          getSelectOptions={transactionSelectOptions}
          cleanFormData={cleanTransactionDetails}
          cleanSubmitData={transactionSubmitData}
          requiredFields={['billingAccount', 'amount', 'paymentMethod']}
          remarks={{
            billingAccount: 'Must be the same as Order > Details > Account Code',
          }}
          allowDelete={allowDelete}
        />
      );
    case '/seveneleven':
      return (
        <UploadFile
          type={type}
          back={cancel}
          name="sevenEleven"
          fileType="txt"
          getDataURL={`${REACT_APP_API_DOMAIN}/seven-eleven/${id}`}
          uploadFileURL={`${REACT_APP_API_DOMAIN}/seven-eleven/upload`}
        />
      );
    case '/cqm':
      return (
        <UploadFile
          type={type}
          back={cancel}
          name="cqm"
          fileType="csv"
          getDataURL={`${REACT_APP_API_DOMAIN}/CQM/${id}`}
          uploadFileURL={`${REACT_APP_API_DOMAIN}/CQM/upload`}
        />
      );
    case '/admin':
      return (
        <AdminForm
          fetch={getForm}
          create={createRecord}
          update={updateRecord}
          id={id}
          type={type}
          cancel={cancel}
          allowDelete={allowDelete}
        />
      );
    case '/adminrole':
      return (
        <AdminRoleForm
          fetch={getForm}
          create={createRecord}
          update={updateRecord}
          id={id}
          type={type}
          cancel={cancel}
        />
      );
    case '/paymentmethod':
      return (
        <DefaultForm
          fetch={getForm}
          create={createRecord}
          update={updateRecord}
          id={id}
          type={type}
          cancel={cancel}
          relationDataTypeHide={['CarParks', 'Payments', 'Transactions']}
          allowDelete={allowDelete}
        />
      );

    case '/award':
      return (
        <DefaultForm
          fetch={getForm}
          create={createRecord}
          update={updateRecord}
          id={id}
          type={type}
          cancel={cancel}
          allowDelete={allowDelete}
          requiredFields={[
            'title',
            'chineseTitle',
            'subTitle',
            'chineseSubTitle',
            'grantedBy',
            'chineseGrantedBy',
            'image',
          ]}
        />
      );

    case '/banner':
      return (
        <BannerForm
          fetch={getForm}
          create={createRecord}
          update={updateRecord}
          id={id}
          type={type}
          cancel={cancel}
          allowDelete={allowDelete}
          requiredFields={['image', 'chineseImage', 'priority']}
          cleanData={cleanBannerData}
        />
      );

    default:
      return (
        <DefaultForm
          fetch={getForm}
          create={createRecord}
          update={updateRecord}
          id={id}
          type={type}
          cancel={cancel}
          allowDelete={allowDelete}
        />
      );
  }
}

export default NewRecord;
