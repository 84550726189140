export const transactionFilter = [
  {
    title: 'Status',
    value: 'status',
    type: 'dropdown',
    options: [
      { value: 'success', label: 'Successful' },
      { value: 'unmatched', label: 'Unmatched' },
      { value: 'failed', label: 'Failed' },
      { value: 'pending', label: 'Pending' },
    ],
  },
];

export const TransactionTableColumns = [
  'id',
  'reference',
  'receipt',
  'amount',
  'transactionTime',
  'billingAccount',
  'status',
  'createdAt',
  'updatedAt',
  'paymentMethod',
  'registrationMark',
  'carParkCode',
];

export const TransactionSchema = [
  { name: 'id', label: 'Id', type: 'string' },
  { name: 'reference', label: 'Reference', type: 'string' },
  { name: 'receipt', label: 'Receipt', type: 'string' },
  { name: 'amount', label: 'Amount', type: 'string' },
  { name: 'billingAccount', label: 'Billing Account', type: 'string' },
  { name: 'status', label: 'Status', type: 'dropdown' },
  { name: 'registrationMark', label: 'Registration Mark', type: 'string' },
  { name: 'carParkCode', label: 'Car Park Code', type: 'dropdown' },
  { name: 'paymentMethod', label: 'Payment Method', type: 'dropdown' },
  { name: 'remarks', label: 'Remarks', type: 'string' },
];

export const statusOptions = [
  { value: 'success', label: 'Successful' },
  { value: 'unmatched', label: 'Unmatched' },
];
