import React, { useEffect, useMemo, useState } from 'react';
import Cookies from 'universal-cookie/cjs';
import axios from 'axios';
import { Spin, Modal, DatePicker } from 'antd';
import { Col, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import { CreateRecordButtons, UpdateRecordButtons } from '../../../components/common/profile';
import { deleteRecord, updateRecord, createRecord } from '../../../util/formHelper';
import { waitingListDefaultObj, statusOptions } from './constants';

const cookies = new Cookies();
const { REACT_APP_API_DOMAIN } = process.env;

const WaitingForm = ({ fetch, update, type, cancel, allowDelete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [waitingList, setWaitingList] = useState(waitingListDefaultObj);
  const [showModal, setShowModal] = useState(false);

  const [carParks, setCarParks] = useState([]);
  const [parkingPlans, setParkingPlans] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [cars, setCars] = useState([]);

  const currentParkingPlan = useMemo(
    () => parkingPlans.find((p) => p.value === waitingList.ParkingPlanId) || {},
    [parkingPlans, waitingList.ParkingPlanId]
  );

  async function fetchCars(customerId) {
    try {
      const {
        data: { data },
      } = await axios.get(`${REACT_APP_API_DOMAIN}/car?customerId=${customerId}`, {
        headers: { token: cookies.get('token') },
      });

      const options = data.map((e) => ({
        value: e.id,
        label: e.registrationMark,
        CarTypeId: e.CarTypeId,
      }));

      setCars(options);
      return Promise.resolve(options);
    } catch (error) {
      setWaitingList({ ...waitingList, CarId: null });
      setCars([]);
      return Promise.reject(error);
    }
  }

  async function fetchCustomers() {
    try {
      const {
        data: { data },
      } = await axios.get(`${REACT_APP_API_DOMAIN}/customer`, {
        headers: { token: cookies.get('token') },
      });

      const options = data.map((e) => ({ value: e.id, label: `${e.firstName} ${e.lastName}` }));

      setCustomers(options);
      return Promise.resolve(options);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function fetchCarParks() {
    try {
      const {
        data: { data },
      } = await axios.get(`${REACT_APP_API_DOMAIN}/car-park`, {
        headers: { token: cookies.get('token') },
      });

      const carparkData = data.map((e) => ({ value: e.id, label: `(${e.code}) ${e.name}` }));

      setCarParks(carparkData);
      return Promise.resolve(carparkData);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function fetchParkingPlans(carparkId) {
    try {
      const {
        data: { data },
      } = await axios.get(`${REACT_APP_API_DOMAIN}/parkingplan?CarParkId=${carparkId}`, {
        headers: { token: cookies.get('token') },
      });

      const options = data
        .filter((e) => e.isFull && e.isWaitingList)
        .map(({ id, subTitle, CarTypeId, CarType = {} }) => ({
          value: id,
          label: subTitle ? `${CarType.name} | ${subTitle}` : 'N/A',
          CarTypeId,
        }));

      setParkingPlans(options);
      return Promise.resolve(options);
    } catch (error) {
      setParkingPlans([]);
      return Promise.reject(error);
    }
  }

  async function fetchWaitingList() {
    try {
      const {
        data: { data },
      } = await axios.get(fetch, { headers: { token: cookies.get('token') } });
      setWaitingList(data);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  const fetchData = async () => {
    try {
      setIsLoading(true);

      if (type === 'edit') {
        const {
          CarPark = {},
          Car = {},
          Customer = {},
          ParkingPlan = {},
        } = await fetchWaitingList();

        await fetchCars(Customer.id);

        setParkingPlans([
          {
            value: ParkingPlan.id,
            label: ParkingPlan.subTitle || 'N/A',
            CarTypeId: ParkingPlan.CarTypeId,
          },
        ]);
        setCustomers([{ value: Customer.id, label: `${Customer.firstName} ${Customer.lastName}` }]);
        setCarParks([{ value: CarPark.id, label: `(${CarPark.code}) ${CarPark.name}` }]);
        setIsLoading(false);
        return;
      }

      await fetchCarParks();
      await fetchCustomers();
      setWaitingList(waitingListDefaultObj);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSave = async () => {
    const { CarId, ParkingPlanId, status, remarks, CustomerId, priority, manualInputDate } =
      waitingList || {};

    const body = {
      CarId,
      ParkingPlanId,
      status,
      remarks: remarks || undefined,
      CustomerId,
      priority: priority || undefined,
      manualInputDate: manualInputDate || undefined,
    };

    if (type === 'edit') {
      updateRecord(update, body);
    } else {
      createRecord(`${process.env.REACT_APP_API_DOMAIN}/WaitingList`, body);
    }
  };

  function renderConfirmDeleteModal(e) {
    return (
      <Modal
        title="Delete record?"
        visible={showModal}
        onOk={() => deleteRecord(e)}
        okText="Confirm"
        onCancel={() => setShowModal(false)}
        centered
      >
        Please click "Confirm" to delete the record.
      </Modal>
    );
  }

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spin />
        </div>
      ) : (
        <>
          <Row>
            {type === 'edit' && (
              <Col md={6} className="mb-4">
                <Label>id</Label>
                <Input disabled type="text" value={waitingList.id} name="username" />
              </Col>
            )}

            <Col md={6} className="mb-4">
              <Label>Status</Label>
              <Select
                menuPlacement="auto"
                menuPosition="fixed"
                options={statusOptions}
                value={statusOptions.find((e) => e.value === waitingList.status)}
                onChange={(selected) =>
                  setWaitingList({
                    ...waitingList,
                    status: selected.value,
                  })
                }
                isOptionDisabled={(e) => e.isDisable}
              />
            </Col>

            {type === 'edit' && (
              <Col md={6} className="mb-4">
                <Label>reference</Label>
                <Input disabled type="text" value={waitingList.reference} name="reference" />
              </Col>
            )}

            <Col md={6} className="mb-4">
              <Label>Priority</Label>
              <Input
                type="number"
                value={waitingList.priority}
                onChange={(e) => {
                  setWaitingList({
                    ...waitingList,
                    priority: e.target.value ? Number(e.target.value) : '',
                  });
                }}
                name="priority"
              />
            </Col>
          </Row>

          <Row>
            <Col md={6} className="mb-4">
              <Label>CarPark</Label>
              <Select
                menuPlacement="auto"
                menuPosition="fixed"
                options={carParks}
                value={carParks.find((e) => e.value === waitingList.CarParkId)}
                onChange={(selected) => {
                  setWaitingList({
                    ...waitingList,
                    CarParkId: selected.value,
                    ParkingPlanId: null,
                    CarId: null,
                  });
                  fetchParkingPlans(selected.value);
                }}
                isDisabled={type === 'edit'}
              />
            </Col>

            <Col md={6} className="mb-4">
              <Label>Parking Plan</Label>
              <Select
                menuPlacement="auto"
                menuPosition="fixed"
                options={parkingPlans}
                value={parkingPlans.find((e) => e.value === waitingList.ParkingPlanId) || null}
                onChange={(selected) =>
                  setWaitingList({
                    ...waitingList,
                    ParkingPlanId: selected.value,
                    CarId: null,
                  })
                }
                isDisabled={type === 'edit' || !waitingList.CarParkId}
              />
            </Col>

            <Col md={6} className="mb-4">
              <Label>Customer</Label>
              <Select
                menuPlacement="auto"
                menuPosition="fixed"
                options={customers}
                value={customers.find((e) => e.value === waitingList.CustomerId)}
                onChange={(selected) => {
                  setWaitingList({
                    ...waitingList,
                    CustomerId: selected.value,
                    CarId: null,
                  });
                  fetchCars(selected.value);
                }}
                isDisabled={type === 'edit'}
              />
            </Col>

            <Col md={6} className="mb-4">
              <Label>Cars</Label>
              <Select
                menuPlacement="auto"
                menuPosition="fixed"
                options={cars.filter((e) => e.CarTypeId === currentParkingPlan.CarTypeId)}
                value={cars.find((e) => e.value === waitingList.CarId) || null}
                onChange={(selected) =>
                  setWaitingList({
                    ...waitingList,
                    CarId: selected.value,
                  })
                }
                isDisabled={
                  !waitingList.CustomerId ||
                  !waitingList.ParkingPlanId ||
                  (waitingList.status !== 'confirmed' && waitingList.status !== 'processed')
                }
              />
            </Col>

            {type === 'edit' && (
              <Col md={6} className="mb-4">
                <Label>Created At</Label>
                <Input
                  disabled
                  type="text"
                  value={moment(waitingList.createdAt).format('MM/DD/YYYY')}
                  name="createdAt"
                />
              </Col>
            )}

            <Col md={6} className="mb-4">
              <div className="d-flex flex-column">
                <Label>Manual Input Date</Label>
                <Input
                  type="text"
                  value={waitingList.manualInputDate}
                  onChange={(e) => {
                    setWaitingList({
                      ...waitingList,
                      manualInputDate: e.target.value,
                    });
                  }}
                  name="manualInputDate"
                />
                {/* <DatePicker
                  value={waitingList.manualInputDate ? moment(waitingList.manualInputDate) : null}
                  className="mb-3"
                  onChange={(date) => {
                    setWaitingList({
                      ...waitingList,
                      manualInputDate: date,
                    });
                  }}
                  format="YYYY-MM-DD HH:mm"
                /> */}
              </div>
            </Col>

            <Col md={6} className="mb-4">
              <Label>Remarks</Label>
              <Input
                type="textarea"
                value={waitingList.remarks}
                onChange={(e) => {
                  setWaitingList({
                    ...waitingList,
                    remarks: e.target.value,
                  });
                }}
                name="remarks"
                style={{ height: '100px' }}
              />
            </Col>
          </Row>

          {type === 'edit' ? (
            <>
              {renderConfirmDeleteModal(update)}
              <UpdateRecordButtons
                handleSave={handleSave}
                handleDelete={() => setShowModal(true)}
                cancel={cancel}
                allowDelete={allowDelete}
              />
            </>
          ) : (
            <CreateRecordButtons handleSave={handleSave} cancel={cancel} />
          )}
        </>
      )}
    </>
  );
};

export default WaitingForm;
