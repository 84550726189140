import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie/cjs';

const cookies = new Cookies();

class UserMenu extends Component {
  logOut(e) {
    e.preventDefault();

    const token = cookies.get('token');
    axios
      .post(`${process.env.REACT_APP_API_DOMAIN}/logout`, null, {
        headers: { token },
      })
      .then((response) => {
        console.log(response);
        cookies.remove('username');
        cookies.remove('token');
        window.location = '/';
      })
      .catch((error) => {
        alert('Something went wrong. Unable to log out');
        console.log(error.response);
      });
  }

  render() {
    return (
      <li className="onhover-dropdown">
        <div className="media  align-items-center">
          <img
            className="align-self-center pull-right mr-2"
            src={require('../../../assets/images/dashboard/user.png')}
            alt="header-user"
          />
          <div className="media-body">
            <h6 className="m-0 txt-dark f-16">
              {cookies.get('username') || ''}
              <i className="fa fa-angle-down pull-right ml-2" />
            </h6>
          </div>
        </div>
        <ul className="profile-dropdown onhover-show-div p-20">
          {/* <li> */}
          {/* <a href="#"> */}
          {/* <i className="icon-user"></i> */}
          {/* Edit Profile */}
          {/* </a> */}
          {/* </li> */}
          {/* <li> */}
          {/* <a href="#"> */}
          {/* <i className="icon-email"></i> */}
          {/* Inbox */}
          {/* </a> */}
          {/* </li> */}
          {/* <li> */}
          {/* <a href="#"> */}
          {/* <i className="icon-check-box"></i> */}
          {/* Task */}
          {/* </a> */}
          {/* </li> */}
          {/* <li> */}
          {/* <a href="#"> */}
          {/* <i className="icon-comments"></i> */}
          {/* Chat */}
          {/* </a> */}
          {/* </li> */}
          <li>
            <a href="#" onClick={this.logOut.bind(this)}>
              <i className="icon-power-off" />
              Logout
            </a>
          </li>
        </ul>
      </li>
    );
  }
}

export default UserMenu;
