import axios from 'axios'

export function getCustomerLocations () {
  return axios
    .get(`${process.env.REACT_APP_API_DOMAIN}/location?isCustomerLocation=true`)
    .then(({ data: { data } }) => {
      const allRegionOptions = Object.values(
        data.reduce((output, { region }) => {
          if (!output[region]) {
            Object.assign(output, { [region]: { value: region, label: region } })
          }
          return output
        }, {})
      )

      return { allRegionOptions }
    })
    .catch(error => console.log(error))
}

export function getDistrictsByRegion (selectedRegion) {
  return axios
    .get(`${process.env.REACT_APP_API_DOMAIN}/location?isCustomerLocation=true`)
    .then(({ data: { data } }) => {
      const allDistrictOptions = Object.values(
        data
          .filter(
            ({ region, isEnable, isCustomerLocation }) =>
              region === selectedRegion && isEnable && isCustomerLocation
          )
          .map(({ id, district }) => ({ value: id, label: district }))
      )

      return { allDistrictOptions }
    })
    .catch(error => console.log(error))
}
