import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import { ScrollContext } from 'react-router-scroll-4';
import './index.scss';
import './App.css';

// ** Import custom components **
import App from './components/app';

// Wilson Parking CMS
import Login from './containers/Login/login';
import Homepage from './containers/General/homepage';
import MyAccount from './containers/General/myAccount';

import Item from './containers/Profile';
import { useRole } from './constants/menu';
import { getPermissions } from './util/generalHelper';

function Root() {
  const { resources, role } = useRole();

  return (
    <BrowserRouter basename="/">
      {/* <ScrollContext> */}
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
        <App>
          <Route path={`${process.env.PUBLIC_URL}/home`} component={Homepage} />
          <Route path={`${process.env.PUBLIC_URL}/myaccount`} component={MyAccount} />

          {resources.map(({ title, path, module }) => {
            let resourceName = title.toLowerCase().replace(/\s/g, '-');

            if (title === 'Homepage and Pages' || title === 'Our Company') {
              resourceName = 'staticInfo';
            }

            const component = React.createElement(Item, {
              moduleName: title,
              api: resourceName,
              permissions: getPermissions(role, module),
            });
            return (
              <Route
                key={title}
                path={`${process.env.PUBLIC_URL}${path}`}
                render={() => component}
              />
            );
          })}
        </App>
      </Switch>
      {/* </ScrollContext> */}
    </BrowserRouter>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));
