import React from 'react';
import DefaultDetails from '../../components/common/profile/Details';
import { OrderDetails } from '../Custom/Details';
import ParkingPlanDetails from '../Custom/ParkingPlan/Details';
import {
  BookingSchema,
  CarSchema,
  CarParkSchema,
  CustomerSchema,
  ParkingPlanSchema,
  ParkingServiceSchema,
  PaymentSchema,
  TransactionSchema,
  ParkingPlanCustomAPISchema,
} from '../Custom/constants';
import {
  cleanCarDetails,
  cleanCarParkDetails,
  cleanCustomerDetails,
  cleanBookingDetails,
  cleanParkingPlanDetails,
  cleanParkingServiceDetails,
  cleanPaymentDetails,
  cleanTransactionDetails,
  cleanAdminDetails,
} from '../../util/detailsHelper';
import { AdminSchema } from '../Custom/Admin/constants';

function Details({ module, back, edit, getRecord, id }) {
  switch (module) {
    case '/ppcard':
      return (
        <DefaultDetails
          fetch={`${process.env.REACT_APP_API_DOMAIN}/PPCard/${id}`}
          edit={edit}
          back={back}
        />
      );
    case '/carpark':
      return (
        <DefaultDetails
          fetch={getRecord}
          edit={edit}
          back={back}
          customSchema={CarParkSchema}
          customCleanData={cleanCarParkDetails}
        />
      );
    case '/parkingservice':
      return (
        <DefaultDetails
          fetch={getRecord}
          edit={edit}
          back={back}
          customSchema={ParkingServiceSchema}
          customCleanData={cleanParkingServiceDetails}
        />
      );
    case '/parkingplan':
      return (
        <ParkingPlanDetails
          fetch={getRecord}
          edit={edit}
          back={back}
          customSchema={ParkingPlanSchema}
          customCleanData={cleanParkingPlanDetails}
          customAPI={{
            url: `${process.env.REACT_APP_API_DOMAIN}/test/test-quota/${id}`,
            method: 'get',
            dataKey: 'data.result',
          }}
          customAPISchema={ParkingPlanCustomAPISchema}
        />
      );
    case '/car':
      return (
        <DefaultDetails
          fetch={getRecord}
          edit={edit}
          back={back}
          customSchema={CarSchema}
          customCleanData={cleanCarDetails}
        />
      );
    case '/customer':
      return (
        <DefaultDetails
          fetch={getRecord}
          edit={edit}
          back={back}
          customSchema={CustomerSchema}
          customCleanData={cleanCustomerDetails}
        />
      );
    case '/order':
      return <OrderDetails fetch={getRecord} edit={edit} back={back} />;
    case '/booking':
      return (
        <DefaultDetails
          fetch={getRecord}
          edit={edit}
          back={back}
          customSchema={BookingSchema}
          customCleanData={cleanBookingDetails}
        />
      );

    case '/lastbooking':
      return (
        <DefaultDetails
          fetch={`${process.env.REACT_APP_API_DOMAIN}/booking/${id}`}
          back={back}
          customSchema={BookingSchema}
          customCleanData={cleanBookingDetails}
        />
      );
    case '/payment':
      return (
        <DefaultDetails
          fetch={getRecord}
          edit={edit}
          back={back}
          customSchema={PaymentSchema}
          customCleanData={cleanPaymentDetails}
        />
      );
    case '/transaction':
      return (
        <DefaultDetails
          fetch={getRecord}
          edit={edit}
          back={back}
          customSchema={TransactionSchema}
          customCleanData={cleanTransactionDetails}
        />
      );
    case '/admin':
      return (
        <DefaultDetails
          fetch={getRecord}
          edit={edit}
          back={back}
          customSchema={AdminSchema}
          customCleanData={cleanAdminDetails}
        />
      );
    default:
      return <DefaultDetails fetch={getRecord} edit={edit} back={back} />;
  }
}
export default Details;
