import React from 'react';
import { useHistory } from 'react-router-dom';
import useImportFromSkiData from './useImportFromSkiData';

const SkiDataMonthlyParkerActionButtons = (data, edit, setIsLoading) => {
  const { id, CarId, CustomerId, SerialNo, UserEmail, UserSurname, UserTitle } = data || {};

  const RenderEditButton = () => (
    <button type="button" className="btn btn-primary btn-xs mb-2" onClick={() => edit && edit(id)}>
      Edit
    </button>
  );

  const RenderImportButton = () => {
    const { handelImportFromSkiData } = useImportFromSkiData({
      SerialNo,
      UserEmail,
      UserSurname,
      UserTitle,
      setIsLoading,
    });
    return (
      <button type="button" className="btn btn-light btn-xs mb-2" onClick={handelImportFromSkiData}>
        Import
      </button>
    );
  };

  const RenderCustomerButton = () => {
    const history = useHistory();
    return (
      <button
        type="button"
        className="btn btn-light btn-xs mb-2"
        onClick={() =>
          history.replace({ pathname: 'customer', search: `?page=1&phone=${UserEmail}` })
        }
      >
        Customer
      </button>
    );
  };

  const RenderBookingButton = () => {
    const history = useHistory();
    return (
      <button
        type="button"
        className="btn btn-light btn-xs mb-2"
        onClick={() =>
          history.replace({
            pathname: 'lastbooking',
            search: `?page=1&phone=${UserEmail}&registrationMark=${UserSurname}&carParkCode=${UserTitle}`,
          })
        }
      >
        Booking
      </button>
    );
  };

  return (
    <div className="d-flex flex-column">
      {!CarId && !CustomerId && <RenderEditButton />}
      {!CarId && !CustomerId && <RenderImportButton />}
      {CustomerId && <RenderCustomerButton />}
      {CarId && <RenderBookingButton />}
    </div>
  );
};

export default SkiDataMonthlyParkerActionButtons;
