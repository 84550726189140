export const CarTableColumns = [
  'id',
  'originalId',
  'registrationMark',
  // 'isActivate',
  'isEnable',
  'createdAt',
  'carType',
  'customerPhone',
];

export const activateCardTypeOptions = [
  { value: 'creditCard', label: 'Visa / Mastercard' },
  { value: 'octupus', label: 'Octopus card' },
];

export const carDefaultDetails = {
  id: '',
  customer: { value: '', label: '' },
  isActivate: false,
  isEnable: false,
  registrationMark: '',
  carType: { value: '', label: '' },
  activateCardType: { value: '', label: '' },
  activateCardNumber: '',
};

export const CarSchema = [
  { name: 'id', label: 'Id', type: 'string' },
  { name: 'customerPhone', label: 'Customer Phone', type: 'asyncDropdown', required: true },
  // { name: 'isActivate', label: 'Is Activate', type: 'radio' },
  { name: 'isEnable', label: 'Is Enable', type: 'radio' },
  { name: 'registrationMark', label: 'Registration Mark', type: 'string', required: true },
  { name: 'carType', label: 'Car Type', type: 'dropdown', required: true },
  // { name: 'activateCardType', label: 'Activate Card Type', type: 'dropdown' },
  // { name: 'activateCardNumber', label: 'Activate Card Number', type: 'string' },
];
