export const adminFilter = [

];

export const AdminTableColumns = [
  'id',
  'username',
  'name',
  'enabled',
  'adminRole',
];

export const AdminSchema = [
  { name: 'id', label: 'Id', type: 'STRING' },
  { name: 'username', label: 'Username', type: 'STRING' },
  { name: 'name', label: 'Name', type: 'STRING' },
  { name: 'email', label: 'Email', type: 'STRING' },
  { name: 'phone', label: 'Phone', type: 'STRING' },
  { name: 'role', label: 'Role', type: 'STRING' },
  { name: 'isEnable', label: 'Enable?', type: 'radio' },
];
