import { Modal, Table as AntTable } from 'antd';
import Select from 'react-select';
import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import './carparkStyle.css';

const renderRemoveButton = (id, PaymentMethodId, removePaymentMethod) => (
  <button
    className="btn btn-primary btn-xs mr-3"
    onClick={() => {
      if (window.confirm(`Are you sure to remove the payment method?`)) {
        removePaymentMethod(id, PaymentMethodId);
      }
    }}
  >
    Remove
  </button>
);

const CarparkPaymentMethods = ({ showModal, setShowModal, carParkId }) => {
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [methods, setMethods] = useState([]);
  const [records, setRecords] = useState([]);

  const methodOptions = useMemo(
    () => (methods || []).map((method) => ({ value: method.id, label: method.name })),
    [methods]
  );

  const fetchCarparkPaymentMethods = async () => {
    try {
      const { data: { data = [] } = {} } = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/carpark/${carParkId}/PaymentMethods`
      );
      setRecords(data);
    } catch (e) {
      console.log(e);
      setRecords([]);
    }
  };

  const fetchPaymentMethods = async () => {
    const { data: { data = [] } = {} } = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/payment-method`
    );
    setMethods(data);
  };

  const addPaymentMethod = async (id, PaymentMethodId) => {
    const { data: { data = [] } = {} } = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/carpark/${id}/PaymentMethods`,
      { PaymentMethodId }
    );
    fetchCarparkPaymentMethods();
  };

  const removePaymentMethod = async (id, PaymentMethodId) => {
    const { data: { data = [] } = {} } = await axios.delete(
      `${process.env.REACT_APP_API_DOMAIN}/carpark/${id}/PaymentMethods`,
      { data: { PaymentMethodId } }
    );
    fetchCarparkPaymentMethods();
  };

  useEffect(() => {
    if (showModal) {
      fetchPaymentMethods();
      fetchCarparkPaymentMethods();
    }
  }, [showModal]);

  return (
    <Modal
      title="Edit Payment Methods"
      visible={showModal}
      onOk={null}
      okButtonProps={{ style: { display: 'none' } }}
      onCancel={() => {
        setShowModal(false);
        window.location.reload();
      }}
      centered
    >
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <div style={{ flex: 1 }}>
          <Select
            menuPlacement="auto"
            menuPosition="fixed"
            options={methodOptions}
            value={methodOptions.find((option) => option.value === selectedMethod)}
            onChange={(selected) => setSelectedMethod(selected.value)}
          />
        </div>

        <button
          className="btn btn-primary btn-xs ml-3"
          onClick={() => addPaymentMethod(carParkId, selectedMethod)}
        >
          Add
        </button>
      </div>

      <br />
      <AntTable
        pagination={false}
        className="w-100 carpark-payment-method-table"
        columns={[
          { title: 'Payment Method', dataIndex: 'name', sorter: true },
          { title: 'Is Enabled?', dataIndex: 'isEnable', sorter: true },
          { title: 'Is Online?', dataIndex: 'isOnlineMethod', sorter: true },
          {
            title: 'Remove',
            dataIndex: 'id',
            key: 'idForRemove',
            render: (PaymentMethodId) => {
              const paymentMethod = records.find((pm) => pm.id === PaymentMethodId);
              if (!paymentMethod || !paymentMethod.CarParkPaymentMethods) return (
                <button
                  className="btn btn-primary btn-xs mr-3"
                  disabled
                >
                  DEFAULT
                </button>
              )
              return renderRemoveButton(carParkId, PaymentMethodId, removePaymentMethod);
            },
          },
        ]}
        rowKey="PaymentMethodId"
        dataSource={records.map((record) => ({
          ...record,
          isEnable: record.isEnable ? 'Y' : 'N',
          isOnlineMethod: record.isOnlineMethod ? 'Y' : 'N',
        }))}
        scroll={{ x: true }}
      />
    </Modal>
  );
};

export default CarparkPaymentMethods;
