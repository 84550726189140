import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import Cookies from 'universal-cookie/cjs';

const cookies = new Cookies();

const { REACT_APP_API_DOMAIN } = process.env;

export function safeGetFromObject(obj, key) {
  return key.split('.').reduce((o, x) => (typeof o === 'undefined' || o === null ? o : o[x]), obj);
}

export function convertDateFormat(dateString) {
  const formattedDate = moment(dateString).format('YYYY-MM-DD');
  return formattedDate;
}

export function convertTimeFormat(dateString) {
  return moment(dateString).format('HH:mm');
}

export function capitalize(string) {
  if (string !== null && string !== undefined) {
    const capitalized = string.charAt(0).toUpperCase() + string.slice(1);
    return capitalized.toString();
  }
}

export function cleanRelationData(schema, data, value, title, browseType) {
  const relationKey = value === 'User' ? value : value.charAt(0).toLowerCase() + value.slice(1);
  const isNotDateType = schema.filter(
    (item) =>
      item.type !== 'DATE' &&
      item.name !== 'id' &&
      item.name !== 'token' &&
      item.name !== 'password'
  );

  const cleanedSchema = isNotDateType.map((data) => {
    const schemaTitle = _.lowerCase(data.name)
      .split(' ')
      .map((word) => _.capitalize(word))
      .join(' ');
    return { title: schemaTitle, value: data.name, type: data.type };
  });

  const dataInArray = Array.isArray(data) ? data : [data];
  const existingSchema = isNotDateType.map(({ name }) => name);

  // if this.props.type === 'add', show empty input fields
  const emptyData = {};
  isNotDateType.map(({ name }) => (emptyData[name] = ''));
  const finalEmptyData = value === 'User' ? emptyData : [emptyData];

  const cleanedData = [];

  (browseType === 'edit' || browseType === 'details') & (data !== undefined) &&
    dataInArray &&
    dataInArray.forEach((data) => {
      cleanedData.push(
        Object.entries(data).reduce((output, [key, value]) => {
          if (existingSchema.includes(key)) {
            if (value !== null || value.constructor === Object) {
              const allValues = Object.values(value).map((item) => item);
              output[key] = [...allValues].join(', ');
            }
            output[key] = value;
          }

          // for displaying name of previously uploaded file in Edit page
          // if (key === "image" || key === "icon") {
          //   output.currentFile = value;
          // }

          return output;
        }, {})
      );
    });

  return {
    relationType: {
      [relationKey]: title,
    },
    relationSchema: {
      [relationKey]: cleanedSchema,
    },
    relationData: {
      [relationKey]: data !== undefined ? cleanedData : finalEmptyData,
    },
  };
}

export function convertLabelFormat(schema) {
  const titles = schema.map((item) => {
    const { name, ...rest } = item;
    let { values } = rest;
    const title = _.lowerCase(name)
      .split(' ')
      .map((word) => _.capitalize(word))
      .join(' ');

    if (values) {
      values = values.map((value) => ({ value, label: value }));
    }

    return { ...rest, title, value: name, values };
  });

  return titles;
}

export function handleData(data) {
  if (data === null || data === undefined || data === '') {
    return 'N/A';
  }
  return data;
}

// car park id and car park code
export function getCarParkOptions() {
  return axios
    .get(`${REACT_APP_API_DOMAIN}/carPark`)
    .then(({ data: { data } }) => {
      const carParkOptions = data.map(({ id, code }) => ({ value: id, label: code }));
      return { carParkOptions };
    })
    .catch((error) => console.log(error));
}

// car park code and car park services
export function getCarParks() {
  return axios
    .get(`${REACT_APP_API_DOMAIN}/booking-options/carPark`)
    .then(({ data: { carPark } }) => {
      const carParksInput = new Map(Object.entries(carPark));
      const carParkOptions = [...carParksInput.keys()].map((key) => ({
        value: key,
        label: key,
      }));

      return { carParksInput, carParkOptions };
    })
    .catch((error) => console.log(error));
}

export function getCarByCustomerPhone(phone) {
  return axios
    .get(`${REACT_APP_API_DOMAIN}/booking-options/customer?phone=${phone}`)
    .then(({ data: { customer } }) => {
      const allCars = customer[phone];
      return { allCars };
    })
    .catch((error) => console.log({ error }));
}

export function loadCustomersOptions(inputValue, callback) {
  return axios
    .get(`${REACT_APP_API_DOMAIN}/booking-options/customer?phone=${inputValue}`)
    .then(({ data: { customer } }) => {
      const customerInput = new Map(Object.entries(customer));

      callback([...customerInput.keys()].map((key) => ({ value: key, label: key })));

      return { customerInput };
    })
    .catch((error) => console.log(error));
}

export function loadRegistrationMarkOptions(inputValue, callback) {
  return axios
    .get(`${REACT_APP_API_DOMAIN}/car?registrationMark=${inputValue}`)
    .then(({ data: { data = [] } = {} }) => {
      const registrationMarks = data.map(({ id, registrationMark }) => ({
        value: id,
        label: registrationMark,
      }));
      callback(registrationMarks);

      return { registrationMarks };
    })
    .catch((error) => console.log(error));
}

export async function getLocations(inputValue) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_API_DOMAIN}/location`)
      .then(({ data: { data = [] } = {} }) => {
        let locations = data.map(({ id, district, region }) => ({
          value: id,
          label: `${district}, ${region}`,
        }));
        if (inputValue)
          locations = locations.filter((option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
          );
        resolve(locations);

        return { locations };
      })
      .catch((error) => reject(error))
  );
}

export function getAllCarTypes() {
  return axios
    .get(`${REACT_APP_API_DOMAIN}/carType`)
    .then(({ data: { data } }) => {
      const carTypes = data.map(({ id, name }) => ({
        value: id,
        label: name,
      }));

      return { carTypes };
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getCustomerById(id) {
  return axios
    .get(`${REACT_APP_API_DOMAIN}/customer/${id}`, {
      headers: { token: cookies.get('token') },
    })
    .then((response) => {
      const {
        data: {
          data: { phone },
        },
      } = response;

      return phone;
    })
    .catch((error) => console.log(error));
}

export function getCustomerByPhone(phone) {
  return axios
    .get(`${REACT_APP_API_DOMAIN}/customer`, {
      params: { phone },
      headers: { token: cookies.get('token') },
    })
    .then(({ data: { data } }) => {
      const majorCustomer = data.find(({ isMajor }) => isMajor);
      const { id: customerId } = majorCustomer || {};

      return customerId;
    })
    .catch((error) => console.log(error));
}

export async function getCustomerLocation(id) {
  if (!id) return;

  try {
    const {
      data: { data },
    } = await axios.get(`${REACT_APP_API_DOMAIN}/customer/${id}/Location`);

    const { id: locationId, region, district } = data;
    return { locationId, region, district };
  } catch (e) {
    console.log({ e });
    return { locationId: null, region: undefined, district: '' };
  }
}

export async function getLocationById(id) {
  if (!id) return;

  try {
    const {
      data: { data },
    } = await axios.get(`${REACT_APP_API_DOMAIN}/location/${id}`);
    const { district, region } = data || {};

    return `${district}, ${region}`;
  } catch (e) {
    console.log({ e });
  }
}

export function getAllParkingService() {
  return axios
    .get(`${REACT_APP_API_DOMAIN}/parking-service`)
    .then((response) => {
      const {
        data: { data },
      } = response;

      const allParkingService = data.map(({ id, type }) => ({ value: id, label: type }));

      return { allParkingService };
    })
    .catch((error) => console.log(error));
}

export function getPaymentMethodById(id) {
  if (!id) return;
  return axios
    .get(`${REACT_APP_API_DOMAIN}/paymentMethod/${id}`)
    .then((response) => {
      const {
        data: {
          data: { name: paymentMethodName },
        },
      } = response;

      return paymentMethodName;
    })
    .catch((error) => console.log(error));
}

export function getPaymentMethods() {
  return axios
    .get(`${REACT_APP_API_DOMAIN}/paymentMethod`)
    .then(({ data: { data } }) => {
      const allPaymentMethods = data.map(({ id, name }) => ({
        value: id,
        label: name,
      }));

      return { allPaymentMethods };
    })
    .catch((error) => console.log(error));
}

export function getPPCards() {
  return axios
    .get(`${REACT_APP_API_DOMAIN}/PPCard?status=available`)
    .then(({ data: { data } }) => {
      console.log({ data });

      const allPPCards = data.map(({ id, cardNumber }) => ({ value: id, label: cardNumber }));

      return { allPPCards };
    })
    .catch((error) => {
      console.log(error);
      return { allPPCards: [] };
    });
}

export function getRegistrationMarks() {
  return axios
    .get(`${REACT_APP_API_DOMAIN}/car`)
    .then(({ data: { data } }) => {
      const allRegistrationMarks = data.map(({ id, registrationMark }) => ({
        value: id,
        label: registrationMark,
      }));

      return { allRegistrationMarks };
    })
    .catch((error) => console.log(error));
}

export function getAdminRoles() {
  return axios
    .get(`${REACT_APP_API_DOMAIN}/admin-role`, { headers: { token: cookies.get('token') } })
    .then(({ data: { data: AdminRoles = [] } = {} }) => AdminRoles)
    .catch((error) => console.log(error));
}

export function getPermissions(role, module) {
  const { isAllGranted, RolePermissions = [] } = role || {};
  if (isAllGranted && (module.includes('Report') || module === 'WaitingList'))
    return ['CREATE', 'READ', 'UPDATE', 'DELETE', 'EXPORT'];
  if (isAllGranted && !module.includes('Report')) return ['CREATE', 'READ', 'UPDATE', 'DELETE'];
  if (Array.isArray(RolePermissions)) {
    return RolePermissions.filter(({ module: moduleName }) => moduleName === module).map(
      ({ action }) => action
    );
  }
  return [];
}

export function checkLPN(input = '', maxCharacter = 8) {
  let checkFormat = /^(?:([A-HJ-NPR-Z0-9\s])(?!\1{4,})){1,8}$/;

  if (maxCharacter === 15) {
    checkFormat = /^(?:([A-HJ-NPR-Z0-9\s])(?!\1{4,})){1,15}$/;
  }

  const checkMulitSpace = /\s{2,}/;

  const result = checkFormat.test(input) && !checkMulitSpace.test(input);

  return result;
}

export function getStringToNestedObject(path, obj) {
  return path.split('.').reduce((o, i) => o?.[i] || '', obj);
}
