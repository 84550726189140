import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import DefaultTable from '../../components/common/profile/Table';
import {
  bookingFilter,
  BookingTableColumns,
  carParkFilter,
  CarParkTableColumns,
  CarTableColumns,
  customerFilter,
  CustomerTableColumns,
  lastBookingFilter,
  OrderTableColumns,
  ParkingPlanTableColumns,
  ParkingServiceTableColumns,
  paymentFilter,
  PaymentTableColumns,
  ppCardFilter,
  SevenElevenTableColumns,
  SkiDataTableColumns,
  transactionFilter,
  TransactionTableColumns,
  SkiDataMonthlyParkerColumns,
  NewsTableColumns,
  AnnouncementTableColumns,
} from '../Custom/constants';
import {
  AccMpColumns,
  BuyNewColumns,
  ChangeLpnColumns,
  OfflineTransactionColumns,
  OnlineTransactionColumns,
  reportFilter,
  SummaryAntTableColumns,
  SummaryCsvColumns,
  operationReportColumns,
  operationReportFilter,
  orderFilter,
  IParkTransactionColumns,
  IParkTransactionFilter,
  IParkCouponFilter,
  IParkCouponColumns,
  skidataFilter,
} from '../Custom/Report';
import StatusAction, { StatusActionModal } from '../Custom/Booking/StatusAction';
import { LastBookingActions } from '../Custom/LastBooking';
import SkiDataMonthlyParkerActions from '../Custom/SkiDataMonthlyParker/ActionButtons';
import { SkiDataActions } from '../Custom/SkiData/ActionButtons';
import {
  cleanOperationTableData,
  cleanAccMpTableData,
  cleanAccountReconciliationReportData,
  cleanAdminTableData,
  cleanBookingTableData,
  cleanBuyNewTableData,
  cleanCarParkTableData,
  cleanCarTableData,
  cleanChangeLpnTableData,
  cleanCustomerTableData,
  cleanLastBookingTableData,
  cleanOrderTableData,
  cleanParkingPlanTableData,
  cleanParkingServiceTableData,
  cleanPaymentMethodTableData,
  cleanPaymentTableData,
  cleanSevenElevenTableData,
  cleanSkiDataTableData,
  cleanTransactionTableData,
  cleanSkiDataMonthlyParkerableData,
  cleanIParkTransactionTableData,
  cleanNewsTableData,
  cleanAnnouncementTableData,
  cleanIParkCouponTableData,
  cleanNotificationTemplateTableData,
  cleanWaitingListTableData,
} from '../../util/tableHelper';
import { parkingPlanFilter } from '../Custom/ParkingPlan/constants';
import { adminFilter, AdminTableColumns } from '../Custom/Admin/constants';
import { PaymentMethodTableColumns } from '../Custom/PaymentMethod/contants';
import { BannerTableColumns } from '../Custom/Banner/contants';
import { parkingServiceFilter } from '../Custom/ParkingService/constants';
import CarparkActions from '../Custom/CarPark/CarparkActions';
import SkiDataMontlyParkerTable from '../Custom/SkiDataMonthlyParker/SkiDataMontlyParkerTable';
import CustomerTable from '../Custom/Customer/CustomerTable';
import FileUploadTable from '../Custom/fileUpload/FileUploadTable';
import StaticInFoTable from '../Custom/StaticInfo/StaticInfoTable';
import { getStaticInfoName } from '../../util/formHelper';
import { NotificationTemplateColumns } from '../Custom/NotificationTemplate/constant';
import {
  waitingListTableColumns,
  waitingListFilter,
  WaitingListCsvColumns,
} from '../Custom/WaitingList/constants';
import WaitingListTable from '../Custom/WaitingList/Table';

const { REACT_APP_API_DOMAIN } = process.env;
const reportURL = (type) => `${REACT_APP_API_DOMAIN}/report/${type}`;

function Table({ module, details, edit, getAllRecords, customPage, canExport }) {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [record, setRecord] = useState({});

  const renderStatusActionModal = () => (
    <StatusActionModal
      showModal={showModal}
      setShowModal={setShowModal}
      record={record}
      setRecord={setRecord}
    />
  );

  switch (module) {
    case '/fileUpload':
      return <FileUploadTable />;

    case '/waitingList':
      return (
        <WaitingListTable
          canExport={canExport}
          csvFileName="WP_Waiting_List_Report.csv"
          getAllRecords={`${process.env.REACT_APP_API_DOMAIN}/WaitingList`}
          details={details}
          edit={edit}
          customSchema={waitingListTableColumns}
          customCleanData={cleanWaitingListTableData}
          filterBy={waitingListFilter}
          customColumn={(accountCode) => (
            <button
              type="button"
              className="btn btn-light btn-xs mt-2 mb-2"
              onClick={() => {
                history.replace({
                  pathname: 'booking',
                  search: `?page=1&accountCode=${accountCode}`,
                });
              }}
            >
              Redirect To Booking
            </button>
          )}
          customCsvColumns={WaitingListCsvColumns}
        />
      );

    case '/news':
      return (
        <DefaultTable
          getAllRecords={getAllRecords}
          details={null}
          edit={edit}
          customCleanData={cleanNewsTableData}
          customSchema={NewsTableColumns}
        />
      );
    case '/announcement':
      return (
        <DefaultTable
          getAllRecords={`${process.env.REACT_APP_API_DOMAIN}/news`}
          details={null}
          edit={edit}
          customCleanData={cleanAnnouncementTableData}
          customSchema={AnnouncementTableColumns}
        />
      );
    case '/ppcard':
      return (
        <DefaultTable
          getAllRecords={`${process.env.REACT_APP_API_DOMAIN}/PPCard`}
          details={details}
          edit={edit}
          filterBy={ppCardFilter}
        />
      );
    case '/carpark':
      return (
        <DefaultTable
          getAllRecords={getAllRecords}
          details={details}
          edit={edit}
          customSchema={[...CarParkTableColumns, 'Payment Link Methods']}
          // customSchema={CarParkTableColumns}
          customCleanData={cleanCarParkTableData}
          customColumn={(id, PaymentMethods) => (
            <CarparkActions
              id={id}
              PaymentMethods={PaymentMethods}
              allowEdit={edit !== undefined}
            />
          )}
          filterBy={carParkFilter}
        />
      );
    case '/parkingservice':
      return (
        <DefaultTable
          getAllRecords={getAllRecords}
          details={details}
          edit={edit}
          customSchema={ParkingServiceTableColumns}
          customCleanData={cleanParkingServiceTableData}
          filterBy={parkingServiceFilter}
        />
      );
    case '/parkingplan':
      return (
        <DefaultTable
          getAllRecords={`${process.env.REACT_APP_API_DOMAIN}/parkingplan?currentBookingCount=true`}
          details={details}
          edit={edit}
          customSchema={ParkingPlanTableColumns}
          customCleanData={cleanParkingPlanTableData}
          filterBy={parkingPlanFilter}
          defaultSorting={{ sortField: 'id', sortOrder: 'ascend' }}
        />
      );
    case '/customer':
      return (
        <CustomerTable
          getAllRecords={getAllRecords}
          details={details}
          edit={edit}
          customSchema={CustomerTableColumns}
          customCleanData={cleanCustomerTableData}
          filterBy={customerFilter}
        />
      );
    case '/car':
      return (
        <DefaultTable
          getAllRecords={getAllRecords}
          details={details}
          edit={edit}
          customSchema={CarTableColumns}
          customCleanData={cleanCarTableData}
          filterBy={bookingFilter}
        />
      );
    case '/skidatamonthlyparker':
      return (
        <SkiDataMontlyParkerTable
          getAllRecords={getAllRecords}
          edit={undefined}
          customColumn={(id, setIsLoading) => SkiDataMonthlyParkerActions(id, edit, setIsLoading)}
          customCleanData={cleanSkiDataMonthlyParkerableData}
          customSchema={SkiDataMonthlyParkerColumns}
        />
      );
    case '/skidata':
      return (
        <DefaultTable
          getAllRecords={getAllRecords}
          details={details}
          customSchema={SkiDataTableColumns}
          customCleanData={cleanSkiDataTableData}
          customColumn={(id) => SkiDataActions(id)}
          filterBy={skidataFilter}
        />
      );
    case '/order':
      return (
        <DefaultTable
          getAllRecords={getAllRecords}
          details={details}
          edit={edit}
          customSchema={OrderTableColumns}
          customCleanData={cleanOrderTableData}
          filterBy={orderFilter}
        />
      );
    case '/booking':
      return (
        <>
          {renderStatusActionModal()}

          <DefaultTable
            getAllRecords={getAllRecords}
            details={details}
            edit={edit}
            customSchema={BookingTableColumns}
            customCleanData={cleanBookingTableData}
            reworkedColumn={(id, status) =>
              StatusAction(id, status, setShowModal, setRecord, edit !== undefined)
            }
            filterBy={bookingFilter}
          />
        </>
      );
    case '/lastbooking':
      return (
        <>
          {renderStatusActionModal()}

          <DefaultTable
            getAllRecords={getAllRecords}
            details={details}
            edit={undefined}
            customSchema={[...BookingTableColumns, 'actions']}
            customCleanData={cleanLastBookingTableData}
            customColumn={(id, status, reference, CarParkId) =>
              LastBookingActions(id, status, reference, customPage, edit !== undefined, CarParkId)
            }
            reworkedColumn={(id, status) =>
              StatusAction(id, status, setShowModal, setRecord, edit !== undefined)
            }
            filterBy={lastBookingFilter}
          />
        </>
      );
    case '/payment':
      return (
        <DefaultTable
          getAllRecords={getAllRecords}
          details={details}
          edit={edit}
          customSchema={PaymentTableColumns}
          customCleanData={cleanPaymentTableData}
          filterBy={paymentFilter}
        />
      );
    case '/transaction':
      return (
        <DefaultTable
          getAllRecords={getAllRecords}
          details={details}
          edit={edit}
          customSchema={TransactionTableColumns}
          customCleanData={cleanTransactionTableData}
          filterBy={transactionFilter}
        />
      );
    case '/seveneleven':
      return (
        <DefaultTable
          getAllRecords={getAllRecords}
          details={details}
          customSchema={SevenElevenTableColumns}
          customCleanData={cleanSevenElevenTableData}
        />
      );
    case '/cqm':
      return <DefaultTable getAllRecords={getAllRecords} />;
    case '/onlinetransaction':
      return (
        <DefaultTable
          getAllRecords={reportURL('onlineTransactionReport')}
          customSchema={OnlineTransactionColumns}
          // customCleanData={cleanOnlineTransactionTableData}
          customCleanData={({ data }) => ({ newDataSource: data.data.reportData || [] })}
          filterBy={reportFilter}
          // pdfDocTitle="Online Transaction"
          csvFileName="WP_Online_Transaction_Report.csv"
          // additionalCSVs={[
          //   {
          //     cleanData: cleanAccountReconciliationReportData,
          //     buttonText: 'Download Accounting Report CSV',
          //     filename: 'ACC_Recon_Online.csv',
          //   },
          // ]}
          defaultSorting={{ sortField: 'id', sortOrder: 'ascend' }}
          canExport={canExport}
        />
      );
    case '/offlinetransaction':
      return (
        <DefaultTable
          getAllRecords={reportURL('offlineTransactionReport')}
          customSchema={OfflineTransactionColumns}
          // customCleanData={cleanOfflineTransactionTableData}
          customCleanData={({ data }) => ({ newDataSource: data.data.reportData || [] })}
          filterBy={reportFilter}
          // pdfDocTitle="Offline Transaction"
          csvFileName="WP_Offline_Transaction_Report.csv"
          // additionalCSVs={[
          //   {
          //     cleanData: cleanAccountReconciliationReportData,
          //     buttonText: 'Download Accounting Report CSV',
          //     filename: 'ACC_Recon_Offline.csv',
          //   },
          // ]}
          defaultSorting={{ sortField: 'id', sortOrder: 'ascend' }}
          canExport={canExport}
        />
      );
    case '/changelpn':
      return (
        <DefaultTable
          getAllRecords={reportURL('changeLPNReport')}
          customSchema={ChangeLpnColumns}
          customCleanData={cleanChangeLpnTableData}
          filterBy={reportFilter}
          // pdfDocTitle="Change LPN"
          csvFileName="WP_Change_LPN_Report.csv"
          canExport={canExport}
        />
      );
    case '/buynew':
      return (
        <DefaultTable
          getAllRecords={reportURL('buyNewReport')}
          customSchema={BuyNewColumns}
          customCleanData={cleanBuyNewTableData}
          filterBy={reportFilter}
          // pdfDocTitle="Buy New"
          csvFileName="WP_Buy_New_Report.csv"
          canExport={canExport}
        />
      );
    case '/accountmonthlyparking':
      return (
        <DefaultTable
          getAllRecords={reportURL('accountMonthlyReport')}
          customSchema={AccMpColumns}
          customCleanData={cleanAccMpTableData}
          filterBy={reportFilter}
          // pdfDocTitle="Account Monthly Parking"
          csvFileName="WP_Account_Monthly_Parking_Report.csv"
          canExport={canExport}
        />
      );
    case '/waitingListReport':
      return (
        <DefaultTable
          getAllRecords={reportURL('waitingListReport')}
          // customSchema={AccMpColumns}
          //  customCleanData={cleanAccMpTableData}
          filterBy={reportFilter}
          csvFileName="WP_Waiting_List_Report.csv"
          canExport={canExport}
        />
      );
    // case '/summary':
    //   return (
    //     <>
    //       <DefaultTable
    //         getAllRecords={reportURL('summaryReport')}
    //         groupedHeadColumns={SummaryAntTableColumns}
    //         // customCleanData={cleanSummaryTableData}
    //         customCleanData={({ data }) => ({ newDataSource: data.data || [] })}
    //         filterBy={reportFilter}
    //         customCsvColumns={SummaryCsvColumns}
    //         csvFileName="WP_Summary_Report.csv"
    //         canExport={canExport}
    //       />
    //       {/* <DefaultTable
    //         getAllRecords={reportURL('summaryReport')}
    //         groupedHeadColumns={SummaryAntTableColumns}
    //         customCleanData={cleanSummaryTableData}
    //         filterBy={reportFilter}
    //       /> */}
    //     </>
    //   );
    case '/operation':
      return (
        <DefaultTable
          collapseFilter
          getAllRecords={reportURL('operationReport')}
          // customCleanData={cleanSummaryTableData}
          customCleanData={cleanOperationTableData}
          filterBy={operationReportFilter}
          // csvFileName="WP_Operation_Report.csv"
          customSchema={operationReportColumns}
          refund={edit}
          reportType={['paymentReport', 'detailedSummaryReport', 'generalSummaryReport']}
          canExport={canExport}
        />
      );
    case '/iParkTransaction':
      return (
        <DefaultTable
          getAllRecords={`${process.env.REACT_APP_API_DOMAIN}/IParkTransaction`}
          customSchema={IParkTransactionColumns}
          customCleanData={cleanIParkTransactionTableData}
          filterBy={IParkTransactionFilter}
          // pdfDocTitle="Account Monthly Parking"
          csvFileName="WP_IPark_Transaction_Report.csv"
          canExport={canExport}
        />
      );
    case '/IParkCoupon':
      return (
        <DefaultTable
          getAllRecords={`${process.env.REACT_APP_API_DOMAIN}/IParkCoupon`}
          customSchema={IParkCouponColumns}
          customCleanData={cleanIParkCouponTableData}
          filterBy={IParkCouponFilter}
          // pdfDocTitle="Account Monthly Parking"
          csvFileName="WP_IPark_Coupon_Issued_Report.csv"
          canExport={canExport}
        />
      );
    case '/admin':
      return (
        <DefaultTable
          getAllRecords={getAllRecords}
          details={details}
          edit={edit}
          customSchema={AdminTableColumns}
          customCleanData={cleanAdminTableData}
          filterBy={adminFilter}
        />
      );
    case '/adminrole':
      return <DefaultTable getAllRecords={getAllRecords} details={details} edit={edit} />;
    case '/paymentmethod':
      return (
        <DefaultTable
          getAllRecords={getAllRecords}
          details={details}
          edit={edit}
          customSchema={PaymentMethodTableColumns}
          customCleanData={cleanPaymentMethodTableData}
        />
      );

    case '/homePageAndPages':
    case '/ourCompany':
      return (
        <StaticInFoTable
          getAllRecords={getAllRecords}
          edit={edit}
          pageName={getStaticInfoName(module)}
        />
      );

    case '/notificationTemplate':
      return (
        <DefaultTable
          getAllRecords={getAllRecords}
          edit={edit}
          customCleanData={cleanNotificationTemplateTableData}
          customSchema={NotificationTemplateColumns}
        />
      );

    default:
      return <DefaultTable getAllRecords={getAllRecords} edit={edit} />;
  }
}
export default Table;
