import React from 'react';

const CreateRecordButtons = ({ handleSave, cancel }) => {
  return (
    <div className="d-flex justify-content-end mt-5">
      <button className="btn btn-light btn-sm  mr-3" onClick={cancel}>
        Cancel
      </button>
      <button className="btn btn-primary btn-sm" onClick={handleSave}>
        Save
      </button>
    </div>
  );
};

export default CreateRecordButtons;
