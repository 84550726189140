import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MENUITEMS, useRole } from '../../../constants/menu';

const Sidebar = () => {
  const { menuItems } = useRole();

  // const [selectedPath, setSelectedPath] = useState('1');
  // const [mainmenu, setMainmenu] = useState([]);
  //
  // const onItemSelection = (arg, e) => {
  //   setSelectedPath(arg.path);
  // };
  //
  const setNavActive = (item) => {
    menuItems.filter((menuItem) => {
      if (menuItem !== item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
          }
          if (submenuItems !== item) submenuItems.active = false;
        });
      }
    });
    item.active = !item.active;

    // setMainmenu(menuItems)
  };

  // componentWillMount () {
  //   this.setState({
  //     mainmenu: MENUITEMS
  //   })
  // }

  // useEffect(() => {
  //   let currentUrl = window.location.pathname
  //   console.log({mainmenu, menuItems})
  //
  //   mainmenu.filter(items => {
  //     if (!items.children) {
  //       if (items.path === currentUrl) setNavActive(items)
  //       return false
  //     }
  //     items.children.filter(subItems => {
  //       if (subItems.path === currentUrl) setNavActive(subItems)
  //       if (!subItems.children) return false
  //       subItems.children.filter(subSubItems => {
  //         if (subSubItems.path === currentUrl) setNavActive(subSubItems)
  //       })
  //     })
  //   })
  // }, [mainmenu, menuItems])

  const renderMenuItems = () =>
    menuItems.map((menuItem, i) => (
      <li className={`${menuItem.active ? 'active' : ''}`} key={i}>
        {menuItem.sidebartitle ? <div className="sidebar-title">{menuItem.sidebartitle}</div> : ''}
        {menuItem.type === 'sub' ? (
          <a
            className="sidebar-header "
            href="javascript:void(0)"
            onClick={() => setNavActive(menuItem)}
          >
            <i className={`icon-${menuItem.icon}`} />
            <span>{menuItem.title}</span>
            <i className="fa fa-angle-right pull-right" />
          </a>
        ) : (
          ''
        )}
        {menuItem.type === 'link' ? (
          <Link
            to={`${process.env.PUBLIC_URL}${menuItem.path}`}
            className={`sidebar-header ${menuItem.active ? 'Active' : ''}`}
            onClick={() => setNavActive(menuItem)}
          >
            <i className={`icon-${menuItem.icon}`} />
            <span>{menuItem.title}</span>
            {menuItem.children ? <i className="fa fa-angle-right pull-right" /> : ''}
          </Link>
        ) : (
          ''
        )}
        {menuItem.children ? (
          <ul
            className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''}`}
            style={menuItem.active ? { opacity: 1, transition: 'opacity 500ms ease-in' } : {}}
          >
            {menuItem.children.map((childrenItem, index) => (
              <li
                key={index}
                className={childrenItem.children ? (childrenItem.active ? 'active' : '') : ''}
              >
                {childrenItem.type === 'sub' ? (
                  <a href="javascript:void(0)" onClick={() => setNavActive(childrenItem)}>
                    <i className="fa fa-angle-right" />
                    {childrenItem.title}
                  </a>
                ) : (
                  ''
                )}

                {childrenItem.type === 'link' ? (
                  <Link
                    to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                    className={childrenItem.active ? 'active' : ''}
                    onClick={() => setNavActive(childrenItem)}
                  >
                    <i className="fa fa-angle-right" />
                    {childrenItem.title}{' '}
                  </Link>
                ) : (
                  ''
                )}
                {childrenItem.children ? (
                  <ul className={`sidebar-submenu ${childrenItem.active ? 'menu-open' : ''}`}>
                    {childrenItem.children.map((childrenSubItem, key) => (
                      <li className={childrenSubItem.active ? 'active' : ''} key={key}>
                        {childrenSubItem.type === 'link' ? (
                          <Link
                            to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                            className={childrenSubItem.active ? 'active' : ''}
                          >
                            <i className="fa fa-angle-right" />
                            {childrenSubItem.title}
                          </Link>
                        ) : (
                          ''
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  ''
                )}
              </li>
            ))}
          </ul>
        ) : (
          ''
        )}
      </li>
    ));

  // const theme = {
  //   selectionColor: '#C51162'
  // }

  return (
    <div className="page-sidebar custom-scrollbar page-sidebar-open">
      {/* <UserPanel /> */}
      <ul className="sidebar-menu">{renderMenuItems()}</ul>
      {/* <div className="sidebar-widget text-center"> */}
      {/* <div className="sidebar-widget-top"> */}
      {/* <h6 className="mb-2 fs-14">Need Help</h6> */}
      {/* <i className="icon-bell"></i> */}
      {/* </div> */}
      {/* <div className="sidebar-widget-bottom p-20 m-20"> */}
      {/* <p>+1 234 567 899 help@pixelstrap.com <a href="#">Visit FAQ</a></p> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default Sidebar;
