import React from 'react';
import { Button } from 'reactstrap';
import RenderRow from '../comoponents/RenderRow';
import { LANGUAGE, PagesFields, pagesOptions } from '../constant';
import { ObjectWrapper, LanguageTitle, HomePageSectionInner, SectionName } from '../styles';

export default function Pages({
  handleUpload,
  handleFileRemove,
  handleOnChange,
  data,
  edit,
  handleUpdate,
}) {
  const FieldsObject = PagesFields.map((e) => ({
    ...e,
    contentFields: LANGUAGE.reduce((acc, input) => {
      const languageTitle = input === 'En' ? 'English' : 'Traditional Chinese';
      return {
        ...acc,
        [languageTitle]: e.contentFields.map((c) => ({
          ...c,
          language: input,
          key: input === 'En' ? c.key : `${c.key}_zh`,
        })),
      };
    }, {}),
  }));

  const keyName = Array.isArray(data) ? data?.[0]?.pageName || '' : '';
  const name = pagesOptions.find((e) => e.value === keyName)?.label || '';

  return (
    <>
      {edit && (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <SectionName>{name}</SectionName>
          <Button size="sm" color="primary" onClick={handleUpdate}>
            Submit
          </Button>
        </div>
      )}

      {FieldsObject.map((section, i) => (
        <ObjectWrapper key={i}>
          <HomePageSectionInner style={{ paddingTop: 20 }} length={section.commonFields.length}>
            {section.commonFields.map((field) => (
              <RenderRow
                key={field.key}
                handleUpload={handleUpload}
                handleFileRemove={handleFileRemove}
                onChange={handleOnChange}
                field={field}
                data={data}
              />
            ))}

            {Object.entries(section.contentFields).map(([languageTitle, contentFields]) => (
              <ObjectWrapper
                key={languageTitle}
                style={{
                  padding: '20px 10px 10px 10px',
                }}
              >
                <LanguageTitle>{languageTitle}</LanguageTitle>

                {contentFields.map((field) => (
                  <RenderRow
                    key={field.key}
                    handleUpload={handleUpload}
                    handleFileRemove={handleFileRemove}
                    onChange={handleOnChange}
                    field={field}
                    data={data}
                  />
                ))}
              </ObjectWrapper>
            ))}
          </HomePageSectionInner>
        </ObjectWrapper>
      ))}
    </>
  );
}
