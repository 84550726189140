import React, { Component } from 'react'
// Import custom components
import Breadcrumb from '../../components/common/breadcrumb.component'
import { Table, Form } from 'reactstrap'
// import Cookies from "universal-cookie/cjs";
// const cookies = new Cookies();

class MyAccount extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true,
      render: 'accountDetail',

      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',

      message: '' // for password success, error
    }
  }

  componentDidMount () {
    // if (!cookies.get('token')) {
    //   window.location = '/'
    // }
    setTimeout(
      function () {
        this.setState({ isLoading: false })
      }.bind(this),
      1000
    )
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
    console.log(this.state[e.target.name])
  };

  isPassword (password) {
    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/
    return regex.test(password)
  }

  validateFormData () {
    if (!this.isPassword(this.state.newPassword)) {
      this.setState({
        message:
          'Password must be at least 8 characters, including at least 1 uppercase letter and at least 1 lowercase letter'
      })
      return false
    }

    if (this.state.newPassword !== this.state.confirmNewPassword) {
      this.setState({ message: 'Passwords must match' })
      return false
    }

    return true
  }

  handleUpdatePassword = e => {
    e.preventDefault()

    if (this.validateFormData() !== true) {
      return
    }
    console.log('done!')

    // update password api below
  };

  _renderUpdatePassword () {
    const inputField = [
      {
        label: 'Current password',
        name: 'currentPassword'
      },
      {
        label: 'New password',
        name: 'newPassword'
      },
      {
        label: 'Confirm new password',
        name: 'confirmNewPassword'
      }
    ]

    return (
      <Form className="theme-form m-t-25" onSubmit={this.handleUpdatePassword}>
        {inputField.map(item => {
          return (
            <div className="form-group" key={item.name}>
              <label className="col-form-label pt-0">{item.label}</label>
              <input
                required
                type="password"
                className="form-control form-control-lg"
                name={item.name}
                style={{ width: '50%' }}
                onChange={this.handleChange}
              />
            </div>
          )
        })}
        <div className="form-group form-row mt-3">
          <div className="col-md-3">
            <button type="submit" className="btn btn-secondary">
              Submit
            </button>
          </div>
        </div>
        {this.state.message !== '' ? <span>{this.state.message}</span> : null}
      </Form>
    )
  }

  _renderAccountDetail () {
    return (
      <div className="table-responsive m-t-25">
        <Table>
          <thead>
            <tr>
              <th scope="row">Information</th>
              <th scope="row">Detail</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Username</th>
              <td>aherhaerhare</td>
            </tr>
            <tr>
              <th scope="row">Email</th>
              <td>aherhaerhare@gmail.com</td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }

  render () {
    return (
      <div>
        {/* Container-fluid starts */}
        <Breadcrumb title="My Account" parent="General" />
        {/* Container-fluid Ends */}

        {/* Container-fluid starts */}
        <div className="container-fluid">
          <div className="row">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={() =>
                          this.setState({ render: 'accountDetail' })
                        }
                      >
                        Account detail
                      </button>
                      <button
                        className="btn btn-secondary m-15"
                        type="button"
                        onClick={() =>
                          this.setState({ render: 'updatePassword' })
                        }
                      >
                        Update password
                      </button>
                      {this.state.render === 'accountDetail'
                        ? this._renderAccountDetail()
                        : this._renderUpdatePassword()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Container-fluid Ends */}
          </div>
        </div>
        {/* Container-fluid Ends */}
      </div>
    )
  }
}

export default MyAccount
