import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie/cjs';

const cookies = new Cookies();

export const RESOURCES = [
  { title: 'Admin', module: 'Admin', path: '/admin' },
  { title: 'Admin Role', module: 'AdminRole', path: '/adminrole' },
  { title: 'PP Card', module: 'PPCard', path: '/ppcard' },
  { title: 'News', module: 'News', path: '/news' },
  { title: 'Announcement', module: 'News', path: '/announcement' },
  { title: 'Upload file', module: 'FileUpload', path: '/fileUpload' },
  { title: 'Homepage and Pages', module: 'StaticInfo', path: '/homePageAndPages' },
  { title: 'Our Company', module: 'StaticInfo', path: '/ourCompany' },
  { title: 'Notification Template', module: 'NotificationTemplate', path: '/notificationTemplate' },

  { title: 'Car Park', module: 'CarPark', path: '/carpark', sidebartitle: 'Car Park' },
  { title: 'Parking Service', module: 'ParkingService', path: '/parkingservice' },
  { title: 'Parking Plan', module: 'ParkingPlan', path: '/parkingplan' },
  { title: 'Waiting List', module: 'WaitingList', path: '/waitingList' },

  { title: 'Customer', module: 'Customer', path: '/customer', sidebartitle: 'Customer' },
  { title: 'Car', module: 'Customer', path: '/car' },
  { title: 'Ski Data Monthly Parker', module: 'SkiData', path: '/skidatamonthlyparker' },
  { title: 'Ski Data', module: 'SkiData', path: '/skidata' },

  { title: 'Order', module: 'Order', path: '/order', sidebartitle: 'Order' },
  { title: 'Booking', module: 'Order', path: '/booking' },
  { title: 'Last Booking', module: 'Order', path: '/lastbooking' },
  { title: 'Payment', module: 'Payment', path: '/payment' },
  { title: 'Transaction', module: 'Payment', path: '/transaction' },

  {
    title: 'Seven Eleven',
    module: 'SevenEleven',
    path: '/seveneleven',
    sidebartitle: 'Transaction',
  },
  { title: 'CQM', module: 'CQM', path: '/cqm' },

  { title: 'Award', module: 'Award', path: '/award', sidebartitle: 'Misc' },
  { title: 'Payment Method', module: 'PaymentMethod', path: '/paymentmethod' },
  { title: 'Banner', module: 'Banner', path: '/banner' },

  {
    title: 'Online Transaction Report',
    module: 'OnlineTransactionReport',
    path: '/onlinetransaction',
    sidebartitle: 'Report',
  },
  {
    title: 'Offline Transaction Report',
    module: 'OfflineTransactionReport',
    path: '/offlinetransaction',
  },
  { title: 'Change LPN Report', module: 'ChangeLpnReport', path: '/changelpn' },
  { title: 'Buy New Report', module: 'BuyNewReport', path: '/buynew' },
  // { title: 'Summary Report', module: 'SummaryReport', path: '/summary' },
  { title: 'Operation Report', module: 'OperationReport', path: '/operation' },
  {
    title: 'IPark Transaction Report',
    module: 'IParkTransactionReport',
    path: '/iParkTransaction',
  },
  {
    title: 'IPark Coupon Issued Report',
    module: 'IParkCouponIssuedReport',
    path: '/IParkCoupon',
  },
  {
    title: 'Waiting List Report',
    module: 'WaitingListReport',
    path: '/waitingListReport',
  },
];

const DEFUALTMENU = [
  {
    title: 'Home',
    type: 'link',
    path: '/home',
    badgeType: 'primary',
    badgeValue: 'new',
    sidebartitle: 'General',
    active: false,
  },
];

export const MENUITEMS = RESOURCES.reduce(
  (output, resource) => {
    output.push({
      title: resource.title,
      icon: 'blackboard',
      type: 'link',
      path: resource.path,
      active: false,
      sidebartitle: resource.sidebartitle,
      module: resource.module,
    });

    return output;
  },
  [...DEFUALTMENU]
);

export const useRole = () => {
  const token = cookies.get('token');
  const [role, setRole] = useState({
    isAllGranted: false,
    RolePermissions: [],
  });

  const resources = useMemo(
    () =>
      RESOURCES.filter(
        ({ module }) =>
          !module ||
          role.isAllGranted ||
          role.RolePermissions.some((permission) => permission.module === module)
      ),
    [role]
  );

  const menuItems = useMemo(
    () =>
      MENUITEMS.filter(
        ({ module }) =>
          !module ||
          role.isAllGranted ||
          role.RolePermissions.some((permission) => permission.module === module)
      ),
    [role]
  );

  useEffect(() => {
    if (token) {
      axios
        .get(`${process.env.REACT_APP_API_DOMAIN}/me`, {
          headers: {
            token,
          },
        })
        .then(({ data: { data } }) => {
          if (!data || !data.Admin) return;
          setRole(
            data.Admin.AdminRole || {
              isAllGranted: false,
              RolePermissions: [],
            }
          );
        })
        .catch((error) => console.log(error));
    }
  }, [token]);
  return {
    role,
    resources,
    menuItems,
  };
};
