import React from 'react';
import { Modal } from 'antd';
import Select from 'react-select';
import { capitalize } from '../../../util/generalHelper';
import { changeBookingStatus } from '../../../util/tableHelper';

const bookingStatusOptions = [
  { value: 'pending', label: 'Pending' },
  { value: 'paid', label: 'Paid' },
  { value: 'active', label: 'Active' },
  { value: 'renewed', label: 'Renewed' },
  { value: 'changed', label: 'Changed' },
  { value: 'temporary', label: 'Temporary' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'expired', label: 'Expired' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'error', label: 'Error' },
  { value: 'timeout', label: 'Timeout' },
  { value: 'terminated', label: 'Terminated' },
  { value: 'tempChangeTerminated', label: 'TempChangeTerminated' },
];

export function StatusActionModal({ showModal, setShowModal, record = {}, setRecord }) {
  return (
    <Modal
      title="Edit booking status"
      visible={showModal}
      onOk={() => changeBookingStatus({ ...record, status: record.status.value })}
      okText="Submit"
      onCancel={() => setShowModal(false)}
      centered
    >
      <Select
        menuPlacement="auto"
        menuPosition="fixed"
        options={bookingStatusOptions}
        value={record.status}
        onChange={(selected) =>
          setRecord({ ...record, status: { value: selected.value, label: selected.label } })
        }
      />
    </Modal>
  );
}

function StatusAction(id, status, setShowModal, setRecord, allowEdit) {
  return (
    <div className="d-flex flex-column">
      {status ? (
        <>
          <span style={{ textAlign: 'center' }}>{capitalize(status)}</span>
          {allowEdit && (
            <button
              type="button"
              className="btn btn-light btn-xs mt-2 mb-2"
              onClick={() => {
                setShowModal(true);
                setRecord({
                  id,
                  status: { value: status, label: capitalize(status) },
                });
              }}
            >
              Change
            </button>
          )}
        </>
      ) : (
        <span>n/a</span>
      )}
    </div>
  );
}

export default StatusAction;
