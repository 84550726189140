import React, { useEffect, useState } from 'react'
import { Row, Col, Label } from 'reactstrap'
import { Spin } from 'antd'
import { capitalize, convertDateFormat } from '../../../util/generalHelper'
import axios from 'axios'
import { detailsOrderObj, detailsBookingsObj } from './constants'
import DetailsButtons from '../../../components/common/profile/DetailsButtons'
import Cookies from 'universal-cookie/cjs';

const cookies = new Cookies();

const OrderDetails = ({ fetch, edit, back }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [Order, setOrder] = useState({})
  const [Bookings, setBookings] = useState([])
  const [GuestBookings, setGuestBookings] = useState([])
  const [PaymentMethod, setPaymentMethod] = useState('')

  const getBookingData = array => {
    return array.map(item => {
      const { Car, ParkingPlan, type, validFrom, validTo } = item
      const { registrationMark } = Car || {}
      const { CarPark, skidataSurname, subTitle } = ParkingPlan || {}
      const { code } = CarPark || {}

      console.log({ registrationMark })

      return {
        registrationMark,
        type: capitalize(type),
        carParkCode: code,
        parkingPlan: `${skidataSurname} ${subTitle}`,
        validFrom: convertDateFormat(validFrom) || 'N/A',
        validTo: convertDateFormat(validTo) || 'N/A'
      }
    })
  }

  useEffect(() => {
    setIsLoading(true)

    axios
      .get(fetch, { headers: { token: cookies.get('token') } })
      .then(({ data: { data } }) => {
        console.log({ data })

        const {
          Bookings: BookingsArr,
          GuestBookings: GuestBookingsArr,
          Customer,
          Payment,
          status
        } = data
        const { phone } = Customer || {}
        const { PaymentMethod: PaymentMethodObj } = Payment || {}
        const { name } = PaymentMethodObj || {}

        let allAccountCodesConcant

        if (BookingsArr.length > 0) {
          const allAccountCodes = BookingsArr.map(({ accountCode }) => accountCode)
          allAccountCodesConcant = allAccountCodes.join(', ')

          const allBookings = getBookingData(BookingsArr)
          setBookings(allBookings)
        }

        if (GuestBookingsArr.length > 0) {
          const allBookings = getBookingData(GuestBookingsArr)
          setGuestBookings(allBookings)
        }

        const orderData = {
          customer: phone,
          status: capitalize(status),
          accountCode: allAccountCodesConcant || 'N/A'
        }
        setOrder(orderData)

        setPaymentMethod(name)

        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        console.log(error)
      })
  }, [])

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spin />
        </div>
      ) : (
        <>
          <DetailsButtons edit={edit} back={back} />

          <h1>Order</h1>
          <Row>
            {detailsOrderObj.map(({ value, label }) => (
              <Col md={6} className="d-flex flex-column mb-5" key={value}>
                <Label>{label}</Label>
                <span>{Order[value] || 'N/A'}</span>
              </Col>
            ))}
          </Row>

          {Bookings.map((item, index) => (
            <div key={index}>
              <h5>Booking #{index + 1}</h5>
              <br />
              <Row>
                {detailsBookingsObj.map(({ value, label }) => (
                  <Col md={6} className="d-flex flex-column mb-5" key={value}>
                    <Label>{label}</Label>
                    <span>{Bookings[index][value] || 'N/A'}</span>
                  </Col>
                ))}
              </Row>
            </div>
          ))}

          {GuestBookings.map((item, index) => (
            <div key={index}>
              <h5>Guest Booking #{index + 1}</h5>
              <br />
              <Row>
                {detailsBookingsObj.map(({ value, label }) => (
                  <Col md={6} className="d-flex flex-column mb-5" key={value}>
                    <Label>{label}</Label>
                    <span>{GuestBookings[index][value] || 'N/A'}</span>
                  </Col>
                ))}
              </Row>
            </div>
          ))}

          <h5>Payment</h5>
          <br />
          <Row>
            <Col md={6} className="d-flex flex-column mb-5">
              <Label>Payment Method</Label>
              <span>{PaymentMethod || 'N/A'}</span>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default OrderDetails
