import React from 'react';
import CONFIG from '../../../../config';

export default function RenderImageTitle({ title, value, file }) {
  return (
    <div>
      <span>{title}</span>
      <br />
      <span style={{ color: 'red' }}>(Best Size : 160x182)</span>
      <img
        src={file ? window.URL.createObjectURL(file) : `${CONFIG.FILE_DOMAIN}${value}`}
        alt=""
        style={{ width: 160 }}
      />
    </div>
  );
}
