import React from 'react'

const DetailsButtons = ({ edit, back }) => {
  return (
    <div className="d-flex justify-content-end mb-4">
      {edit && (
        <button className="btn btn-primary btn-sm mr-3" onClick={() => edit && edit()}>
          Edit
        </button>
      )}
      <button className="btn btn-primary btn-sm" onClick={() => back && back()}>
        Back
      </button>
    </div>
  )
}

export default DetailsButtons
