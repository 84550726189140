export const parkingServiceFilter = [
  { title: 'Type', value: 'type' },
  { title: 'Chinese Type', value: 'chineseType' },
];
export const ParkingServiceTableColumns = [
  'id',
  'type',
  'chineseType',
  'remark',
  'chineseRemark',
  'isEnable',
  'isShowIcon',
  'isIHourly',
  'updatedAt',
  'priority',
];

export const ParkingServiceSchema = [
  { name: 'id', label: 'Id', type: 'STRING' },
  { name: 'type', label: 'Type', type: 'STRING' },
  { name: 'chineseType', label: 'Chinese Type', type: 'STRING' },
  { name: 'remark', label: 'remark', type: 'STRING' },
  { name: 'chineseRemark', label: 'Chinese Remark', type: 'STRING' },
  { name: 'isEnable', label: 'Is Enable', type: 'BOOLEAN' },
  { name: 'isShowIcon', label: 'Is Show Icon', type: 'BOOLEAN' },
  { name: 'isIHourly', label: 'Is i-Hourly', type: 'BOOLEAN' },
  { name: 'updatedAt', label: 'Updated At', type: 'DATE' },
  { name: 'priority', label: 'Priority', type: 'string' },
  { name: 'isSearchParam', label: 'isSearchParam', type: 'BOOLEAN' },
];
