import React, {useEffect, useState} from 'react';
import { Col, Label, Row } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated/dist/react-select.esm';
import axios from "axios";

const allPaymentMethods = [
  { value: 1, label: 'PPS' },
  { value: 2, label: 'VISA/MasterCard (Wirecard)' },
  { value: 3, label: 'Cheque' },
  { value: 4, label: '7-11' },
  { value: 5, label: 'Octopus' },
  { value: 6, label: 'VISA/MasterCard' },
  { value: 7, label: 'O! ePay' },
  { value: 8, label: 'WeChat Pay' },
  { value: 9, label: 'FPS' },
  { value: 10, label: 'Cash' },
];

const LocationField = ({ value, onChange = () => {}, isRequired = false }) => {
  const [locationOptions, setLocationOptions] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/location?isCustomerLocation=true`)
      .then(({ data: { data } }) => {
        const allRegionOptions = data.reduce((output, { id, district }) => {
          output.push({ value: id, label: district });
          return output;
        }, []);

        return setLocationOptions(allRegionOptions);
      })
      .catch(error => console.log(error))
  }, []);

  return (
    <Row>
      <Col md={6} className="mb-4">
        <Label>
          Location
          {isRequired && <span style={{color: 'red'}}>*</span>}
        </Label>
        <Select
          closeMenuOnSelect={false}
          components={() => makeAnimated()}
          value={locationOptions.find((item) => item.value === value)}
          options={locationOptions}
          onChange={onChange}
        />
      </Col>
    </Row>
  );
};

const FormCustomExtraField = ({ value, onChange = () => {}, relation, isRequired = false }) => {

  switch (relation) {
    case 'PaymentMethods':
      return (
        <Row>
          <Col md={6} className="mb-4">
            <Label>Payment Methods</Label>
            <Select
              closeMenuOnSelect={false}
              components={() => makeAnimated()}
              value={value}
              isMulti
              options={allPaymentMethods}
              onChange={onChange}
            />
          </Col>
        </Row>
      );
    case 'Location':
      return <LocationField value={value} onChange={onChange} isRequired={isRequired} />;
    default:
      return null;
  }
};

export default FormCustomExtraField;
