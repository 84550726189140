import React from 'react';
import { Button } from 'reactstrap';
import Breadcrumb from './breadcrumb.component';
import UserImportModalAndButton from '../../containers/Custom/Customer/UserImportModalAndButton';

const Layout = ({ title = '', moduleName, add, render, section }) => {
  const titleValue = render === 'table' ? 'Table' : title;

  return (
    <div>
      <Breadcrumb title={title} parent={moduleName} children={titleValue} />

      <div className="container-fluid">
        {(render === 'table' || render === 'details') && (
          <div className="d-flex justify-content-end mb-4">
            {add && (
              <Button size="sm" color="primary" onClick={add}>
                Add
              </Button>
            )}
            {title === 'Customer' && render === 'table' && <UserImportModalAndButton />}
          </div>
        )}

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">{section}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
