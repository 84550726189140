import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// Import custom components
import Cookies from 'universal-cookie/cjs';
import Layout from '../../components/common/Layout';
import Table from './Table';
import Details from './Details';
import NewRecord from './NewRecord';
import { Change, ChangeLpn, Renew } from '../Custom/LastBooking';

const cookies = new Cookies();

const { REACT_APP_API_DOMAIN } = process.env;

function Item({ moduleName, api, permissions }) {
  const location = useLocation();

  const [render, setRender] = useState('table');
  const [id, setId] = useState('');

  useEffect(() => {
    if (!cookies.get('token')) {
      window.location = '/';
    }
  }, []);

  const renderSectionTitle = () => {
    switch (render) {
      case 'table':
        return `${moduleName}`;
      case 'details':
        return 'Details';
      case 'edit':
        return `Edit ${moduleName}`;
      case 'add':
        return `Add New ${moduleName}`;
      default:
        return render.replace(/([A-Z])/g, ' $1').trim();
    }
  };

  const renderSection = () => {
    const capitalized = api
      .replace(/^(\w)/, (m) => m.toUpperCase())
      .replace(/(-\w)/g, (m) => m[1].toUpperCase());
    // let capitalized = api.charAt(0).toUpperCase() + api.slice(1);

    const renderEditButton = (id) => {
      setRender('edit');
      setId(id);
    };

    switch (render) {
      case 'table':
        return (
          <Table
            module={location.pathname}
            getAllRecords={`${REACT_APP_API_DOMAIN}/${api}`}
            details={(id) => {
              setRender('details');
              setId(id);
            }}
            edit={permissions.includes('UPDATE') ? renderEditButton : undefined}
            customPage={(id, page) => {
              setRender(page);
              setId(id);
            }}
            canExport={permissions.includes('EXPORT')}
          />
        );
      case 'details':
        return (
          <Details
            module={location.pathname}
            getRecord={`${REACT_APP_API_DOMAIN}/${api}/${id}`}
            id={id}
            edit={permissions.includes('UPDATE') ? () => setRender('edit') : undefined}
            back={() => setRender('table')}
          />
        );
      case 'add':
        return (
          <NewRecord
            module={location.pathname}
            getForm={`${REACT_APP_API_DOMAIN}/schema/${capitalized}`}
            id={id}
            createRecord={`${REACT_APP_API_DOMAIN}/${api}`}
            type="add"
            cancel={() => setRender('table')}
          />
        );
      case 'edit':
        return (
          <NewRecord
            module={location.pathname}
            getForm={`${REACT_APP_API_DOMAIN}/${api}/${id}`}
            updateRecord={`${REACT_APP_API_DOMAIN}/${api}/${id}`}
            id={id}
            type="edit"
            cancel={() => setRender('table')}
            allowDelete={permissions.includes('DELETE')}
          />
        );
      case 'renew':
        return (
          <Renew
            id={id}
            saveActionURL={`${REACT_APP_API_DOMAIN}/admin/booking/renew`}
            back={() => setRender('table')}
          />
        );
      case 'change':
        return (
          <Change
            id={id}
            saveActionURL={`${REACT_APP_API_DOMAIN}/admin/booking/change`}
            back={() => setRender('table')}
          />
        );
      case 'changeRegistrationMark':
        return (
          <ChangeLpn
            id={id}
            saveActionURL={`${REACT_APP_API_DOMAIN}/admin/booking/changeRegistrationMark`}
            back={() => setRender('table')}
          />
        );
      default:
        break;
    }
  };

  const renderAddButton = () => setRender('add');

  const addRecord = () => {
    if (!permissions.includes('CREATE')) return undefined;
    switch (location.pathname) {
      case '/skidata':
      case '/lastbooking':
      case '/onlinetransaction':
      case '/offlinetransaction':
      case '/changelpn':
      case '/buynew':
      case '/accountmonthlyparking':
      case '/summary':
      case '/payment':
      case '/operation':
      case '/cqm':
      case '/seveneleven':
      case '/iParkTransaction':
      case '/iParkCoupon':
      case '/fileUpload':
      case '/ourCompany':
      case '/homePageAndPages':
      case '/waitingListReport':
        return undefined;
      default:
        return renderAddButton;
    }
  };

  return (
    <Layout
      moduleName={moduleName}
      title={renderSectionTitle()}
      render={render}
      section={renderSection()}
      add={addRecord()}
    />
  );
}

export default Item;
