import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { Label, Input, Button } from 'reactstrap';
import axios from 'axios';
import Cookies from 'universal-cookie/cjs';
import { isEmpty } from 'lodash';
import ReactJson from 'react-json-view';

const cookies = new Cookies();

export default function UserImportModalAndButton() {
  const [phone, setPhone] = useState('');
  const [importResult, setImportResult] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);

  useEffect(() => {
    if (!showImportModal) {
      setPhone('');
      setImportResult({});
    }
  }, [showImportModal]);

  function handleImport(isPreview = true) {
    const rule = /^1[0-9]{10}$|^[456789][0-9]{7}$/;
    if (!phone || !rule.test(phone)) {
      alert('Please enter valid phone number');
      return;
    }

    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/user/import`,
        { phone, isPreview },
        {
          headers: { token: cookies.get('token') },
        }
      )
      .then((e) => {
        const { isOldCustomerFound, error, data } = e.data || {};
        const { dataFromOldSystem = {} } = data || {};

        if (error) {
          alert(error);
          return;
        }

        if (!isPreview && !error && isOldCustomerFound) {
          if (!alert('User successfully imported')) {
            window.location.reload();
          }
          return;
        }

        if (isOldCustomerFound && !isEmpty(dataFromOldSystem)) {
          setImportResult(dataFromOldSystem);
          return;
        }

        throw 'Unkonw Error,please try again';
      })
      .catch(() => alert('Unkonw Error,please try again'))
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <Button
        style={{ marginLeft: 20 }}
        size="sm"
        color="primary"
        onClick={() => {
          setShowImportModal(true);
        }}
      >
        Import
      </Button>

      <Modal
        title="Import User From Old System"
        visible={showImportModal}
        okText="Import"
        onCancel={() => setShowImportModal(false)}
        centered
        confirmLoading={isLoading}
        footer={[
          <Button
            key="preview"
            size="sm"
            color="primary"
            onClick={() => handleImport(true)}
            disabled={isLoading}
          >
            Preview
          </Button>,
          <Button
            key="import"
            size="sm"
            color="primary"
            disabled={isEmpty(importResult) || isLoading}
            onClick={() => handleImport(false)}
          >
            Import
          </Button>,
        ]}
      >
        <Label>Phone Number</Label>
        <Input value={phone} onChange={(e) => setPhone(e.target.value)} />

        {!isEmpty(importResult) && (
          <>
            <Label style={{ marginTop: 20, fontSize: 20 }}>Preview</Label>
            <ReactJson name="User Data" src={importResult} displayDataTypes={false} />
          </>
        )}
      </Modal>
    </>
  );
}
