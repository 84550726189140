import React, { useEffect, useState } from 'react';
import { Row, Col, Label, Input } from 'reactstrap';
import { Spin, DatePicker, Radio, Input as AntInput, Modal } from 'antd';
import axios from 'axios';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import Cookies from 'universal-cookie/cjs';
import { isEmpty } from 'lodash';
import { getDistrictsByRegion } from '../../../util/selectOptions';
import { loadCustomersOptions, checkLPN } from '../../../util/generalHelper';
import { createRecord, updateRecord, deleteRecord } from '../../../util/formHelper';
import { CreateRecordButtons, UpdateRecordButtons } from '.';
import ErrorMessage from './errorMessage';

const cookies = new Cookies();

function SimplifiedForm({
  fetch,
  create,
  update,
  type,
  cancel,
  schema,
  getSelectOptions,
  cleanFormData,
  cleanSubmitData,
  getCarParks,
  requiredFields = [],
  remarks = {},
  allowDelete,
  addOrigin = false,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [options, setOptions] = useState({});
  const [errors, setErrors] = useState({});

  const [carParks, setCarParks] = useState(new Map());

  const [showModal, setShowModal] = useState(false);

  const fetchDropdownOptions = async () => {
    const allOptions = await getSelectOptions();
    setOptions(allOptions);
  };

  const fetchExistingRecord = async () => {
    setIsLoading(true);
    fetchDropdownOptions();

    try {
      const response = await axios.get(fetch, { headers: { token: cookies.get('token') } });
      const { data } = response.data;

      const cleanedDetails = await cleanFormData(data, 'form');
      setDetails(cleanedDetails);

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      alert('Loading error');
      setIsLoading(false);
    }
  };

  const getAllCarParks = async () => {
    const {
      data: { carPark },
    } = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/booking-options/carPark`);

    const carParksInput = new Map(Object.entries(carPark));
    setCarParks(carParksInput);
  };

  useEffect(() => {
    if (type === 'edit') {
      fetchExistingRecord();
    } else {
      fetchDropdownOptions();
    }

    if (getCarParks) {
      getAllCarParks();
    }
  }, []);

  useEffect(() => {
    if (getCarParks && carParks && details.carParkCode) {
      const allPlans = carParks.get(details.carParkCode.label) || [];

      setOptions({ ...options, parkingPlan: allPlans });
    }
  }, [carParks, details.carParkCode]);

  const handleInput = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleSelect = (selected, name) => {
    setDetails({ ...details, [name]: selected });
  };

  const handleSelectRegion = async (selected) => {
    const { allDistrictOptions } = await getDistrictsByRegion(selected.value);
    setDetails({ ...details, region: selected, district: allDistrictOptions[0] });
    setOptions({ ...options, district: allDistrictOptions });
  };

  const onChangeDate = (dateString, name) => {
    setDetails({ ...details, [name]: dateString });
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const errorObj = requiredFields.reduce((acc, v) => {
      const isCarPage =
        typeof fetch === 'string' && fetch ? fetch.toLocaleLowerCase().includes('car') : false;

      if (
        v === 'registrationMark' &&
        details.registrationMark &&
        !checkLPN(details.registrationMark, isCarPage ? 15 : 8)
      ) {
        return { ...acc, [v]: 'invalid registration mark' };
      }

      return !details?.[v] ? { ...acc, [v]: 'request field' } : acc;
    }, {});

    if (!isEmpty(errorObj)) {
      setErrors(errorObj);
      alert('Something went wrong. Please make sure to fill in all the information');
      return;
    }

    const body = await cleanSubmitData(details, type);

    if (body.registrationMark) {
      body.registrationMark = body.registrationMark.trim();
    }

    if (type === 'edit') {
      updateRecord(update, body);
    } else {
      if (addOrigin) Object.assign(body, { origin: 'cms' });
      createRecord(create, body);
    }
  };

  const renderFields = ({ name, label, type, loadOptions }) => {
    const isRequired = (requiredFields || []).some((field) => field === name);
    switch (type) {
      case 'date':
        return (
          <Col md={6} className="mb-4" key={name}>
            <div className="d-flex flex-column">
              <Label>
                {label}
                {isRequired && <span style={{ color: 'red' }}>*</span>}
              </Label>
              <DatePicker
                value={details[name] ? moment(details[name], 'YYYY-MM-DD') : null}
                className="mb-3"
                onChange={(date, dateString) => onChangeDate(dateString, name)}
              />
              {remarks[name] && (
                <span style={{ color: 'gray', fontSize: '9px' }}>{remarks[name]}</span>
              )}
              <ErrorMessage name={name} errors={errors} />
            </div>
          </Col>
        );
      case 'radio':
        return (
          <Col md={6} className="mb-4" key={name}>
            <div className="d-flex flex-column">
              <Label>
                {label}
                {isRequired && <span style={{ color: 'red' }}>*</span>}
              </Label>
              <Radio.Group
                onChange={handleInput}
                name={name}
                value={details[name]}
                className="ml-1 mt-1 mb-3"
              >
                <Radio value className="mr-5">
                  Yes
                </Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
              {remarks[name] && (
                <span style={{ color: 'gray', fontSize: '9px' }}>{remarks[name]}</span>
              )}
              <ErrorMessage name={name} errors={errors} />
            </div>
          </Col>
        );
      case 'asyncDropdown':
        return (
          <Col md={6} className="mb-4" key={name}>
            <Label>
              {label}
              {isRequired && <span style={{ color: 'red' }}>*</span>}
            </Label>
            <AsyncSelect
              value={details[name]}
              menuPlacement="auto"
              menuPosition="fixed"
              onChange={(selected) => handleSelect(selected, name)}
              loadOptions={loadOptions || loadCustomersOptions}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: errors[name] !== undefined ? 'red' : 'hsl(0,0%,80%)',
                }),
              }}
            />
            {remarks[name] && (
              <span style={{ color: 'gray', fontSize: '9px' }}>{remarks[name]}</span>
            )}
            <ErrorMessage name={name} errors={errors} />
          </Col>
        );
      case 'dropdown':
        return (
          <Col md={6} className="mb-4" key={name}>
            <Label>
              {label}
              {isRequired && <span style={{ color: 'red' }}>*</span>}
            </Label>
            {remarks[name] && (
              <>
                <br />
                <span style={{ color: 'gray', fontSize: '10px' }}>{remarks[name]}</span>
              </>
            )}
            <Select
              menuPlacement="auto"
              menuPosition="fixed"
              options={options[name]}
              value={details[name] || {}}
              onChange={(selected) =>
                name === 'region' ? handleSelectRegion(selected) : handleSelect(selected, name)
              }
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: errors[name] !== undefined ? 'red' : 'hsl(0,0%,80%)',
                }),
              }}
            />
            <ErrorMessage name={name} errors={errors} />
          </Col>
        );
      case 'password':
        return (
          <Col md={6} className="mb-4" key={name}>
            <Label>
              {label}
              {isRequired && <span style={{ color: 'red' }}>*</span>}
            </Label>
            <AntInput.Password
              type="text"
              name={name}
              value={details[name] || ''}
              onChange={handleInput}
              autoComplete={false}
            />
            {remarks[name] && (
              <span style={{ color: 'gray', fontSize: '9px' }}>{remarks[name]}</span>
            )}
            <ErrorMessage name={name} errors={errors} />
          </Col>
        );
      case 'textarea':
        return (
          <Col md={12} className="mb-4" key={name}>
            <div className="d-flex flex-column">
              <Label>
                {label}
                {isRequired && <span style={{ color: 'red' }}>*</span>}
              </Label>
              <textarea
                style={{
                  border: '1px solid #ced4da',
                  padding: 10,
                  borderRadius: '4px',
                }}
                name={name}
                rows="4"
                value={details[name]}
                onChange={handleInput}
              />
              {remarks[name] && (
                <span style={{ color: 'gray', fontSize: '9px' }}>{remarks[name]}</span>
              )}
              <ErrorMessage name={name} errors={errors} />
            </div>
          </Col>
        );
      default:
        return (
          <Col md={6} className="mb-4" key={name}>
            <Label>
              {label}
              {isRequired && <span style={{ color: 'red' }}>*</span>}
            </Label>
            {name === 'cardNumber' ? (
              <Input
                type="number"
                name={name}
                value={details[name] && details[name].label}
                onChange={handleInput}
                disabled={name === 'id'}
              />
            ) : (
              <Input
                type={type}
                name={name}
                value={details[name] || ''}
                onChange={handleInput}
                disabled={name === 'id'}
                autoComplete={false}
                style={{ border: errors[name] !== undefined && '1px solid red' }}
              />
            )}
            {remarks[name] && (
              <span style={{ color: 'gray', fontSize: '9px' }}>{remarks[name]}</span>
            )}
            <ErrorMessage name={name} errors={errors} />
          </Col>
        );
    }
  };

  const renderConfirmDeleteModal = () => (
    <Modal
      title="Delete record?"
      visible={showModal}
      onOk={() => deleteRecord(update)}
      okText="Confirm"
      onCancel={() => setShowModal(false)}
      centered
    >
      Please click "Confirm" to delete the record.
    </Modal>
  );

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spin />
        </div>
      ) : (
        <>
          <Row>{schema.map((item) => renderFields(item))}</Row>

          {type === 'edit' ? (
            <>
              {renderConfirmDeleteModal()}
              <UpdateRecordButtons
                handleSave={handleSave}
                handleDelete={() => setShowModal(true)}
                cancel={cancel}
                allowDelete={allowDelete}
              />
            </>
          ) : (
            <CreateRecordButtons handleSave={handleSave} cancel={cancel} />
          )}
        </>
      )}
    </>
  );
}

export default SimplifiedForm;
