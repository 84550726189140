import React, { Component } from 'react';

class Breadcrumb extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-lg-6">
              <h3>
                {this.props.title}
                {/* <small>Let's Park Profile for Corporate Users</small> */}
              </h3>
            </div>
            <div className="col-lg-6">
              <ol className="breadcrumb pull-right">
                <li className="breadcrumb-item">
                  <a href="#!">
                    <i className="fas fa-home" style={{ color: '#1fb2af' }} />
                  </a>
                </li>
                <li className="breadcrumb-item">{this.props.parent}</li>
                <li className="breadcrumb-item active">{this.props.children}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Breadcrumb;
