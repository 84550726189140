import { loadRegistrationMarkOptions } from '../../../util/generalHelper';

export const bookingFilter = [
  { title: 'Phone', value: 'phone' },
  { title: 'Registration Mark', value: 'registrationMark' },
];

export const BookingTableColumns = [
  'id',
  'carParkCode',
  'phone',
  'ppCardNumber',
  'accountCode',
  'registrationMark',
  'parkingServiceDesc',
  'expiryDate',
  'lastPaymentTime',
  'status',
];

export const BookingSchema = [
  { name: 'carParkCode', label: 'Car Park Code', type: 'dropdown' },
  { name: 'parkingPlan', label: 'Parking Plan', type: 'dropdown' },
  { name: 'validFrom', label: 'Valid From', type: 'date' },
  { name: 'validTo', label: 'Valid To', type: 'date' },
  {
    name: 'registrationMark',
    label: 'Registration Mark',
    type: 'asyncDropdown',
    loadOptions: loadRegistrationMarkOptions,
  },
  { name: 'customer', label: 'Customer', type: 'asyncDropdown' },
  { name: 'ppCardNumber', label: 'PP Card Number', type: 'dropdown' },
];
