export const OrderTableColumns = [
  'id',
  'originalId',
  'reference',
  'type',
  'status',
  'createdAt',
  'updatedAt',
  'customerPhone',
  'registrationMark',
  'carParkCode',
  'validFrom',
  'validTo',
];

export const detailsOrderObj = [
  { value: 'customer', label: 'Customer Phone' },
  { value: 'status', label: 'Status' },
  { value: 'accountCode', label: 'Account Code' },
];

export const detailsBookingsObj = [
  { value: 'registrationMark', label: 'Registration Mark' },
  { value: 'type', label: 'Type' },
  { value: 'carParkCode', label: 'Car Park Code' },
  { value: 'parkingPlan', label: 'Parking Plan' },
  { value: 'validFrom', label: 'Valid From' },
  { value: 'validTo', label: 'Valid To' },
];

export const bookingTypeOptions = [
  { value: 'new', label: 'New' },
  { value: 'renew', label: 'Renew' },
  { value: 'change', label: 'Change' },
  { value: 'change license', label: 'Change License' },
];

export const orderStatusOptions = [
  { value: 'pending', label: 'Pending' },
  { value: 'paid', label: 'Paid' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'error', label: 'Error' },
  { value: 'time', label: 'Timeout' },
];

export const orderDefaultObj = {
  customer: '',
  status: '',
  accountCode: '',
};

export const bookingDefaultObj = {
  registrationMark: { value: '', label: '' },
  type: { value: '', label: '' },
  carParkCode: { value: '', label: '' },
  parkingPlan: { value: '', label: '' },
  validFrom: null,
  validTo: null,
};

export const adminDefaultObj = {
  name: '',
  level: 'Company',
  isEnable: true,
  AdminRole: {
    id: '',
    name: '',
  },
};

export const userDefaultObj = {
  username: '',
  password: '',
  email: '',
  phone: '',
  type: 'admin',
};

export const waitingListDefaultObj = {
  id: '',
  reference: '',
  CarParkId: '',
  CustomerId: '',
  CarId: '',
  ParkingPlanId: '',
  status: '',
  remarks: '',
  createdAt: '',
};

export const adminRoleDefaultObj = {
  isAllGranted: false,
  level: 'Company',
  name: '',
};

export const rolePermissionDefaultObj = {
  AdminRoleId: '',
  action: 'READ',
  module: '',
};
