import React, { useEffect, useState } from 'react';
import { Col, Label, Row, Input } from 'reactstrap';
import { Table as AntTable } from 'antd';
import 'antd/dist/antd.css';
import Select from 'react-select';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Cookies from 'universal-cookie/cjs';
import { generateTableColumns, cleanDefaultTableData } from '../../../util/tableHelper';
import useSyncCarPark from './useSyncCarPark';

const cookies = new Cookies();

const SkiDataMontlyParkerTable = ({
  getAllRecords,
  details,
  edit,
  customSchema = [],
  groupedHeadColumns = undefined,
  customCleanData = undefined,
  customColumn = undefined, // column that the returned table schema does NOT include
  reworkedColumn = undefined, // column that the returned table schema includes, with ADD-ON needed
  defaultSorting = {},
}) => {
  const history = useHistory();
  const location = useLocation();
  const { page: pageFromQuery = 1, ...queryFilters } = queryString.parse(location.search);
  const { SkiDataTable, syncCarPark } = useSyncCarPark();

  const [carParks, setCarParks] = useState([]);
  const [selectedCarPark, setSelectedCarPark] = useState(null);
  const [registrationMask, setRegistrationMask] = useState('');
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [schema, setSchema] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 100,
    current: parseInt(pageFromQuery),
  });
  const [filter, setFilter] = useState({ ...queryFilters });
  const [sortBy, setSortBy] = useState({
    sortField: '',
    sortOrder: '',
  });

  const fetch = (params = {}) => {
    setIsLoading(true);

    const updatedFilter = Object.entries(filter).reduce((output, [key, value]) => {
      if (value !== '' && !Array.isArray(value)) {
        output[key] = value;
      }
      if (Array.isArray(value) && value.length > 0) {
        if (value.filter((e) => e).length === 0) return output;
        output[key] = value.filter((e) => e).toString();
      }
      if (value.hasOwnProperty('value') && value.hasOwnProperty('label')) {
        output[key] = value.value;
      }

      return output;
    }, {});

    const {
      pagination,
      sortField = defaultSorting.sortField || 'id',
      sortOrder = defaultSorting.sortOrder || 'descend',
    } = params;

    const updatedParams = {
      ...updatedFilter,
      ...pagination,
      sortField,
      sortOrder,
    };

    // Convert Antd Table "pagination.current" to API query param "page"
    updatedParams.page = updatedParams.current;
    delete updatedParams.current;

    axios
      .get(getAllRecords, {
        params: updatedParams,
        headers: { token: cookies.get('token') },
      })
      .then(async (response) => {
        // console.log(response);
        let cleaned;

        if (customCleanData === undefined) {
          cleaned = cleanDefaultTableData(response);
          setSchema(cleaned.cleanedSchema);
        } else if (customCleanData && reworkedColumn) {
          cleaned = await customCleanData(response, customColumn, reworkedColumn, setIsLoading);
          customSchema && setSchema(customSchema);
        } else {
          cleaned = await customCleanData(response, customColumn, setIsLoading);
          customSchema && setSchema(customSchema);
        }

        setDataSource(cleaned.newDataSource);
        setPagination({ ...params.pagination, total: response.data.count });
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status) {
          const errorType = error.response.status.toString()[0];
          if (errorType === '5') {
            alert('Filter not available at the moment');
          }
        }
        setDataSource([]);
        setIsLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const page = filters ? pagination.current : 1;
    const { columnKey, order } = sorter || {};

    let search = `?page=${page}`;
    if (filter) {
      Object.keys(filter).forEach((filterKey) => {
        let value = filter[filterKey];

        if (typeof filter[filterKey] === 'object') value = filter[filterKey].value;
        if (Array.isArray(filter[filterKey])) value = filter[filterKey].toString();
        if (!value) return '';
        search += `&${filterKey}=${value}`;
      });
    }

    history.replace({
      pathname: location.pathname,
      search,
    });

    setSortBy({ page: pagination.current, columnKey, order });

    fetch({
      pagination: { current: page },
      sortField: columnKey,
      sortOrder: order,
    });
  };

  const renderDetailsButton = (id) => (
    <button
      type="button"
      className="btn btn-primary btn-xs mr-3"
      onClick={() => details && details(id)}
    >
      Details
    </button>
  );

  const renderEditButton = (id) => (
    <button type="button" className="btn btn-primary btn-xs mr-3" onClick={() => edit && edit(id)}>
      Edit
    </button>
  );

  const tableSchema = generateTableColumns(
    schema,
    details ? (id) => renderDetailsButton(id) : undefined,
    edit ? (id) => renderEditButton(id) : undefined
  );

  useEffect(() => {
    if (isInitialized && Object.keys(filter).length === 0) {
      handleTableChange(pagination);
    }
  }, [filter]);

  const getAllCarParks = async () => {
    const {
      data: { data = [] },
    } = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/CarPark`);

    const cleanData = data
      .filter(({ facilityNo }) => facilityNo)
      .map(({ id, code, name }) => ({
        value: id,
        label: `(${code}) ${name}`,
      }));

    setCarParks(cleanData);
  };

  useEffect(() => {
    fetch({ pagination: { current: parseInt(pageFromQuery) } });

    getAllCarParks();

    setIsInitialized(true);
  }, []);

  const handleTableFilter = () => {
    handleTableChange(pagination, filter, sortBy);
  };

  return (
    <>
      <Row style={{ display: 'flex', alignItems: 'center' }}>
        <Col md={4} className="mb-4">
          <Label>Car Parks</Label>
          <Select
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({ ...provided, zIndex: 999 }),
            }}
            value={selectedCarPark}
            options={carParks}
            onChange={(selected) => {
              setFilter({ ...filter, CarParkId: selected.value });
              setSelectedCarPark(selected);
            }}
          />
        </Col>

        <Col md={4} className="mb-4">
          <Label>Registration Mark</Label>
          <Input
            type="text"
            value={registrationMask}
            onChange={(e) => {
              setRegistrationMask(e.target.value);
              setFilter({ ...filter, UserSurname: e.target.value });
            }}
          />
        </Col>

        <button
          style={{ height: 36 }}
          type="button"
          className="btn btn-primary btn-sm"
          onClick={handleTableFilter}
        >
          Search
        </button>

        {!isLoading && location.search.includes('CarParkId') && (
          <button
            style={{ height: 36, marginLeft: 20 }}
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => {
              if (window.confirm(`Are you sure to sync data from SkiData from the selected car park${ registrationMask ? ` for ${registrationMask}` : ' (ALL records)' }?`)) {
                syncCarPark(selectedCarPark.value, registrationMask);
              }
            }}
          >
            Sync
          </button>
        )}

        <button
          type="button"
          className="btn btn-link btn-sm"
          onClick={() => {
            setSelectedCarPark(null);
            setRegistrationMask("");
            setFilter({});
          }}
        >
          Reset
        </button>
      </Row>

      <AntTable
        className="w-100"
        columns={groupedHeadColumns || tableSchema}
        rowKey="id"
        dataSource={dataSource}
        pagination={{ ...pagination, position: 'both', showQuickJumper: true }}
        loading={isLoading}
        onChange={handleTableChange}
        scroll={{ x: true }}
        bordered={!!groupedHeadColumns}
      />

      <SkiDataTable />
    </>
  );
};

export default SkiDataMontlyParkerTable;
