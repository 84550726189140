import React from 'react';

export const NewsTableColumns = [
  'id',
  'title',
  'chineseTitle',
  'abstract',
  'chineseAbstract',
  'content',
  'chineseContent',
  'status',
  'startDate',
  'expiryDate',
  'createdAt',
];

export const AnnouncementTableColumns = [
  'id',
  'title',
  'chineseTitle',
  'content',
  'chineseContent',
  'status',
  'startDate',
  'expiryDate',
  'createdAt',
  'urlLink',
  'chineseUrlLink',
];
