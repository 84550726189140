import React, { useEffect, useMemo, useState } from 'react';
import Cookies from 'universal-cookie/cjs';
import axios from 'axios';
import { Radio, Spin, Modal } from 'antd';
import { Col, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import { adminDefaultObj, userDefaultObj } from '../Order/constants';
import { CreateRecordButtons, UpdateRecordButtons } from '../../../components/common/profile';
import { getAdminRoles, getCarParkOptions } from '../../../util/generalHelper';
import { createRecord, updateRecord, deleteRecord } from '../../../util/formHelper';

const cookies = new Cookies();

const AdminForm = ({ fetch, update, type, cancel, allowDelete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [Admin, setAdmin] = useState(adminDefaultObj);
  const [User, setUser] = useState(userDefaultObj);
  const [carParkOptions, setCarParkOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const AdminRole = useMemo(
    () => ({ value: Admin.AdminRole.id || '', label: Admin.AdminRole.name || '' }),
    [Admin.AdminRole]
  );

  const [adminRoleOptions, setAdminRoleOptions] = useState({});

  const fetchDropdownOptions = async () => {
    const AdminRoles = await getAdminRoles();
    setAdminRoleOptions(
      AdminRoles.filter(({ isEnable }) => isEnable).map((role) => ({
        value: role.id,
        label: role.name,
      }))
    );
    const { carParkOptions: carparks } = await getCarParkOptions();
    setCarParkOptions(carparks);
    setIsLoading(false);
  };
  const fetchExistingRecord = async () => {
    fetchDropdownOptions();

    try {
      const {
        data: { data },
      } = await axios.get(fetch, { headers: { token: cookies.get('token') } });
      const { User: user, ...admin } = data;
      if (user.password) delete user.password;
      setUser(user);
      setAdmin(admin);
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);

    if (type === 'edit') {
      fetchExistingRecord();
    } else {
      fetchDropdownOptions();
      setAdmin(adminDefaultObj);
      setUser(userDefaultObj);
    }
  }, []);

  const handleSave = async () => {
    const { name, isEnable, carparks } = Admin;

    const { username, email, phone } = User;

    const body = {
      name,
      isEnable,
      carparks,
      AdminRoleId: AdminRole.value,
      User: {
        username,
        email,
        phone,
      },
    };

    if (User.id) body.User.id = User.id;
    if (User.password) body.User.password = User.password;

    if (type === 'edit') {
      // console.log({ update, body });
      updateRecord(update, body);
    } else {
      // console.log({ body });
      createRecord(`${process.env.REACT_APP_API_DOMAIN}/admin`, body);
    }
  };

  function renderConfirmDeleteModal(data) {
    return (
      <Modal
        title="Delete record?"
        visible={showModal}
        onOk={() => deleteRecord(data)}
        okText="Confirm"
        onCancel={() => setShowModal(false)}
        centered
      >
        Please click "Confirm" to delete the record.
      </Modal>
    );
  }

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spin />
        </div>
      ) : (
        <>
          <Row>
            <Col md={6} className="mb-4">
              <Label>Username</Label>
              <Input
                type="text"
                value={User.username}
                name="username"
                // onChange={(e) => console.log(e.target) }
                onChange={(e) => setUser({ ...User, username: e.target.value })}
              />
            </Col>
            <Col md={6} className="mb-4">
              <Label>Password</Label>
              <Input
                type="password"
                value={User.password || ''}
                name="password"
                onChange={(e) => setUser({ ...User, password: e.target.value })}
              />
            </Col>
            <Col md={6} className="mb-4">
              <Label>Name</Label>
              <Input
                type="name"
                value={Admin.name}
                name="password"
                onChange={(e) => setAdmin({ ...Admin, name: e.target.value })}
              />
            </Col>
            <Col md={6} className="mb-4">
              <Label>Email</Label>
              <Input
                type="email"
                value={User.email}
                name="email"
                onChange={(e) => setUser({ ...User, email: e.target.value })}
              />
            </Col>
            <Col md={6} className="mb-4">
              <Label>Phone</Label>
              <Input
                type="text"
                value={User.phone}
                name="phone"
                onChange={(e) => setUser({ ...User, phone: e.target.value })}
              />
            </Col>
            <Col md={6} className="mb-4">
              <Label>Role</Label>
              <Select
                menuPlacement="auto"
                menuPosition="fixed"
                options={adminRoleOptions}
                value={AdminRole}
                onChange={(selected) =>
                  setAdmin({
                    ...Admin,
                    AdminRole: { id: selected.value, name: selected.label },
                  })
                }
              />
            </Col>
            <Col md={6} className="mb-4">
              <div className="d-flex flex-column">
                <Label>Enable?</Label>
                <Radio.Group
                  name="isEnable"
                  value={!!Admin.isEnable}
                  onChange={(e) => setAdmin({ ...Admin, isEnable: e.target.value })}
                  className="ml-1 mt-1 mb-3"
                >
                  <Radio value className="mr-5">
                    Yes
                  </Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </div>
            </Col>

            <Col md={6} className="mb-4">
              <Label>Carparks</Label>
              <Select
                menuPlacement="auto"
                menuPosition="fixed"
                options={carParkOptions}
                value={carParkOptions.filter((option) =>
                  (Admin.carparks || []).includes(option.value)
                )}
                onChange={(value) =>
                  setAdmin({
                    ...Admin,
                    carparks: (value || []).map((item) => parseInt(item.value, 10)),
                  })
                }
                isMulti
              />
            </Col>
          </Row>

          {type === 'edit' ? (
            <>
              {renderConfirmDeleteModal(update)}
              <UpdateRecordButtons
                handleSave={handleSave}
                handleDelete={() => setShowModal(true)}
                cancel={cancel}
                allowDelete={allowDelete}
              />
            </>
          ) : (
            <CreateRecordButtons handleSave={handleSave} cancel={cancel} />
          )}
        </>
      )}
    </>
  );
};

export default AdminForm;
