import React, { useEffect, useState } from 'react';
import { Row, Col, Label } from 'reactstrap';
import { Spin } from 'antd';
import axios from 'axios';
import Select from 'react-select';
import SubmitButtons from './SubmitButtons';
import { getCarParks } from '../../../util/generalHelper';
import Cookies from 'universal-cookie/cjs';

const cookies = new Cookies();

const defaultDetails = {
  carParkCode: { value: '', label: '' },
  parkingPlan: { value: '', label: '' }
};

const Change = ({ id, back, saveActionURL }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [carParks, setCarParks] = useState(new Map());
  const [carParkOptions, setCarParkOptions] = useState([]);
  const [parkingPlanOptions, setParkingPlanOptions] = useState([]);
  const [details, setDetails] = useState(defaultDetails);

  useEffect(() => {
    setIsLoading(true);

    async function fetchData() {
      try {
        const [
          { carParksInput, carParkOptions },
          {
            data: { data }
          }
        ] = await Promise.all([
          getCarParks(),
          axios.get(`${process.env.REACT_APP_API_DOMAIN}/booking/${id}`,
            { headers: { token: cookies.get('token') } })
        ]);

        setCarParks(carParksInput);
        setCarParkOptions(carParkOptions);

        const { CarParkId, ParkingPlan } = data;
        const { CarPark, ParkingService, ParkingServiceId, subTitle } = ParkingPlan || {};
        const { code } = CarPark || {};
        const { type } = ParkingService || {};

        const parkingPlanName = `${type} | ${subTitle}`;

        const carParkCode = { value: CarParkId, label: code };
        const parkingPlan = { value: ParkingServiceId, label: parkingPlanName };

        setDetails({ carParkCode, parkingPlan });
      } catch (err) {
        console.log({ err });
        alert('Loading error');
      }

      setIsLoading(false);
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (carParks && details.carParkCode) {
      const allPlans = carParks.get(details.carParkCode.value) || [];
      setParkingPlanOptions(allPlans);
    }
  }, [carParks, details.carParkCode]);

  const handleSelect = (selected, type) => {
    setDetails({ ...details, [type]: selected });
  };

  const bodyData = () => {
    const { carParkCode, parkingPlan } = details;
    const { value: CarParkId } = carParkCode || {};
    const { value: ParkingPlanId } = parkingPlan || {};

    return { CarParkId, ParkingPlanId, PreviousBookingId: id };
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spin />
        </div>
      ) : (
        <div>
          <Row>
            <Col md={6} className="mb-4">
              <Label>Car Park Code</Label>
              <Select
                menuPlacement="auto"
                menuPosition="fixed"
                options={carParkOptions}
                value={details.carParkCode}
                onChange={selected => handleSelect(selected, 'carParkCode')}
              />
            </Col>
            <Col md={6} className="mb-4">
              <Label>Parking Plan</Label>
              <Select
                menuPlacement="auto"
                menuPosition="fixed"
                options={parkingPlanOptions}
                value={details.parkingPlan}
                onChange={selected => handleSelect(selected, 'parkingPlan')}
              />
            </Col>
          </Row>

          <SubmitButtons id={id} back={back} body={bodyData()} saveActionURL={saveActionURL} />
        </div>
      )}
    </>
  );
};

export default Change;
