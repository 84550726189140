import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie/cjs';

const cookies = new Cookies();

function ActionButtons(id, status, reference, customPage, allowEdit, carParkId) {
  const resendPaymentLink = async () => {
    if (!window.confirm(`Are you sure to resend the payment link for booking ${id}?`)) return;
    const { data: { result: { sms } = {} } = {} } = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/test/direct_payment_link`,
      { id }
    );

    if (!sms) return window.alert('Resend failed, please try again.');
    window.alert('Resend success.');
  };

  const resendConfirmation = async () => {
    if (!window.confirm(`Are you sure to resend confirmation for booking ${id}?`)) return;
    const { data: { record: { sms = '' } = {} } = {} } = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/test/booking/notify/${id}`,
      { forceSend: true }
    );

    if (!sms) return window.alert('Resend failed, please try again.');
    window.alert('Resend success.');
  };

  return (
    <div className="d-flex flex-column">
      {(status === 'paid' || status === 'active') && allowEdit && (
        <>
          <button
            type="button"
            className="btn btn-light btn-xs mb-2"
            onClick={() => customPage(id, 'renew')}
          >
            Renew Booking
          </button>
          <button
            type="button"
            className="btn btn-light btn-xs mb-2"
            onClick={() => customPage(id, 'change')}
          >
            Change Booking
          </button>
          <button
            type="button"
            className="btn btn-light btn-xs mb-2"
            onClick={() => customPage(id, 'changeRegistrationMark')}
          >
            Change Registration Mark
          </button>
        </>
      )}
      {status !== 'renewed' && (
        <button type="button" className="btn btn-light btn-xs mb-2" onClick={resendPaymentLink}>
          Resend Payment Link
        </button>
      )}
      {(status === 'paid' || status === 'active') && (
        <button type="button" className="btn btn-light btn-xs" onClick={resendConfirmation}>
          Resend Confirmation
        </button>
      )}
    </div>
  );
}

export default ActionButtons;
